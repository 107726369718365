import {React, useState} from 'react';
import axios from "axios";

export async function getTypesPrestations(id_cabinet = null) {
    
    return axios.post(process.env.REACT_APP_API_URL+'prestations/get_types', {
        token: process.env.REACT_APP_TOKEN,
        id_cabinet: id_cabinet,
    }, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    })
    .catch(function (error) {
        console.log(error.toJSON());
    });
}


export async function getCabPrestations(user, id_cabinet) {
    
    return axios.post(process.env.REACT_APP_API_URL+'prestations/get', {
        token: user.sessionToken,
        id_cabinet: id_cabinet
    }, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    })
    .catch(function (error) {
        console.log(error.toJSON());
    });
}


export async function setPrestation(user, id_cabinet, id_prestation = null, id_type_prestation, prix_fixe = null, prix_horaire = null) {
    
    return axios.post(process.env.REACT_APP_API_URL+'rendezvous/set', {
        token: user.sessionToken,
        id_cabinet: id_cabinet,
        id_prestation: (id_prestation != null ? id_prestation : 'new'),
        id_type_prestation: id_type_prestation,
        prix_fixe: prix_fixe,
        prix_horaire: prix_horaire
    }, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    })
    .catch(function (error) {
        console.log(error.toJSON());
    });
}

export async function setTypePrestation(user, id_cabinet, nom) {
    
    return axios.post(process.env.REACT_APP_API_URL+'prestations/set_type', {
        token: user.sessionToken,
        id_cabinet: id_cabinet,
        nom: nom,
    }, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    })
    .catch(function (error) {
        console.log(error.toJSON());
    });
}

export async function supprTypePrestation(user, id_type) {
    
    return axios.post(process.env.REACT_APP_API_URL+'prestations/suppr_type', {
        token: user.sessionToken,
        id_type: id_type,
    }, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    })
    .catch(function (error) {
        console.log(error.toJSON());
    });
}

export function useTypesPrestationPerso() {

    const getTypesPrestationPerso = () => {
      var typesPerso = JSON.parse(localStorage.getItem('typesPrestationsPerso'));
      if(!typesPerso) typesPerso = [];
      return typesPerso
    };
  
    const [typesPrestationsPerso, setTypesPrestationsPerso] = useState(getTypesPrestationPerso());
  
    const saveTypesPrestationsPerso = typesPerso => {
      localStorage.setItem('typesPrestationsPerso', JSON.stringify(typesPerso));
      setTypesPrestationsPerso(typesPerso);
    };
  
    return {
        setTypesPrestationsPerso: saveTypesPrestationsPerso,
        typesPrestationsPerso
    }
}