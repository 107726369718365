import { React, useEffect, useState } from 'react';
import { Redirect } from "react-router-dom";
import {useCredentials} from '../../Services/authServices';

export default function Logout() {

    const { user, setUser } = useCredentials();

    useEffect(() => {
        setUser(null);
    });

  return <Redirect to={"/"} />
}