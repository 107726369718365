import {React, useEffect, useState, useReducer} from 'react';
import { useParams } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Skeleton, TextField, Button} from '@mui/material';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Select from 'react-select'
import IconButton from '@mui/material/IconButton';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionActions from '@mui/material/AccordionActions';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import Switch from '@mui/material/Switch';
import swal from 'sweetalert';
import { Table, Column, HeaderCell, Cell } from 'rsuite-table';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../App/App.css';
import "rsuite-table/dist/css/rsuite-table.css";

import {useCredentials} from '../../Services/authServices';
import {getAnimalRdvs} from '../../Services/animalServices';
import {getDocuments, uploadDocument, downloadDocument, supprDocument} from '../../Services/FileServices';


function b64toBlob(b64Data, contentType='', sliceSize=512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
  
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
  
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
  
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
      
    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
}


function Documents() {

    const { id } = useParams(); 
    const { user } = useCredentials();
    const [listeDocuments, setListeDocuments] = useState();
    const [listeComplete, setListeComplete] = useState();
    const [listeRdvs, setListeRdvs] = useState();
    const [selectedRdvs, setSelectedRdvs] = useState([]);
    const [condense, setCondense] = useState(true);
    const type_documents = ['lettre_refere','formulaire_antecedents','resultats','compte_rendu','ordonnance','autre'];
    const [listeSousTypesDocument, setListeSousTypesDocument] = useState([]);
    const sous_types_resultats = ['Biochimie', 'Hématologie', 'Analyse Urinaires', 'Radiographie', 'Echographie', 'Scanner', 'IRM', 'Scintigraphie', 'Cytopathologie', 'Histopathologie', 'Autres analyses'];
    const sous_types_cr = ['Médecine interne', 'Oncologie', 'Cardiologie', 'Chirurgie', 'Imagerie', 'Dermatologie', 'Neurologie', 'Reproduction', 'Anesthesie', 'Urgences & soins intensifs', 'Comportement'];
    const [maxDate, setMaxDate] = useState();

    const [open, setOpen] = useState(false);
    const [editedDoc, setEditedDoc] = useState({}); 
    const [nomError, setNomError] = useState();
    const [typeDocError, setTypeDocError] = useState();
    const [fileError, setFileError] = useState();
    const [consultError, setConsultError] = useState();
    const [loading, setLoading] = useState(false);
    const handleClose = () => setOpen(false);  
    
    const [sortColumn, setSortColumn] = useState();
    const [sortType, setSortType] = useState();

    const [iframeSrc, setIframeSrc] = useState();
    const [iframeWidth, setIframeWidth] = useState();
    const [iframeHeight, setIframeHeight] = useState();
    const [iframeScale, setIframeScale] = useState();
    const [openApercu, setOpenApercu] = useState(false);
    const handleCloseApercu = () => setOpenApercu(false);  
    
    const [pageWidth, setPageWidth] = useState(window.innerWidth);
    
    const [, forceUpdate] = useReducer(o => !o);

    useEffect(() => {
        getDocuments(user, id).then(res => {
            if(res != undefined && res.data != undefined && res.data.statut == "ok") {                    
                let liste_docs = [];
                res.data.donnees.map((d, i) => {
                    if(liste_docs[d.type_document] == undefined) { liste_docs[d.type_document] = []; }
                    liste_docs[d.type_document].push(d);

                    let date = new Date(d.date_ajout_iso);
                    if(!maxDate || date > maxDate) { setMaxDate(date); }
                });
                setListeDocuments(liste_docs);
                setListeComplete(res.data.donnees);
            }            
        });

        getAnimalRdvs(user, id).then(res => {
            if(res != undefined && res.data != undefined && res.data.statut == "ok") {    

                let liste_rdvs = [];
                let selected = [];
                res.data.donnees.map((r, i) => {
                    liste_rdvs.push({value: r.id, label: (r.date_formatted ? 'le '+r.date_formatted : 'Sans date')+ ' - '+r.motif_consultation});
                    selected.push(r.id);
                });
                setListeRdvs(liste_rdvs);
                setSelectedRdvs(selected);
            }
        });

        window.onresize = function() {
            setPageWidth(window.innerWidth);
        }

    }, []);

    const toggleCondense = () => {
        setCondense(!condense);
    }

    const libelleType = (type_doc) => {

        let nom = '';
        switch (type_doc) {
            case 'lettre_refere': nom = 'Lettre(s) de référés'; break;
            case 'formulaire_antecedents': nom = 'Formulaire(s) d\'antécédents'; break;
            case 'resultats': nom = 'Résultats de laboratoire'; break;
            case 'compte_rendu': nom = 'Compte-rendu(s) de spécialiste(s)'; break;
            case 'ordonnance': nom = 'Ordonnance(s)'; break;
            case 'autre': nom = 'Autres documents'; break;
        }
        return nom;
    }


    const updateDoc = (e) => {
        let docVals = {...editedDoc};
        docVals[e.target.id] = e.target.value;
        setEditedDoc(docVals);
    }    
    const updateTypeDocument = (type) => {
        let docVals = {...editedDoc};
        docVals['type_document'] = type;
        setEditedDoc(docVals);

        let liste = [];
        if(type == 'resultats') {
            sous_types_resultats.map((t, i) => {
                liste.push({ value: t, label: t});
            });
        }
        else if(type == 'compte_rendu') {
            sous_types_cr.map((t, i) => {
                liste.push({ value: t, label: t});
            });
        }
        setListeSousTypesDocument(liste);
    }  
    const updateSousTypeDocument = (e) => {
        let docVals = {...editedDoc};
        docVals['sous_type_document'] = e.value;
        setEditedDoc(docVals);
    }
    const updateRdvs = (selected) => {        
        let tab = []
        let docVals = {...editedDoc};
        if(selected) {
            selected.map((rdv, i) => { tab.push(rdv.value); });
        }
        docVals['ids_rdvs'] = tab.join(',');
        setEditedDoc(docVals);
    }

    const handleFileChange = (e) => {

        if (e.target.files) {

            const file = e.target.files[0];
            const allowed_formats = ['application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'image/jpeg', 'image/png', 'application/pdf'];

            if(file.size > 5000000) { setFileError('L\'image est trop lourde'); }
            else if(allowed_formats.indexOf(file.type) == -1) { setFileError('Les formats autorisés sont pdf, docx, jpg et png'); }
            else {
                setFileError(null);
                let docVals = {...editedDoc};
                docVals['doc'] = file;
                docVals['format'] = file.type;
                docVals['nom_fichier'] = file.name;
                setEditedDoc(docVals);            
            }            
        }
    }

    const openWithType = (type) => {
        updateTypeDocument(type);
        setOpen(true);
    };

    const importDoc = () => {

        setNomError(null);
        setTypeDocError(null);
        setFileError(null); 
        setConsultError(null);

        let nb_errors = 0;
        if(!editedDoc.nom || editedDoc.nom.length == 0) { setNomError('Ce champ est obligatoire'); nb_errors++; }
        if(!editedDoc.type_document || editedDoc.type_document.length == 0) { setTypeDocError('Ce champ est obligatoire'); nb_errors++; }
        if(!editedDoc.doc) { setFileError('Ce champ est obligatoire'); nb_errors++;}
        if(!editedDoc.ids_rdvs || editedDoc.ids_rdvs.length == 0) { setConsultError('Ce champ est obligatoire'); nb_errors++; }

        if(nb_errors == 0) {
            setLoading(true);
            uploadDocument(user, editedDoc.doc, editedDoc.format, editedDoc.nom, id, editedDoc.type_document, editedDoc.sous_type_document, editedDoc.ids_rdvs).then((res) => {
                if(res != undefined && res.data != undefined && res.data.statut == "ok") {

                    let liste_docs_complete = [...listeComplete];
                    let liste_docs = {...listeDocuments};

                    let ajd = new Date;
                    let docVals = {...editedDoc};
                    docVals.id = res.data.id_document;
                    docVals.date_ajout = (ajd.getDate() < 10 ? '0' : '')+ajd.getDate()+'/'+((ajd.getMonth()+1) < 10 ? '0' : '')+(ajd.getMonth()+1)+'/'+ajd.getFullYear();
                    docVals.statut = 'nouveau';

                    let consultations = [];
                    editedDoc.ids_rdvs.split(',').map((rdv, i) => { consultations.push({id: parseInt(rdv)}); });
                    docVals.consultations = consultations;

                    liste_docs_complete.push(docVals);
                    if(liste_docs[editedDoc.type_document] == undefined) { liste_docs[editedDoc.type_document] = []; }
                    liste_docs[editedDoc.type_document].push(docVals);

                    setListeComplete(liste_docs_complete);
                    setListeDocuments(liste_docs);                 
                    setOpen(false);
                }
                setLoading(false);
            }); 
        }  
    }

    const download = (id_document, methode = 'download') => {
        downloadDocument(user, id_document).then((res) => {
            if(res != undefined && res.data != undefined && res.data.statut == "ok") {
                const blob = b64toBlob(res.data.donnees, res.data.format);
                const blobUrl = URL.createObjectURL(blob);

                if(methode == 'apercu') {
                    // const img = new Image();
                    // img.src = blobUrl;
                    // img.onload = () => {                        
                    //     let max_height = (window.innerHeight * 0.85) - 100;
                    //     let scale = (max_height / img.height);
                    //     setIframeWidth(img.width+1);
                    //     setIframeHeight(img.height+1);
                    //     setIframeScale(scale);
                    //     setIframeSrc(blobUrl);
                    //     setOpenApercu(true);
                    // };
                    window.open(blobUrl);             
                }
                else {
                    var link = document.createElement("a");
                    link.href = blobUrl;
                    link.download = res.data.nom;
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                }
            }
        });
    };    

    const supprDoc = (id_document) => {
        swal({
            title: "Êtes-vous sûr(e) ?",
            text: "Cette action supprimera définitivement le document.",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          })
          .then((willDelete) => {
            if(willDelete) {
                supprDocument(user, id_document).then((res) => {
                    if(res != undefined && res.data != undefined && res.data.statut == "ok") {

                        let liste_docs_complete = [];
                        let liste_docs = [];

                        Object.entries(listeDocuments).forEach(([t, l]) => {
                            l.map((d, i) => {
                                if(liste_docs[t] == undefined) { liste_docs[t] = []; }
                                if(d.id != id_document) {
                                    liste_docs[t].push(d);
                                    liste_docs_complete.push(d);
                                }
                            });
                        });

                        setListeComplete(liste_docs_complete);
                        setListeDocuments(liste_docs);                           
                        forceUpdate();
                    }
                });  
            }  
        });
    }


    const handleSortColumn = (sortColumn, sortType) => {
        setSortColumn(sortColumn);
        setSortType(sortType);
    };

    const dateFrToTime = (d) => {
        let _d = d.split('/');
        let d_obj = new Date(_d[2]+'-'+_d[1]+'-'+_d[0]);
        return d_obj.getTime();
    }; 

    const listeTriee = (type, sortColumn, sortType = 'desc') => {
        if(listeDocuments) {

            let liste = [...(type != null ? listeDocuments[type] : listeComplete)];
            let liste_filtree = [];
            liste.map((rdv, i) => {
                rdv.consultations.map((c, j) => { if(selectedRdvs.indexOf(c.id) > -1) liste_filtree.push(rdv); });
            });

            return liste_filtree.sort((a, b) => {

                let x = a[sortColumn];
                let y = b[sortColumn];
                
                if(sortColumn == 'date_ajout') { 
                    x = dateFrToTime(x);
                    y = dateFrToTime(y);
                    return (sortType === 'asc' ? x - y : y - x);
                }
                else if(typeof x == 'string' && typeof y == 'string') {
                    if (typeof x != 'string') {x = x.toString();}
                    if (typeof y != 'string') {y = y.toString();}
                    return "a5b".localeCompare("a21b", undefined, { numeric: true }) * (sortType === 'asc' ? -1 : 1);
                }
                else {
                    return (sortType === 'asc' ? x - y : y - x);
                }
            });
        }
        else return [];
    }

    const filtreConsultations = (selected) => {
        let tab = [];
        if(selected) {
            if(selected.length == 0) {
                listeRdvs.map((rdv, i) => { tab.push(rdv.value); });
            }
            else {
                selected.map((rdv, i) => { tab.push(rdv.value); });
            }
        }
        setSelectedRdvs(tab);
    }

    const listeTypesDocument = [];
    type_documents.map((t, i) => {
        listeTypesDocument.push({ value: t, label: libelleType(t)});
    });


    return(<div>

        {pageWidth < 1400 && <Card className={['p-2','px-3','mb-2'].join(" ")} style={{position: 'relative', zIndex: 20, overflow: 'visible'}}>
        <Row className={['d-flex','align-items-center'].join(" ")}>
            <Col md={3} className='borderRight'>
                <FormControlLabel
                    value="start"
                    control={<Switch checked={condense} onChange={toggleCondense} className='ms-2' />}
                    label="Vue condensée"
                    labelPlacement="start"
                    className='ms-1'
                />
            </Col>
            <Col md={3} className='borderRight'>
                <Select
                    defaultValue={listeRdvs}
                    isMulti
                    name="rdvs"
                    options={listeRdvs}
                    className="basic-multi-select mt-3"
                    classNamePrefix="select"
                    placeholder="Consultations liées"
                    onChange={filtreConsultations}
                />
            </Col>
            <Col md={3} className='borderRight'>
                <p className='mb-2'><span className='textSecondary'>{listeComplete ? listeComplete.length : 0}</span> documents</p>
                <p className='mb-2'><span className='textSecondary'>{listeComplete ? listeComplete.filter((d) => d.statut == 'nouveau').length : 0}</span> nouveau(x) document(s)</p>                            
                <p className='mb-2'>Dernier dépôt le <span className='textSecondary'>
                    {maxDate && (maxDate.getDate() < 10 ? '0' : '')+maxDate.getDate()+'/'+((maxDate.getMonth()+1) < 10 ? '0' : '')+(maxDate.getMonth()+1)+'/'+maxDate.getFullYear()}
                </span></p>  
            </Col>
            <Col md={3}>
                <Button variant="text" className={['listButton','p-2'].join(" ")} onClick={() => setOpen(true)}>
                    <FileUploadOutlinedIcon className='me-3'/>
                    <span>Importer un document</span>
                </Button>
            </Col>
        </Row>
        </Card>}
        <Row>
            <Col md={(pageWidth < 1400 ? 12 : 9)}> 
                {listeComplete && listeComplete.length == 0 && <em className='text-muted'>Aucun document</em>}               
                {condense ? type_documents.map((type, i) => {
                    return (listeDocuments && listeDocuments[type] && listeDocuments[type].length > 0 && <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                            >
                            <strong className='arvo'>{listeDocuments && listeDocuments[type] ? listeDocuments[type].length : 0} {libelleType(type)}</strong>
                            <IconButton type="button" className='me-2' onClick={() => openWithType(type)}>
                                <FileUploadOutlinedIcon />
                            </IconButton>
                        </AccordionSummary>
                        <AccordionDetails className='px-4'>                            
                            <Table 
                                className='w-100' 
                                data={listeTriee(type, sortColumn, sortType)}
                                sortColumn={sortColumn}
                                sortType={sortType}
                                onSortColumn={handleSortColumn}
                            >
                                <Column flexGrow={1} sortable>
                                    <HeaderCell>Nom</HeaderCell>
                                    <Cell dataKey="nom" />
                                </Column>
                                {(type == "resultats" || type == "compte_rendu") && <Column flexGrow={1} sortable>
                                    <HeaderCell>Sous-type</HeaderCell>
                                    <Cell dataKey="sous_type_document" />
                                </Column>}
                                <Column flexGrow={1} sortable>
                                    <HeaderCell>Date</HeaderCell>
                                    <Cell dataKey="date_ajout" />
                                </Column>                          
                                <Column flexGrow={1} sortable>
                                    <HeaderCell>Statut</HeaderCell>
                                    <Cell dataKey="statut">{rowData => (rowData.statut=="nouveau" ? <strong className='textSecondary'>Nouveau</strong> : <span>Lu</span>)}</Cell>
                                </Column>                            
                                <Column flexGrow={2}>
                                    <HeaderCell>Action</HeaderCell>
                                    <Cell className='p-0'>
                                        {rowData => <div className={['d-flex','align-items-center','h-100'].join(" ")}>
                                            <IconButton type="button" className='ms-1' onClick={() => download(rowData.id, 'apercu')}>
                                                <SearchIcon />
                                            </IconButton>
                                            <IconButton type="button" className='ms-1' onClick={() => download(rowData.id)}>
                                                <FileDownloadOutlinedIcon />
                                            </IconButton>
                                            {rowData.id_proprietaire == user.id && <IconButton type="button" className='textRed' onClick={() => supprDoc(rowData.id)}>
                                                <DeleteOutlineIcon />
                                            </IconButton>}    
                                        </div>}
                                    </Cell>
                                </Column>
                            </Table>                
                        </AccordionDetails>
                    </Accordion>)
                })
                : <div>
                    <Table 
                        className='w-100'
                        wordWrap="break-word" 
                        autoHeight
                        data={listeTriee(null, sortColumn, sortType)}
                        sortColumn={sortColumn}
                        sortType={sortType}
                        onSortColumn={handleSortColumn}
                    >
                        <Column flexGrow={1} sortable>
                            <HeaderCell>Nom</HeaderCell>
                            <Cell dataKey="nom" />
                        </Column>
                        <Column flexGrow={1} sortable>
                            <HeaderCell>Type</HeaderCell>
                            <Cell dataKey="type_document">{rowData => libelleType(rowData.type_document)}</Cell>
                        </Column>                        
                        <Column flexGrow={1} sortable>
                            <HeaderCell>Sous-type</HeaderCell>
                            <Cell dataKey="sous_type_document" />
                        </Column>
                        <Column flexGrow={1} sortable>
                            <HeaderCell>Date</HeaderCell>
                            <Cell dataKey="date_ajout" />
                        </Column>                          
                        <Column flexGrow={1} sortable>
                            <HeaderCell>Statut</HeaderCell>
                            <Cell dataKey="statut">{rowData => (rowData.statut=="nouveau" ? <strong className='textSecondary'>Nouveau</strong> : <span>Lu</span>)}</Cell>
                        </Column>                            
                        <Column flexGrow={2}>
                            <HeaderCell>Action</HeaderCell>
                            <Cell className='p-0'>
                                {rowData => <div className={['d-flex','align-items-center','h-100'].join(" ")}>
                                    <IconButton type="button" className='ms-1' onClick={() => download(rowData.id, 'apercu')}>
                                        <SearchIcon />
                                    </IconButton>
                                    <IconButton type="button" className='ms-1' onClick={() => download(rowData.id)}>
                                        <FileDownloadOutlinedIcon />
                                    </IconButton>
                                    {rowData.id_proprietaire == user.id && <IconButton type="button" className='textRed' onClick={() => supprDoc(rowData.id)}>
                                        <DeleteOutlineIcon />
                                    </IconButton>}
                                </div>}
                            </Cell>
                        </Column>
                    </Table>  
                </div>}
            </Col>
            {pageWidth >= 1400 &&<Col md={3}>
                <Card>                   
                    <CardContent>
                        <FormControlLabel
                            value="start"
                            control={<Switch checked={condense} onChange={toggleCondense} className='ms-2' />}
                            label="Vue condensée"
                            labelPlacement="start"
                            className='ms-1'
                        />
                        <Select
                            defaultValue={listeRdvs}
                            isMulti
                            name="rdvs"
                            options={listeRdvs}
                            className="basic-multi-select mt-3"
                            classNamePrefix="select"
                            placeholder="Consultations liées"
                            onChange={filtreConsultations}
                        />
                        <hr></hr> 
                        <p className='mb-2'><span className='textSecondary'>{listeComplete ? listeComplete.length : 0}</span> documents</p>
                        <p className='mb-2'><span className='textSecondary'>{listeComplete ? listeComplete.filter((d) => d.statut == 'nouveau').length : 0}</span> nouveau(x) document(s)</p>                            
                        <p className='mb-2'>Dernier dépôt le <span className='textSecondary'>
                            {maxDate && (maxDate.getDate() < 10 ? '0' : '')+maxDate.getDate()+'/'+((maxDate.getMonth()+1) < 10 ? '0' : '')+(maxDate.getMonth()+1)+'/'+maxDate.getFullYear()}
                        </span></p>  
                        <hr></hr>
                        <Button variant="text" className={['listButton','p-2'].join(" ")} onClick={() => setOpen(true)}>
                            <FileUploadOutlinedIcon className='me-3'/>
                            <span>Importer un document</span>
                        </Button>
                    </CardContent>
                </Card>
            </Col>}       
        </Row>

        <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
            backdrop: { timeout: 500 },
        }}
        >
        <Fade in={open}>
            <Box className="modal-sm">
                <p className={['titre','bold','mb-2'].join(" ")}>Nouveau document</p>

                <IconButton aria-label="modifier" className="modalExit" onClick={handleClose}>
                    <CloseIcon/>
                </IconButton>

                <TextField 
                    id="nom" 
                    label="Nom" 
                    variant="standard" 
                    value={editedDoc.nom ? editedDoc.nom : ''}
                    onChange={updateDoc}
                    className="w-100"
                    error={!!nomError}
                    helperText={nomError}
                />
                <FormControl variant="standard" sx={{ width: '100%' }}>
                    <Select
                        id="type_document"
                        value={editedDoc.type_document ? listeTypesDocument.filter((c) => c.value == editedDoc.type_document) : ''}
                        className='mt-3'
                        onChange={(e) => updateTypeDocument(e.value)}
                        placeholder="Type de document"
                        options={listeTypesDocument}
                        error={!!typeDocError}
                    >                      
                    </Select>
                </FormControl>
                {typeDocError && <small className='textRed'>{typeDocError}</small>}     
                {(editedDoc.type_document == 'compte_rendu' || editedDoc.type_document == 'resultats') &&
                    <FormControl variant="standard" sx={{ width: '100%' }}>
                        <Select
                            id="sous_type_document"
                            value={editedDoc.sous_type_document ? listeSousTypesDocument.filter((c) => c.value == editedDoc.sous_type_document) : ''}
                            className='mt-3'
                            onChange={updateSousTypeDocument}
                            placeholder="Sous-type de document"
                            options={listeSousTypesDocument}
                        >                      
                        </Select>
                    </FormControl>
                }

                <Select
                    isMulti
                    name="rdvs"
                    options={listeRdvs}
                    className="basic-multi-select mt-3"
                    classNamePrefix="select"
                    placeholder="Consultations liées"
                    onChange={updateRdvs}
                />
                {consultError && <small className='textRed'>{consultError}</small>}   

                <div>
                    <Button variant="outlined" className={['w-100','mt-3','py-4','px-2'].join(" ")} onClick={() => document.getElementById('nouveau_document').click()} startIcon={<FileUploadOutlinedIcon />}>
                        {editedDoc.nom_fichier ? editedDoc.nom_fichier : 'Parcourir'}
                        <input id="nouveau_document" className='d-none' type="file" onChange={handleFileChange} />
                    </Button>
                    {fileError && <small className='textRed'>{fileError}</small>}                    
                </div>
                
                <Button variant="contained" className={['buttonSecondary','mt-5'].join(" ")} type="submit" startIcon={(loading ? <CircularProgress/> : <CheckIcon />)} onClick={importDoc}>Importer</Button> 
            </Box>
        </Fade> 
        </Modal>

        {/*
            <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={openApercu}
            onClose={handleCloseApercu}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: { timeout: 500 },
            }}
            >
            <Fade in={openApercu}>
                <Box className={['modal-apercu','p-3'].join(" ")}>
                    <p className={['titre','bold','mb-2','p-3'].join(" ")}>Apercu</p>

                    <IconButton aria-label="modifier" className="modalExit" onClick={handleCloseApercu}>
                        <CloseIcon/>
                    </IconButton>
                    
                    <div id="iframe_wrapper" className={['custom-scrollbar','h-100'].join(" ")} style={{width: (iframeWidth+10)}}>
                        <iframe 
                            id="apercu_document" 
                            src={iframeSrc} 
                            height={iframeHeight} 
                            width={iframeWidth}
                        ></iframe>
                        <div> </div>
                    </div>                 
                </Box>
            </Fade> 
            </Modal>
        */}
    </div>);
}

export default Documents;