import { React, useEffect, useState } from 'react';
import { Redirect } from "react-router-dom";
import UserWidget from '../Dashboard/UserWidget';
import SideMenu from '../Dashboard/SideMenu';
import SearchIcon from '@mui/icons-material/Search';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Skeleton } from '@mui/material';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../App/App.css';

import {useCredentials} from '../../Services/authServices';  
import {getCabFactures} from '../../Services/cabinetServices';
import {getClientFactures} from '../../Services/UserServices';

function Factures() {

  const { user, setUser } = useCredentials();
  const queryParams = new URLSearchParams(window.location.search);
  const [activeTab, setActiveTab] = useState('1');
  const [listeFacturesEmises, setlisteFacturesEmises] = useState([]);  
  const [listeFacturesRecues, setlisteFacturesRecues] = useState([]);   
  const [listeFacturesAbo, setlisteFacturesAbo] = useState([]);   
  const [numPage, setNumPage] = useState(0);
  const [numPage_r, setNumPage_r] = useState(0);
  const [numPage_a, setNumPage_a] = useState(0);
  const [nbPages, setNbPages] = useState(0);
  const [nbPages_r, setNbPages_r] = useState(0);
  const [nbPages_a, setNbPages_a] = useState(0);
  const nbPerPage = 5; 
  const [filtre, setFiltre] = useState();
  
  useEffect(() => {

    const queryPage = queryParams.get('page');
    if(queryPage != null) setNumPage(queryPage);
    if(user.is_veto) {
      getCabFactures(user).then(res => {
          if(res != undefined && res.data != undefined && res.data.statut == "ok") {     
              
            var liste_factures_emises = [];
            res.data.factures_emises.map((c, i) => {
                c.inPage = false;
                if(i >= numPage*nbPerPage && i < (numPage+1)*nbPerPage) { c.inPage = true; }
                liste_factures_emises.push(c);
            });
            setlisteFacturesEmises(liste_factures_emises);
            setNbPages(Math.trunc(res.data.factures_emises.length/nbPerPage)+1);

            var liste_factures_recues = [];
            res.data.factures_recues.map((c, i) => {
                c.inPage = false;
                if(i >= numPage_r*nbPerPage && i < (numPage_r+1)*nbPerPage) { c.inPage = true; }
                liste_factures_recues.push(c);
            });
            setlisteFacturesRecues(liste_factures_recues);
            setNbPages_r(Math.trunc(res.data.factures_recues.length/nbPerPage)+1);

            var liste_factures_abo = [];
            res.data.factures_abo.map((c, i) => {
                c.inPage = false;
                if(i >= numPage_a*nbPerPage && i < (numPage_a+1)*nbPerPage) { c.inPage = true; }
                liste_factures_abo.push(c);
            });
            setlisteFacturesAbo(liste_factures_abo);
            setNbPages_a(Math.trunc(res.data.factures_abo.length/nbPerPage)+1);
          }
      });
    }
    else {
      getClientFactures(user).then(res => {
        if(res != undefined && res.data != undefined && res.data.statut == "ok") {     

          var liste_factures_recues = [];
          res.data.factures.map((c, i) => {
              c.inPage = false;
              if(i >= numPage_r*nbPerPage && i < (numPage_r+1)*nbPerPage) { c.inPage = true; }
              liste_factures_recues.push(c);
          });
          setlisteFacturesRecues(liste_factures_recues);
          setNbPages_r(Math.trunc(res.data.factures.length/nbPerPage)+1);
        }
    });
    }
  }, [user]);

  const changeActiveTab = (event, newValue) => {
    setActiveTab(newValue);
  };


  const nextPage = (type = "emises") => {    

    let _liste = listeFacturesEmises;
    let _numPage = numPage;
    if(type == "recues") { _liste = listeFacturesRecues; _numPage = numPage_r; }
    else if(type == "abo") { _liste = listeFacturesAbo; _numPage = numPage_a; }

    var liste_factures = [];
    _liste.map((c, i) => {
      c.inPage = false;
      if(i >= (_numPage+1)*nbPerPage && i < (_numPage+2)*nbPerPage) { c.inPage = true; }
      liste_factures.push(c); 
    });

    if(type == "emises") {
      setlisteFacturesEmises(liste_factures);
      setNumPage((_numPage+1));
    }
    else if(type == "recues") { 
      setlisteFacturesEmises(liste_factures);
      setNumPage_r((_numPage+1));
    }
    else if(type == "abo") { 
      setlisteFacturesAbo(liste_factures);
      setNumPage_a((_numPage+1));
    }
  }

  const prevPage = (type = "emises") => {

    let _liste = listeFacturesEmises;
    let _numPage = numPage;
    if(type == "recues") { _liste = listeFacturesRecues; _numPage = numPage_r; }
    else if(type == "abo") { _liste = listeFacturesAbo; _numPage = numPage_a; }

    if(_numPage > 0) {
        var liste_factures = [];
        listeFacturesEmises.map((c, i) => {
        c.inPage = false;
        if(i >= (_numPage-1)*nbPerPage && i < (_numPage)*nbPerPage) { c.inPage = true; }
        liste_factures.push(c); 
        });

        if(type == "emises") {
          setlisteFacturesEmises(liste_factures);
          setNumPage((_numPage-1));
        }
        else if(type == "recues") { 
          setlisteFacturesEmises(liste_factures);
          setNumPage_r((_numPage-1));
        }
        else if(type == "abo") { 
          setlisteFacturesAbo(liste_factures);
          setNumPage_a((_numPage-1));
        }
    }
  }

  const getCouleurStatut = (statut) => {
    let couleur = '';
    switch(statut) {
      case 'pending_customer_approval':
      case 'pending_submission': couleur = 'warning'; break;
      case 'submitted':
      case 'confirmed': 
      case 'paid_out': couleur = 'success'; break;
      case 'cancelled': 
      case 'failed': 
      case 'customer_approval_denied': couleur = 'danger'; break;
      case 'charged_back': couleur = 'grey'; break;
    }
    return couleur;
  }

  const getLibelleStatut = (statut) => {
    let libelle = '';
    switch(statut) {
      case 'pending_customer_approval':
      case 'pending_submission': libelle = 'En attente'; break;
      case 'submitted': libelle = 'Paiement envoyé'; break;
      case 'confirmed': 
      case 'paid_out': libelle = 'Payée'; break;
      case 'cancelled': 
      case 'failed': 
      case 'customer_approval_denied': libelle = 'Paiement annulé'; break;
      case 'charged_back': libelle = 'Remboursée'; break;
    }
    return libelle;
  }

  const updateFiltre = (e) => {
    if(e.target.value.length == 0 || e.target.value.length > 2) setFiltre(e.target.value);
  }

  
  return (
    <div className="fullpage">
      <Container className='full_container'>
        
        <UserWidget /> 
        {user.is_veto && <SideMenu user={user} setUser={setUser}/>}
            
        <Row className={user.is_veto ? [(user.menuClosed ? 'withSidemenu-closed' : 'withSidemenu'), 'h-auto'].join(" ") : ['w-100', 'h-auto', ' ps-4'].join(" ")}>
          <Col md={12} className={['ps-5','py-5','hmax-100'].join(" ")}>

            <Row>
              <Col md={3}>
                <h2>Factures</h2>
                <hr width="80%" className="black_line"></hr>
                {!user.is_veto && <div>
                  <IconButton className="ms-0" aria-label="retour" onClick={() => window.open('/client/'+user.id, '_self')}>
                      <ArrowBackIcon/>
                  </IconButton>
                  <span className='ms-2'>Retour</span>
                </div>}
              </Col>
              <Col md={4}>
                <div className={['searchBar','mb-2','mx-3'].join(" ")}>
                  <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                      <SearchIcon />
                  </IconButton>
                  <InputBase placeholder="Rechercher..." className='w-100' onChange={updateFiltre}/>
                </div>
              </Col>
            </Row>

            {user.is_veto && 
            <TabContext value={activeTab}>
              <TabList onChange={changeActiveTab}>
                <Tab label={"Émises ("+(listeFacturesEmises ? listeFacturesEmises.length : 0)+")"}  value="1" />
                <Tab label={"Reçues ("+(listeFacturesRecues ? listeFacturesRecues.length : 0)+")"} value="2" />
                <Tab label={"Abonnement ("+(listeFacturesAbo ? listeFacturesAbo.length : 0)+")"} value="3" />
              </TabList>
              <TabPanel value="1">
                <Row className='mt-4'>
                  <Col md={10} className={['custom-scrollbar','h-100'].join(" ")}>      

                    <Row className="table-header align-items-center">
                      <Col><strong>Numéro</strong></Col>
                      <Col md={3}><strong>Client</strong></Col>
                      <Col><strong>Date</strong></Col>
                      <Col><strong>Montant</strong></Col>
                      <Col><strong>Statut</strong></Col>
                      <Col md={1}></Col>
                    </Row>

                    {listeFacturesEmises ? listeFacturesEmises.map((f, i) => {	
                      var match = (filtre ? f.client.toLowerCase().indexOf(filtre.toLowerCase()) > -1 : false);
                      if((!filtre && f.inPage) || (filtre && match)) {
                        var mt = (i > 0 ? "mt-3" : "mt-1");
                        return (<div className = {mt}>
                          <Row className="table-row align-items-center">
                          <Col><p>{f.code}</p></Col>
                          <Col md={3}><p>{f.client}</p></Col>
                          <Col><p>{f.date}</p></Col>
                          <Col><p>{f.montant} €</p></Col>
                          <Col>
                          <div className={['etiquette',getCouleurStatut(f.statut)].join(" ")}>{getLibelleStatut(f.statut)}</div>
                          </Col>
                          <Col md={1} className='text-center'>
                          <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={() => window.open(process.env.REACT_APP_FACTURES_URL+f.code+'.pdf', '_blank')}>
                          <FileDownloadOutlinedIcon />
                          </IconButton>
                          </Col>
                          </Row>
                          </div>)
                        }
                      }) : (<div>
                        <Skeleton variant="rounded" height={60} className='mt-3'/>
                        <Skeleton variant="rounded" height={60} className='mt-3'/>
                        <Skeleton variant="rounded" height={60} className='mt-3'/>
                        <Skeleton variant="rounded" height={60} className='mt-3'/>
                        </div>)
                    } 

                    <div className='mt-3'>
                      {numPage > 0 && <IconButton aria-label="appel" className="mr-2" onClick={() => prevPage("emises")}>
                          <KeyboardArrowLeftIcon />
                        </IconButton>
                      } 
                      {numPage+1} / {nbPages}
                      {listeFacturesEmises && (numPage+1)*nbPerPage < listeFacturesEmises.length && <IconButton aria-label="message" className="mr-2" onClick={() => nextPage("emises")}>
                          <KeyboardArrowRightIcon />
                        </IconButton>
                      }                                      
                    </div>
                  </Col>
                </Row>
              </TabPanel>              
              <TabPanel value="2">
                <Row className='mt-4'>
                  <Col md={10} className={['custom-scrollbar','h-100'].join(" ")}>      

                    <Row className="table-header align-items-center">
                      <Col><strong>Numéro</strong></Col>
                      <Col md={3}><strong>Specialiste</strong></Col>
                      <Col md={3}><strong>Cabinet</strong></Col>
                      <Col><strong>Date</strong></Col>
                      <Col><strong>Montant</strong></Col>
                      <Col><strong>Statut</strong></Col>
                      <Col md={1}></Col>
                    </Row>

                    {listeFacturesRecues ? listeFacturesRecues.map((f, i) => {	
                      var match = (filtre ? f.client.toLowerCase().indexOf(filtre.toLowerCase()) > -1 : false);
                      if((!filtre && f.inPage) || (filtre && match)) {
                        var mt = (i > 0 ? "mt-3" : "mt-1");
                        return (<div className = {mt}>
                          <Row className="table-row align-items-center">
                          <Col><p>{f.code}</p></Col>
                          <Col md={3}><p>{f.specialiste}</p></Col>
                          <Col md={3}><p>{f.cabinet_specialiste}</p></Col>
                          <Col><p>{f.date}</p></Col>
                          <Col><p>{f.montant} €</p></Col>
                          <Col>
                          <div className={['etiquette',getCouleurStatut(f.statut)].join(" ")}>{getLibelleStatut(f.statut)}</div>
                          </Col>
                          <Col md={1} className='text-center'>
                          <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={() => window.open(process.env.REACT_APP_FACTURES_URL+f.code+'.pdf', '_blank')}>
                            <FileDownloadOutlinedIcon />
                          </IconButton>
                          </Col>
                          </Row>
                          </div>)
                        }
                      }) : (<div>
                        <Skeleton variant="rounded" height={60} className='mt-3'/>
                        <Skeleton variant="rounded" height={60} className='mt-3'/>
                        <Skeleton variant="rounded" height={60} className='mt-3'/>
                        <Skeleton variant="rounded" height={60} className='mt-3'/>
                        </div>)
                    } 

                    <div className='mt-3'>
                      {numPage_r > 0 && <IconButton aria-label="appel" className="mr-2" onClick={() => prevPage("recues")}>
                          <KeyboardArrowLeftIcon />
                        </IconButton>
                      } 
                      {numPage_r+1} / {nbPages_r}
                      {listeFacturesRecues && (numPage_r+1)*nbPerPage < listeFacturesRecues.length && <IconButton aria-label="message" className="mr-2" onClick={() => nextPage("recues")}>
                          <KeyboardArrowRightIcon />
                        </IconButton>
                      }                                      
                    </div>
                  </Col>
                </Row>
              </TabPanel>
              <TabPanel value="3">
                <Row className='mt-4'>
                  <Col md={10} className={['custom-scrollbar','h-100'].join(" ")}>      

                    <Row className="table-header align-items-center">
                      <Col><strong>Numéro</strong></Col>
                      <Col><strong>Période</strong></Col>
                      <Col><strong>Date</strong></Col>
                      <Col><strong>Montant</strong></Col>
                      <Col><strong>Statut</strong></Col>
                      <Col md={1}></Col>
                    </Row>

                    {listeFacturesAbo ? listeFacturesAbo.map((f, i) => {
                      if(f.inPage) {
                        var mt = (i > 0 ? "mt-3" : "mt-1");
                        return (<div className = {mt}>
                          <Row className="table-row align-items-center">
                          <Col><p>{f.code}</p></Col>
                          <Col><p>{f.periode}</p></Col>
                          <Col><p>{f.date}</p></Col>
                          <Col><p>{f.montant} €</p></Col>
                          <Col>
                          <div className={['etiquette',getCouleurStatut(f.statut)].join(" ")}>{getLibelleStatut(f.statut)}</div>
                          </Col>
                          <Col md={1} className='text-center'>
                          <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={() => window.open(process.env.REACT_APP_FACTURES_URL+f.code+'.pdf', '_blank')}>
                          <FileDownloadOutlinedIcon />
                          </IconButton>
                          </Col>
                          </Row>
                          </div>)
                        }
                      }) : (<div>
                        <Skeleton variant="rounded" height={60} className='mt-3'/>
                        <Skeleton variant="rounded" height={60} className='mt-3'/>
                        <Skeleton variant="rounded" height={60} className='mt-3'/>
                        <Skeleton variant="rounded" height={60} className='mt-3'/>
                        </div>)
                    } 

                    <div className='mt-3'>
                      {numPage_a > 0 && <IconButton aria-label="appel" className="mr-2" onClick={() => prevPage("abo")}>
                          <KeyboardArrowLeftIcon />
                        </IconButton>
                      } 
                      {numPage_a+1} / {nbPages_a}
                      {listeFacturesAbo && (numPage_a+1)*nbPerPage < listeFacturesAbo.length && <IconButton aria-label="message" className="mr-2" onClick={() => nextPage("abo")}>
                          <KeyboardArrowRightIcon />
                        </IconButton>
                      }                                      
                    </div>
                  </Col>
                </Row>
              </TabPanel>
            </TabContext>
            }
            {!user.is_veto &&
            <Row className='mt-4'>
                <Col md={10} className={['custom-scrollbar','h-100'].join(" ")}>      

                  <Row className="table-header align-items-center">
                    <Col><strong>Numéro</strong></Col>
                    <Col md={3}><strong>Vétérinaire</strong></Col>
                    <Col md={3}><strong>Cabinet</strong></Col>
                    <Col><strong>Date</strong></Col>
                    <Col><strong>Montant</strong></Col>
                    <Col><strong>Statut</strong></Col>
                    <Col md={1}></Col>
                  </Row>

                  {listeFacturesRecues ? listeFacturesRecues.map((f, i) => {	
                    var match = (filtre ? f.veterinaire.toLowerCase().indexOf(filtre.toLowerCase()) > -1 || f.cabinet_veterinaire.toLowerCase().indexOf(filtre.toLowerCase()) > -1 : false);
                    if((!filtre && f.inPage) || (filtre && match)) {
                      var mt = (i > 0 ? "mt-3" : "mt-1");
                      return (<div className = {mt}>
                        <Row className="table-row align-items-center">
                        <Col><p>{f.code}</p></Col>
                        <Col md={3}><p>{f.veterinaire}</p></Col>
                        <Col md={3}><p>{f.cabinet_veterinaire}</p></Col>
                        <Col><p>{f.date}</p></Col>
                        <Col><p>{f.montant} €</p></Col>
                        <Col>
                        <div className={['etiquette',getCouleurStatut(f.statut)].join(" ")}>{getLibelleStatut(f.statut)}</div>
                        </Col>
                        <Col md={1} className='text-center'>
                        <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={() => window.open(process.env.REACT_APP_FACTURES_URL+f.code+'.pdf', '_blank')}>
                        <FileDownloadOutlinedIcon />
                        </IconButton>
                        </Col>
                        </Row>
                        </div>)
                      }
                    }) : (<div>
                      <Skeleton variant="rounded" height={60} className='mt-3'/>
                      <Skeleton variant="rounded" height={60} className='mt-3'/>
                      <Skeleton variant="rounded" height={60} className='mt-3'/>
                      <Skeleton variant="rounded" height={60} className='mt-3'/>
                      </div>)
                  } 

                  <div className='mt-3'>
                    {numPage_r > 0 && <IconButton aria-label="appel" className="mr-2" onClick={() => prevPage("recues")}>
                        <KeyboardArrowLeftIcon />
                      </IconButton>
                    } 
                    {numPage_r+1} / {nbPages_r}
                    {listeFacturesRecues && (numPage_r+1)*nbPerPage < listeFacturesRecues.length && <IconButton aria-label="message" className="mr-2" onClick={() => nextPage("recues")}>
                        <KeyboardArrowRightIcon />
                      </IconButton>
                    }                                      
                  </div>
                </Col>
              </Row>}
          </Col>
        </Row>
      </Container>
    </div>);
}

export default Factures;