import { React, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Prompt } from 'react-router';
import UserWidget from '../Dashboard/UserWidget';
import SideMenu from '../Dashboard/SideMenu';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import Container from 'react-bootstrap/Container';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {TextField, Button} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import IconButton from '@mui/material/IconButton';
import swal from 'sweetalert';
import Select from 'react-select';
import { Table, Column, HeaderCell, Cell } from 'rsuite-table';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../App/App.css';
import "rsuite-table/dist/css/rsuite-table.css";

import {useCredentials} from '../../Services/authServices';
import {getCabClients} from '../../Services/cabinetServices'; 
import {getClientDatas, getSpecialistes, sendInvitationVeto} from '../../Services/UserServices';
import {setRdv} from '../../Services/RDVServices';
import {getCabPrestations} from '../../Services/prestationsServices';


function NouveauCasReferes() {

    
    const queryParams = new URLSearchParams(window.location.search);  
    const { user, setUser } = useCredentials();
    const [needSave, setNeedSave] = useState({});
    const [editedRdv, setEditedRdv] = useState({});
    const [animalError, setAnimalError] = useState();  
    const [motifError, setMotifError] = useState();
    const [factureError, setFactureError] = useState();
    const [specialisteError, setSpecialisteError] = useState();
    const [prestationError, setPrestationError] = useState();
    const [listePrestations, setListePrestations] = useState([]);

    const liste_specialites = ['Médecine interne', 'Oncologie', 'Cardiologie', 'Chirurgie', 'Imagerie', 'Dermatologie', 'Neurologie', 'Reproduction', 'Anesthesie', 'Urgences & soins intensifs', 'Comportement'];

    const [specialistes, setSpecialistes] = useState([]);
    const [specialites, setSpecialites] = useState([]);
    const [listeClients, setListeClients] = useState([]);
    const [listeAnimaux, setListeAnimaux] = useState([]);

    const [sortColumn, setSortColumn] = useState();
    const [sortType, setSortType] = useState();

    const [open, setOpen] = useState(false);
    const handleClose = () => setOpen(false);  
    const [invitation, setInvitation] = useState({}); 
    const [prenomError, setPrenomError] = useState();
    const [nomError, setNomError] = useState();
    const [emailError, setEmailError] = useState();    
    const [loading, setLoading] = useState(false);
    
    useEffect(() => {

        const selected = queryParams.get('client');
        const selectedAnimal = queryParams.get('animal');

        let rdvVals = {
            'id': null,
            'specialite': null,
            'id_client': selected,
            'id_animal': selectedAnimal
        }

        if(!user) { window.open('/login', '_self'); }
        else if(!user.is_veto || !user.licence || !user.licence.avec_cas_referes) { window.open('/', '_self'); }
        else {
            getCabClients(user).then(res => {
                if(res != undefined && res.data != undefined && res.data.statut == "ok") {     

                    let optionsClients = [];
                    res.data.clients.map((c, i) => {
                        optionsClients.push({value: c.id, label: c.prenom+' '+c.nom});
                    });
                    setListeClients(optionsClients);

                    if(selected != null) {
                        getClientDatas(user, selected).then(res_a => {
                            if(res_a != undefined && res_a.data != undefined && res_a.data.statut == "ok") {
                    
                              let optionsAnimaux = [];
                              res_a.data.donnees.animaux.map((c, i) => {
                                optionsAnimaux.push({value: c.id, label: c.nom});
                              }); 
                              setListeAnimaux(optionsAnimaux);
                              if(selectedAnimal != null) {
                                res_a.data.donnees.animaux.map((a, i) => {
                                    if(a.id == selectedAnimal) { rdvVals['id_animal'] = a.id; }
                                });
                              }
                              else if(res_a.data.donnees.animaux.length > 0) { rdvVals['id_animal'] = res_a.data.donnees.animaux[0].id; }
                            }
                        });
                    }
                }
            });

            getSpecialistes(user).then(res => {
                if(res != undefined && res.data != undefined && res.data.statut == "ok") {     

                    let optionsSp = [];
                    res.data.donnees.map((c, i) => {
                        optionsSp.push(c);
                    });
                    setSpecialistes(optionsSp);
                    setSpecialites(optionsSp2);
                }
            });
            
            setEditedRdv(rdvVals);    
            
            let optionsSp2 = [];
            let id_spe = 2;
            liste_specialites.map((sp, i) => {
                if(i == 0) rdvVals.specialite = sp;
                optionsSp2.push({value: id_spe, label: sp}); 
                id_spe++;
            });
            setSpecialites(optionsSp2);
        } 

    }, [user]);


    const updatelisteAnimaux = (e) => {
        let rdvVals = {...editedRdv};
        setListeAnimaux([]);
    
        getClientDatas(user, e.value).then(res => {
            if(res != undefined && res.data != undefined && res.data.statut == "ok") {
    
              let optionsAnimaux = [];
              res.data.donnees.animaux.map((c, i) => {
                optionsAnimaux.push({value: c.id, label: c.nom});
              }); 
              setListeAnimaux(optionsAnimaux);
              if(res.data.donnees.animaux.length > 0) { rdvVals['id_animal'] = res.data.donnees.animaux[0].id; }
            }
        });
        
        rdvVals['id_client'] = e.value;
        setEditedRdv(rdvVals);
    }

    const handleSortColumn = (sortColumn, sortType) => {
        setSortColumn(sortColumn);
        setSortType(sortType);
    };

    const dateFrToTime = (d) => {
        let _d = d.split('/');
        let d_obj = new Date(_d[2]+'-'+_d[1]+'-'+_d[0]);
        return d_obj.getTime();
    }; 

    const listeTriee = (specialite, sortColumn, sortType = 'desc') => {
        if(specialistes) {

            let liste = [...specialistes];
            let liste_filtree = [];
            liste.map((sp, i) => {
                if(specialite == 'Indifférent' || sp.specialite == specialite) liste_filtree.push(sp);
            });

            return liste_filtree.sort((a, b) => {

                let x = a[sortColumn];
                let y = b[sortColumn];
                
                if(sortColumn == 'date_ajout') { 
                    x = dateFrToTime(x);
                    y = dateFrToTime(y);
                    return (sortType === 'asc' ? x - y : y - x);
                }
                else if(typeof x == 'string' && typeof y == 'string') {
                    if (typeof x != 'string') {x = x.toString();}
                    if (typeof y != 'string') {y = y.toString();}
                    return "a5b".localeCompare("a21b", undefined, { numeric: true }) * (sortType === 'asc' ? -1 : 1);
                }
                else {
                    return (sortType === 'asc' ? x - y : y - x);
                }
            });
        }
        else return [];
    }

    const CheckCell = ({ rowData, onChange, dataKey, ...props }) => {        
        return <Cell {...props} style={{ padding: 0 }}>
            <Checkbox
                value={rowData[dataKey]}
                inline
                onChange={onChange}
                checked={(editedRdv.id_specialiste && editedRdv.id_specialiste == rowData[dataKey])}
            />
        </Cell>
    }

    const handleCheck = (e, checked) => {
        let rdvVals = {...editedRdv};
        rdvVals['id_specialiste'] = (checked ? e.target.value : null);
        setEditedRdv(rdvVals);
        if(checked) changeSpecialiste(e.target.value);
    };

    const CheckCellPst = ({ rowData, onChange, dataKey, ...props }) => {        
        return <Cell {...props} style={{ padding: 0 }}>
            <Checkbox
                value={rowData[dataKey]}
                inline
                onChange={onChange}
                checked={(editedRdv.type_prestation && editedRdv.type_prestation == rowData[dataKey])}
            />
        </Cell>
    }

    const handleCheckPst = (e, checked) => {
        let rdvVals = {...editedRdv};
        rdvVals['type_prestation'] = (checked ? e.target.value : null);
        setEditedRdv(rdvVals);
    };

    const changeSpecialiste = (id_specialiste) => {
        specialistes.map((sp, i) => {
            if(sp.id == id_specialiste) {
                getCabPrestations(user, sp.cabinet.id).then(res => {
                    if(res != undefined && res.data != undefined && res.data.statut == "ok") {

                        let pst = [];
                        res.data.donnees.map((p, i) => {
                            if(p.dediee_cas_ref == true) pst.push(p);
                        });
                        setListePrestations(pst);
                    }
                });
            }
        });
    }


    const updateSpecialiteRdv = (e) => {
        let rdvVals = {...editedRdv};
        rdvVals['specialite'] = e.label;
        setEditedRdv(rdvVals);
    }
    const updateRdv = (e) => {
        let rdvVals = {...editedRdv};
        rdvVals[e.target.id] = e.target.value;
        setEditedRdv(rdvVals);
    }    
    const updateAnimalRdv = (e) => {
        let rdvVals = {...editedRdv};
        rdvVals['id_animal'] = e.value;
        setEditedRdv(rdvVals);
    }
    const updateFactureRdv = (e) => {
        let rdvVals = {...editedRdv};
        rdvVals['facture_a'] = e.value;
        setEditedRdv(rdvVals);
    }

    const saveRdv = () => {

        setMotifError(null);
        setAnimalError(null);
        setFactureError(null);
        setSpecialisteError(null);
        setPrestationError(null);

        let rdvVals = {...editedRdv};
        let nb_error = 0;
        if(!(rdvVals.motif_consultation && rdvVals.motif_consultation.length > 0)) { setMotifError('Ce champs est obligatoire'); nb_error++; }
        if(!(rdvVals.id_animal && rdvVals.id_animal.toString().length > 0)) { setAnimalError('Ce champs est obligatoire'); nb_error++; }
        if(!(rdvVals.facture_a && rdvVals.facture_a.length > 0)) { setFactureError('Ce champs est obligatoire'); nb_error++; }
        if(!(rdvVals.id_specialiste && rdvVals.id_specialiste.length > 0)) { setSpecialisteError('Ce champs est obligatoire'); nb_error++; }
        if(!(rdvVals.type_prestation && rdvVals.type_prestation.length > 0)) { setPrestationError('Ce champs est obligatoire'); nb_error++; }
        rdvVals.id_veterinaire = user.id;

        if(nb_error == 0) {
            setLoading(true);
            setRdv(user, null, rdvVals).then(res => {
                if(res != undefined && res.data != undefined && res.data.statut == "ok") {
                    setLoading(false);
                    setEditedRdv({
                        'id': null,
                        'specialite': 'Indifférent',
                        'id_client': null,
                        'id_animal': null
                    });
                    swal({
                        title: "Terminé !",
                        text: "Votre cas est créé",
                        icon: "success",
                        buttons: true,
                    });
                }
            });
        }
    }



    const updateInvitation = (e) => {
        let invitVals = {...invitation};
        invitVals[e.target.id] = e.target.value;
        setInvitation(invitVals);        
    }
    
    const invitationPartenaire = () => {
        
        setPrenomError(null);
        setNomError(null);
        setEmailError(null);

        let nb_errors = 0;
        if(!invitation.prenom || invitation.prenom.length == 0) { setPrenomError('Ce champ est obligatoire'); nb_errors++; }
        if(!invitation.nom || invitation.nom.length == 0) { setNomError('Ce champ est obligatoire'); nb_errors++; }
        if(!invitation.email || invitation.email.length == 0) { setEmailError('Ce champ est obligatoire'); nb_errors++; }

        if(nb_errors == 0) {
            sendInvitationVeto(user, invitation).then(res => {
                if(res != undefined && res.data != undefined && res.data.statut == "ok") {
                    setInvitation({});
                    setOpen(false);
                    swal({
                        title: "Envoyé !",
                        text: "Votre spécialiste à reçu son invitation par mail.",
                        icon: "success",
                        buttons: true,
                    });
                }
            });
        }
    }


    return(
        <div className="fullpage">
        <Container className='full_container'>
            
            <UserWidget /> 
            {user.is_veto && <SideMenu user={user} setUser={setUser}/>}
            
            <Row className={[(user.menuClosed ? 'withSidemenu-closed' : 'withSidemenu'), 'h-auto'].join(" ")}>

                <Col xs={10} className={['enteteFiche','pt-5'].join(" ")}>
                    
                    <Row className="align-items-center">
                        <Col className={['d-flex','justify-content-end', 'pe-1'].join(" ")} md={1}>
                            <IconButton className="arrowButtonLeft" aria-label="retour" onClick={() => window.open('/clients', '_self')}>
                                <ArrowBackIcon/>
                            </IconButton>
                        </Col>
                        <Col md={4}>
                            <h3 className="mb-1">Nouveau</h3>
                            <h2 className="bold">CAS RÉFÉRÉ</h2>
                        </Col>
                    </Row>
                </Col>

                <Col md={9} className="px-5 pb-5">
                    <div className={['corpsFiche','borderLeft'].join(" ")}>

                        <Row>
                            <Col>
                                <p className='smallText'>Propriétaire</p>
                                <FormControl variant="standard" sx={{ width: '100%' }}>
                                    <Select
                                        labelId="rdv_animal"
                                        id="id_client"
                                        value={editedRdv.id_client ? listeClients.filter((c) => c.value == editedRdv.id_client) : ''}
                                        onChange={updatelisteAnimaux}
                                        label="Client"
                                        options={listeClients}
                                        placeholder="Client"
                                        >                        
                                    </Select>
                                </FormControl>
                            </Col>
                            <Col>
                                <p className='smallText'>Animal</p>
                                <FormControl variant="standard" sx={{ width: '100%' }} error={!!animalError}>
                                    <Select
                                    labelId="rdv_animal"
                                    id="id_animal"
                                    value={editedRdv.id_animal ? listeAnimaux.filter((c) => c.value == editedRdv.id_animal) : ''}
                                    onChange={updateAnimalRdv}
                                    label="Animal"
                                    options={listeAnimaux}
                                    placeholder="Animal"
                                    >
                                    </Select>
                                </FormControl>
                                {animalError && <small className='textRed'>{animalError}</small>}
                            </Col>
                        </Row>
                        <Row className='mt-3'>
                            <Col>
                                <p className='smallText'>Spécialité requise</p>
                                <FormControl variant="standard" sx={{ width: '100%' }}>
                                    <Select
                                        labelId="specialite"
                                        id="specialite"
                                        value={editedRdv.specialite ? specialites.filter((c) => c.label == editedRdv.specialite) : ''}
                                        onChange={updateSpecialiteRdv}
                                        label="Spécialité"
                                        options={specialites}
                                        placeholder="Spécialité"
                                        >                        
                                    </Select>
                                </FormControl>
                            </Col>
                            <Col>
                                <p className='smallText'>Facturer au</p>
                                <Select
                                    id="facture_a"
                                    value={editedRdv.facture_a ? 
                                        [{ value:'client', label:'Propriétaire de l\'animal' }, { value:'veterinaire', label:'Cabinet généraliste'}].filter((c) => c.value == editedRdv.facture_a) 
                                        : ''
                                    }
                                    onChange={updateFactureRdv}
                                    placeholder="Facturé à"
                                    options={[{ value:'client', label:'Propriétaire de l\'animal' }, { value:'veterinaire', label:'Cabinet généraliste'}]}
                                    >                      
                                </Select>                                
                                {factureError && <small className='textRed'>{factureError}</small>}
                            </Col>
                        </Row>
                        <Row className='mt-3'>
                            <Col>
                                <p className='smallText'>Motif de consultation</p>
                                <BaseTextareaAutosize 
                                    id="motif_consultation" 
                                    label="Motif de consultation" 
                                    variant="standard" 
                                    value={editedRdv.motif_consultation ? editedRdv.motif_consultation : ''}
                                    onChange={updateRdv}
                                    className="mt-1 mb-1 w-100"
                                    minRows={3}
                                    placeholder="..." 
                                />
                                {motifError && <small className='textRed'>{motifError}</small>}
                            </Col>
                        </Row>

                        <Row>
                            <Col md={4}>
                                <p className={['titre','bold','mt-4','mb-2'].join(" ")}>Spécialiste</p>
                            </Col>
                            <Col md={8} className={['d-flex','align-items-center','justify-content-end'].join(" ")}>
                                <em className='smallText textSecondary'>Vous ne trouvez pas votre spécialiste ? Invitez-le</em>
                                <IconButton aria-label="message" className="ms-2" onClick={() => setOpen(true)}>
                                    <MailOutlineIcon />
                                </IconButton>
                            </Col>
                        </Row>
                        <Table 
                            className='w-100'
                            wordWrap="break-word" 
                            autoHeight
                            height={300} 
                            headerHeight={50}
                            data={listeTriee(editedRdv.specialite, sortColumn, sortType)}
                            sortColumn={sortColumn}
                            sortType={sortType}
                            onSortColumn={handleSortColumn}
                        >
                            <Column width={50} align="center">
                                <HeaderCell></HeaderCell>
                                <CheckCell dataKey="id" onChange={handleCheck} />
                            </Column>
                            <Column flexGrow={1} sortable>
                                <HeaderCell>Nom</HeaderCell>
                                <Cell dataKey="nom">{rowData => rowData.prenom+' '+rowData.nom}</Cell>
                            </Column>
                            <Column flexGrow={1} sortable>
                                <HeaderCell>Cabinet</HeaderCell>
                                <Cell dataKey="cabinet.nom" />
                            </Column> 
                            <Column flexGrow={1} sortable>
                                <HeaderCell>Numéro d'ordre</HeaderCell>
                                <Cell dataKey="numero_ordre" />
                            </Column>
                        </Table>
                        {specialisteError && <div><small className='textRed'>{specialisteError}</small></div>}

                        <p className={['titre','bold','mt-4','mb-2'].join(" ")}>Prestation</p>
                        {editedRdv.id_specialiste != null ? <Table 
                            className='w-100'
                            wordWrap="break-word" 
                            autoHeight
                            height={300} 
                            headerHeight={50}
                            data={listePrestations}
                        >
                            <Column width={50} align="center">
                                <HeaderCell></HeaderCell>
                                <CheckCellPst dataKey="type_prestation" onChange={handleCheckPst} />
                            </Column>
                            <Column flexGrow={1}>
                                <HeaderCell>Nom</HeaderCell>
                                <Cell dataKey="nom_prestation" />
                            </Column>
                            <Column flexGrow={1}>
                                <HeaderCell>Type de prestation</HeaderCell>
                                <Cell dataKey="a_distance">{rowData => rowData.a_distance ? 'A distance (visio)': 'En cabinet'}</Cell>
                            </Column> 
                            <Column flexGrow={1}>
                                <HeaderCell>Délai de réponse</HeaderCell>
                                <Cell dataKey="delai" />
                            </Column> 
                            <Column flexGrow={1}>
                                <HeaderCell>Prix fixe</HeaderCell>
                                <Cell dataKey="prix_fixe">{rowData => rowData.prix_fixe+' €'}</Cell>
                            </Column>
                            <Column flexGrow={1}>
                                <HeaderCell>Prix horaire</HeaderCell>
                                <Cell dataKey="prix_fixe">{rowData => rowData.a_distance ? rowData.prix_horaire+' €' : '-'}</Cell>
                            </Column> 
                        </Table>  :
                        <em className='smallText'>Sélectionnez un spécilaliste dans la liste ci-dessus. Les prestations disponibles seront celles de son cabinet</em>
                        }                        
                        {prestationError && <div><small className='textRed'>{prestationError}</small></div>}

                        <div className='mt-5'>
                            <Button variant="contained" className="buttonSecondary" type="submit" onClick={saveRdv} startIcon={(loading ? <CircularProgress/> : <CheckIcon />)}>Valider</Button>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>


        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: { timeout: 500 },
            }}
        >
        <Fade in={open}>
            <Box className="modal-sm">
                <p className={['titre','bold','mb-2'].join(" ")}>Inviter un vétérinaire</p>

                <IconButton aria-label="modifier" className="modalExit" onClick={handleClose}>
                    <CloseIcon/>
                </IconButton>

                <TextField 
                    id="prenom" 
                    label="Prénom" 
                    variant="standard" 
                    value={invitation && invitation.prenom ? invitation.prenom : ''}
                    onChange={updateInvitation}
                    className="w-100 mt-1"
                    error={!!prenomError}
                    helperText={prenomError}
                />
                <TextField 
                    id="nom" 
                    label="Nom" 
                    variant="standard" 
                    value={invitation && invitation.nom ? invitation.nom : ''}
                    onChange={updateInvitation}
                    className="w-100 mt-1"
                    error={!!nomError}
                    helperText={nomError}
                />
                <TextField 
                    id="email" 
                    label="Email" 
                    variant="standard" 
                    value={invitation && invitation.email ? invitation.email : ''}
                    onChange={updateInvitation}
                    className="w-100 mt-1"
                    error={!!emailError}
                    helperText={emailError}
                />                
                <TextField 
                    id="message" 
                    label="Message (facultatif)" 
                    variant="standard" 
                    value={invitation && invitation.message ? invitation.message : ''}
                    onChange={updateInvitation}
                    className="w-100 mt-1"
                />
                
                <Button variant="contained" className={['buttonSecondary','mt-5'].join(" ")} type="submit" startIcon={(loading ? <CircularProgress/> : <CheckIcon />)} onClick={invitationPartenaire}>Inviter</Button> 
            </Box>
        </Fade> 
        </Modal>

        <Prompt
            when={needSave}
            message='You have unsaved changes, are you sure you want to leave?'
        />
        </div>
    )
}

export default NouveauCasReferes;