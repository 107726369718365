import { React, useEffect, useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { Prompt } from 'react-router';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CircularProgress from '@mui/material/CircularProgress';
import {Button} from '@mui/material';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../App/App.css';

import {useCabCredentials, saveCabinet} from '../../Services/cabinetServices'; 


function ValidationCabinet() {

    const { cabinet } = useCabCredentials();
    const [loading, setLoading] = useState(true);
    
    useEffect(() => {        
        if(cabinet == null) { window.open('/inscription_cabinet','_self'); }
        else {
            handleLoading(true);
            saveCabinet(cabinet).then(function(res) {
                if(res != undefined && res.data != undefined && res.data.statut == "ok") {
                    handleLoading(false);
                }
            });
        }        
    }, []);

    const handleLoading = (val) => {
        setLoading(val);  
        if(val) {
            window.onbeforeunload = () => true
        } else {
            window.onbeforeunload = undefined
        }
    }

    return(
        <div className="fullpage">
        <Container className={['full_container','inscription_cabinet_page','align_items_start'].join(" ")}>
            
            <div className='user_widgets'>  
                <div className="logo">          
                    <img src='/img/logo.png' className="w-100" alt="logo"/>
                </div>
            </div>
            
            <Row className={['w-100',' ps-4'].join(" ")}>

                <Col xs={10} className={['px-5','pt-5'].join(" ")}>
                    <Row>
                        <Col md={8}>
                            <h2 className="bold">Merci !</h2>
                            {loading == true ? 
                                <p className='textSecondary'>Ne rechargez pas la page, nous finalisons votre inscription.</p>
                                : <div>
                                <p className='textSecondary'>Un mail vous a été envoyé avec vos identifiants de connexion.</p>
                                <p className='textSecondary'>Cliquez sur le lien ci-dessous pour revenir à l'accueil</p>
                            </div>
                            }

                            {loading == true ? <div className={['d-flex','align-items-center','mt-5'].join(" ")}>
                                    <CircularProgress className='me-3'/>
                                    <p className='textSecondary'>Veuillez patientez... </p>
                                </div> :
                                <Button variant="contained" className={['buttonSecondary','mt-5'].join(" ")} startIcon={<ArrowBackIcon />} onClick={() => window.open('/','_self')}>Accueil</Button>
                            }
                        </Col>
                    </Row>
                </Col>
               
            </Row>
        </Container>

        <Prompt
            when={loading}
            message='You have unsaved changes, are you sure you want to leave?'
        />
        </div>
    )
}

export default ValidationCabinet;