import { React, useEffect, useState } from 'react';
import UserWidget from '../Dashboard/UserWidget';
import SideMenu from '../Dashboard/SideMenu';
import SearchIcon from '@mui/icons-material/Search';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from '@mui/material/Card';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import {Skeleton, TextField, Button} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import Select from 'react-select'
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import swal from 'sweetalert';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../App/App.css';

import {useCredentials} from '../../Services/authServices';  
import {getRdvsReferes} from '../../Services/RDVServices';
import {getCabPrestations} from '../../Services/prestationsServices';
import {uploadDocument} from '../../Services/FileServices';

function Factures() {

    const { user, setUser } = useCredentials();
    const queryParams = new URLSearchParams(window.location.search);
    const [listeCasReferes, setListeCasReferes] = useState([]); 
    const [numPage, setNumPage] = useState(0);
    const [nbPages, setNbPages] = useState(0);
    const nbPerPage = 5; 
    const [filtre, setFiltre] = useState();
    
    const [prestations, setPrestations] = useState([]);   
    const [caProjete, setCaProjete] = useState(0);  
    const [caRealise, setCaRealise] = useState(0);
    const [casOuverts, setCasOuverts] = useState(0);
    const [casFermes, setCasFermes] = useState(0);
    const [casRetards, setCasRetards] = useState(0);
    const [rapporteurs, setRapporteurs] = useState(0);

    const [editedRdv, setEditedRdv] = useState({});
    const type_documents = ['lettre_refere','formulaire_antecedents','resultats','compte_rendu','ordonnance','autre'];
    const [listeSousTypesDocument, setListeSousTypesDocument] = useState([]);
    const sous_types_resultats = ['Biochimie', 'Hématologie', 'Analyse Urinaires', 'Radiographie', 'Echographie', 'Scanner', 'IRM', 'Scintigraphie', 'Cytopathologie', 'Histopathologie', 'Autres analyses'];
    const sous_types_cr = ['Médecine interne', 'Oncologie', 'Cardiologie', 'Chirurgie', 'Imagerie', 'Dermatologie', 'Neurologie', 'Reproduction', 'Anesthesie', 'Urgences & soins intensifs', 'Comportement'];
    const [open, setOpen] = useState(false);
    const [editedDoc, setEditedDoc] = useState({}); 
    const [nomError, setNomError] = useState();
    const [typeDocError, setTypeDocError] = useState();
    const [fileError, setFileError] = useState();
    const [consultError, setConsultError] = useState();
    const [loading, setLoading] = useState(false);
    const handleClose = () => setOpen(false);  
  
    useEffect(() => {

        const queryPage = queryParams.get('page');
        if(queryPage != null) setNumPage(queryPage);

        if(!user) { window.open('/login', '_self'); }
        else if(!user.is_veto) { window.open('/', '_self'); }
        else {
            getRdvsReferes(user, user.cabinet.id, user.id).then(res => {
                if(res != undefined && res.data != undefined && res.data.statut == "ok") { 
                    
                    getCabPrestations(user, user.cabinet.id).then(res_p => {
                        if(res_p != undefined && res_p.data != undefined && res_p.data.statut == "ok") { 
                            setPrestations(res_p.data.donnees);

                            var liste_cas = [];
                            var ca_projete = 0;
                            var ca_realise = 0;
                            var nb_cas_ouverts = 0;
                            var nb_cas_fermes = 0;
                            var nb_retards = 0;
                            var liste_rapporteurs = [];

                            res.data.donnees.map((r, i) => {
                                r.inPage = false;
                                if(i >= numPage*nbPerPage && i < (numPage+1)*nbPerPage) { r.inPage = true; }
                                liste_cas.push(r);

                                if(r.cabinet_specialiste == user.cabinet.id) { 
                                    
                                    var ajd = new Date();
                                    var date_rdv = (r.date_rdv != null ? new Date(r.date_rdv) : null);
                                    var en_retard = (ajd > date_rdv && r.date_cloture == null);
                                    if(en_retard) nb_retards++;

                                    if(liste_rapporteurs.indexOf(r.id_veterinaire) == -1) liste_rapporteurs.push(r.id_veterinaire);

                                    res_p.data.donnees.map((p, j) => {	
                                        if(p.type_prestation == r.type_prestation) {
                                            if(p.prix_fixe || p.prix_horaire) {

                                                if(r.date_cloture == null) {
                                                    ca_projete += (p.prix_fixe + (p.prix_horaire * 15));
                                                    nb_cas_ouverts++;
                                                }
                                                else {
                                                    ca_realise += (p.prix_fixe + (p.prix_horaire * 15));
                                                    nb_cas_fermes++;
                                                }
                                            }
                                        }
                                    });                                
                                }
                            });
                            
                            setNbPages(Math.trunc(res.data.donnees.length/nbPerPage)+1);
                            
                            setListeCasReferes(liste_cas);
                            setCaProjete(ca_projete);
                            setCaRealise(ca_realise);
                            setCasOuverts(nb_cas_ouverts);
                            setCasFermes(nb_cas_fermes);
                            setCasRetards(nb_retards);
                            setRapporteurs(liste_rapporteurs.length);
                            
                        }
                    });
                }
            });        
        }
    }, [user]);


    const nextPage = () => {    

        let _numPage = numPage;
        var liste_cas = [];
        listeCasReferes.map((c, i) => {
        c.inPage = false;
        if(i >= (_numPage+1)*nbPerPage && i < (_numPage+2)*nbPerPage) { c.inPage = true; }
        liste_cas.push(c); 
        });

        setListeCasReferes(liste_cas);
        setNumPage((_numPage+1));
    }

    const prevPage = () => {

        let _numPage = numPage;
        if(_numPage > 0) {
            var liste_cas = [];
            listeCasReferes.map((c, i) => {
                c.inPage = false;
                if(i >= (_numPage-1)*nbPerPage && i < (_numPage)*nbPerPage) { c.inPage = true; }
                liste_cas.push(c); 
            });

            setListeCasReferes(liste_cas);
            setNumPage((_numPage-1));
        }
    }

    const updateFiltre = (e) => {
        if(e.target.value.length == 0 || e.target.value.length > 2) setFiltre(e.target.value);
    }


    const handleImportDoc = (rdv) => {
        setEditedRdv(rdv);
        setOpen(true);
    }

    const updateDoc = (e) => {
        let docVals = {...editedDoc};
        docVals[e.target.id] = e.target.value;
        setEditedDoc(docVals);
    }    
    const updateTypeDocument = (type) => {
        let docVals = {...editedDoc};
        docVals['type_document'] = type;
        setEditedDoc(docVals);

        let liste = [];
        if(type == 'resultats') {
            sous_types_resultats.map((t, i) => {
                liste.push({ value: t, label: t});
            });
        }
        else if(type == 'compte_rendu') {
            sous_types_cr.map((t, i) => {
                liste.push({ value: t, label: t});
            });
        }
        setListeSousTypesDocument(liste);
    }  
    const updateSousTypeDocument = (e) => {
        let docVals = {...editedDoc};
        docVals['sous_type_document'] = e.value;
        setEditedDoc(docVals);
    }
    const updateRdvs = (selected) => {        
        let tab = []
        let docVals = {...editedDoc};
        if(selected) {
            selected.map((rdv, i) => { tab.push(rdv.value); });
        }
        docVals['ids_rdvs'] = tab.join(',');
        setEditedDoc(docVals);
    }

    const handleFileChange = (e) => {

        if (e.target.files) {

            const file = e.target.files[0];
            const allowed_formats = ['application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'image/jpeg', 'image/png', 'application/pdf'];

            if(file.size > 5000000) { setFileError('L\'image est trop lourde'); }
            else if(allowed_formats.indexOf(file.type) == -1) { setFileError('Les formats autorisés sont pdf, docx, jpg et png'); }
            else {
                setFileError(null);
                let docVals = {...editedDoc};
                docVals['doc'] = file;
                docVals['format'] = file.type;
                docVals['nom_fichier'] = file.name;
                setEditedDoc(docVals);            
            }            
        }
    }

    const importDoc = () => {

        setNomError(null);
        setTypeDocError(null);
        setFileError(null); 
        setConsultError(null);

        let nb_errors = 0;
        if(!editedDoc.nom || editedDoc.nom.length == 0) { setNomError('Ce champ est obligatoire'); nb_errors++; }
        if(!editedDoc.type_document || editedDoc.type_document.length == 0) { setTypeDocError('Ce champ est obligatoire'); nb_errors++; }
        if(!editedDoc.doc) { setFileError('Ce champ est obligatoire'); nb_errors++;}

        if(editedRdv && nb_errors == 0) {
            setLoading(true);
            uploadDocument(user, editedDoc.doc, editedDoc.format, editedDoc.nom, editedRdv.id_animal, editedDoc.type_document, editedDoc.sous_type_document, editedRdv.id.toString()).then((res) => {
                if(res != undefined && res.data != undefined && res.data.statut == "ok") {               
                    setOpen(false);
                    swal({
                        title: "Ok !",
                        text: "Le document est enregistré.",
                        icon: "success",
                        buttons: true,
                    });
                }
                setLoading(false);
            }); 
        }  
    }

    const libelleType = (type_doc) => {

        let nom = '';
        switch (type_doc) {
            case 'lettre_refere': nom = 'Lettre(s) de référés'; break;
            case 'formulaire_antecedents': nom = 'Formulaire(s) d\'antécédents'; break;
            case 'resultats': nom = 'Résultats de laboratoire'; break;
            case 'compte_rendu': nom = 'Compte-rendu(s) de spécialiste(s)'; break;
            case 'ordonnance': nom = 'Ordonnance(s)'; break;
            case 'autre': nom = 'Autres documents'; break;
        }
        return nom;
    }

    const listeTypesDocument = [];
    type_documents.map((t, i) => {
        listeTypesDocument.push({ value: t, label: libelleType(t)});
    });

  
    return (
        <div className="fullpage">
        <Container className='full_container'>
            
            <UserWidget /> 
            {user.is_veto && <SideMenu user={user} setUser={setUser}/>}
                
            <Row className={user.is_veto ? [(user.menuClosed ? 'withSidemenu-closed' : 'withSidemenu'), 'h-auto'].join(" ") : ['w-100', 'h-auto', ' ps-4'].join(" ")}>
            <Col md={12} className={['ps-5','py-5','hmax-100'].join(" ")}>
                
                <Row>
                    <Col md={3}>
                        <h2>Cas référés</h2>
                        <hr width="80%" className="black_line"></hr>
                        {!user.is_veto && <div>
                        <IconButton className="ms-0" aria-label="retour" onClick={() => window.open('/client/'+user.id, '_self')}>
                            <ArrowBackIcon/>
                        </IconButton>
                        <span className='ms-2'>Retour</span>
                        </div>}
                    </Col>
                    <Col md={4}>
                        <div className={['searchBar','mb-2','mx-3'].join(" ")}>
                        <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                            <SearchIcon />
                        </IconButton>
                        <InputBase placeholder="Rechercher..." className='w-100' onChange={updateFiltre}/>
                        </div>
                    </Col>
                </Row>
                    
                <Col md={11}>
                    <Card className={['p-4','mt-4'].join(" ")}>
                        <Row>
                            <Col md={4} className='px-4'>
                                <div className={['d-flex','align-items-baseline'].join(" ")}>                                
                                    <strong className={['textSecondary','bigNumber','me-2'].join(" ")}>{casOuverts}</strong>
                                    <p className='smallText'>cas référé(s) ouvert(s)</p>
                                </div>
                                <div className='d-block'>
                                    <p><span className={['fw-bold','me-2'].join(" ")}>{casRetards}</span><span className='smallText'>en retard</span></p>
                                </div>
                            </Col>
                            <Col md={4} className={['px-4','borderLeftGrey'].join(" ")}>
                                <div className={['d-flex','align-items-baseline'].join(" ")}>      
                                    <strong className={['textSecondary','bigNumber','me-2'].join(" ")}>{caProjete} €</strong>
                                    <p className='smallText'>de chiffre d'affaires HT potentiel <sup><small>*</small></sup></p>
                                </div>
                                <div className='d-block'>
                                    <p><span className={['fw-bold','me-2'].join(" ")}>{rapporteurs}</span><span className='smallText'>rapporteur(s)</span></p>
                                </div>
                            </Col>
                            <Col md={4} className={['px-4','borderLeftGrey'].join(" ")}>
                                <div className={['d-flex','align-items-baseline'].join(" ")}>   
                                    <strong className={['textSecondary','bigNumber','me-2'].join(" ")}>{caRealise} €</strong>
                                    <p className='smallText'>de chiffre d'affaires HT réalisé <sup><small>*</small></sup></p>
                                </div>
                                <div className='d-block'>
                                    <p><span className={['fw-bold','me-2'].join(" ")}>{casFermes}</span><span className='smallText'>cas référé(s) clôs</span></p>
                                </div>
                            </Col>
                        </Row>
                    </Card>
                </Col>

                <Row className='mt-4'>
                    <Col md={11} className={['custom-scrollbar','h-100'].join(" ")}>      

                    <Row className="table-header align-items-center">
                        <Col><strong>Type de cas</strong></Col>
                        <Col><strong>Rapporteur</strong></Col>
                        <Col><strong>propriétaire</strong></Col>
                        <Col><strong>Animal</strong></Col>
                        <Col><strong>Date de création</strong></Col>
                        <Col><strong>Délai de réponse</strong></Col>
                        <Col><strong>Clôturé</strong></Col>
                        <Col><strong>En retard</strong></Col>
                        <Col md={1}>Actions</Col>
                    </Row>

                    {listeCasReferes ? listeCasReferes.map((r, i) => {	
                        var match = (filtre ? r.prestation.toLowerCase().indexOf(filtre.toLowerCase()) > -1 || 
                                        r.veterinaire.toLowerCase().indexOf(filtre.toLowerCase()) > -1 || 
                                        r.client.toLowerCase().indexOf(filtre.toLowerCase()) > -1 || 
                                        r.animal.toLowerCase().indexOf(filtre.toLowerCase()) > -1 
                                    : false);
                        var ajd = new Date();
                        var date_rdv = (r.date_rdv != null ? new Date(r.date_rdv) : null);
                        var en_retard = (ajd > date_rdv && r.date_cloture == null);
                        if((!filtre && r.inPage) || (filtre && match)) {
                        var mt = (i > 0 ? "mt-3" : "mt-1");
                        return (<div className = {mt}>
                            <Row className="table-row align-items-center">
                                <Col><p>{r.prestation}</p></Col>
                                <Col><p>{r.veterinaire}</p></Col>
                                <Col><p>{r.client}</p></Col>
                                <Col><p>{r.animal}</p></Col>
                                <Col><p>{r.date_creation_formatted}</p></Col>
                                <Col><p>{r.delai != null ? r.delai : 'Non renseigné'}</p></Col>
                                <Col>{r.date_cloture != null ? 
                                    <div className={['etiquette','success'].join(' ')}>le {r.date_cloture_formatted}</div> : 
                                    <p>Non</p>}
                                </Col>
                                <Col>{en_retard == true ? 
                                    <div className={['etiquette','danger'].join(' ')}>Oui</div> : 
                                    <p>Non</p>}
                                </Col>
                                <Col md={1} className={['d-flex','align-items-center'].join(' ')}>
                                    <IconButton type="button" sx={{ p: '10px' }} aria-label="search" title="Consulter" onClick={() => window.open('/animal/'+r.id_animal+'?onglet=2','_self')}>
                                        <SearchIcon />
                                    </IconButton>
                                    <IconButton type="button" sx={{ p: '10px' }} aria-label="search" title="Importer un document" onClick={() => handleImportDoc(r)}>
                                        <FileUploadOutlinedIcon />
                                    </IconButton>
                                </Col>
                            </Row>
                            </div>)
                        }
                        }) : (<div>
                        <Skeleton variant="rounded" height={60} className='mt-3'/>
                        <Skeleton variant="rounded" height={60} className='mt-3'/>
                        <Skeleton variant="rounded" height={60} className='mt-3'/>
                        <Skeleton variant="rounded" height={60} className='mt-3'/>
                        </div>)
                    } 

                    <div className='mt-3'>
                        {numPage > 0 && <IconButton aria-label="appel" className="mr-2" onClick={prevPage}>
                            <KeyboardArrowLeftIcon />
                        </IconButton>
                        } 
                        {numPage+1} / {nbPages}
                        {listeCasReferes && (numPage+1)*nbPerPage < listeCasReferes.length && <IconButton aria-label="message" className="mr-2" onClick={nextPage}>
                            <KeyboardArrowRightIcon />
                        </IconButton>
                        }                                      
                    </div>
                    </Col>
                </Row>
            </Col>
            </Row>
        </Container>


        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: { timeout: 500 },
            }}
            >
            <Fade in={open}>
                <Box className="modal-sm">
                    <p className={['titre','bold','mb-0'].join(" ")}>Nouveau document</p>
                    {editedRdv && editedRdv.animal && <p className={['smallText','mb-2'].join(" ")}>Pour {editedRdv.animal}</p>}

                    <IconButton aria-label="modifier" className="modalExit" onClick={handleClose}>
                        <CloseIcon/>
                    </IconButton>

                    <TextField 
                        id="nom" 
                        label="Nom" 
                        variant="standard" 
                        value={editedDoc.nom ? editedDoc.nom : ''}
                        onChange={updateDoc}
                        className="w-100"
                        error={!!nomError}
                        helperText={nomError}
                    />
                    <FormControl variant="standard" sx={{ width: '100%' }}>
                        <Select
                            id="type_document"
                            value={editedDoc.type_document ? listeTypesDocument.filter((c) => c.value == editedDoc.type_document) : ''}
                            className='mt-3'
                            onChange={(e) => updateTypeDocument(e.value)}
                            placeholder="Type de document"
                            options={listeTypesDocument}
                            error={!!typeDocError}
                        >                      
                        </Select>
                    </FormControl>
                    {typeDocError && <small className='textRed'>{typeDocError}</small>}     
                    {(editedDoc.type_document == 'compte_rendu' || editedDoc.type_document == 'resultats') &&
                        <FormControl variant="standard" sx={{ width: '100%' }}>
                            <Select
                                id="sous_type_document"
                                value={editedDoc.sous_type_document ? listeSousTypesDocument.filter((c) => c.value == editedDoc.sous_type_document) : ''}
                                className='mt-3'
                                onChange={updateSousTypeDocument}
                                placeholder="Sous-type de document"
                                options={listeSousTypesDocument}
                            >                      
                            </Select>
                        </FormControl>
                    }  

                    <div>
                        <Button variant="outlined" className={['w-100','mt-3','py-4','px-2'].join(" ")} onClick={() => document.getElementById('nouveau_document').click()} startIcon={<FileUploadOutlinedIcon />}>
                            {editedDoc.nom_fichier ? editedDoc.nom_fichier : 'Parcourir'}
                            <input id="nouveau_document" className='d-none' type="file" onChange={handleFileChange} />
                        </Button>
                        {fileError && <small className='textRed'>{fileError}</small>}                    
                    </div>
                    
                    <Button variant="contained" className={['buttonSecondary','mt-5'].join(" ")} type="submit" startIcon={(loading ? <CircularProgress/> : <CheckIcon />)} onClick={importDoc}>Importer</Button> 
                </Box>
            </Fade> 
        </Modal>
        </div>
    );
}

export default Factures;