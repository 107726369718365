import { React, useEffect, useState, useReducer } from 'react';
import Paper from '@mui/material/Paper';
import UserWidget from '../Dashboard/UserWidget';
import SideMenu from '../Dashboard/SideMenu';
import SendIcon from '@mui/icons-material/Send';
import SearchIcon from '@mui/icons-material/Search';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PersonIcon from '@mui/icons-material/Person';
import PetsIcon from '@mui/icons-material/Pets';
import PortraitOutlinedIcon from '@mui/icons-material/PortraitOutlined';
import LocalHospitalOutlinedIcon from '@mui/icons-material/LocalHospitalOutlined';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Skeleton, TextField, Button} from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../App/App.css';

import {useCredentials} from '../../Services/authServices'; 
import {getCabClients, getCabVeterinaires, getCabPartenaires} from '../../Services/cabinetServices'; 
import {getMessages, setMessage} from '../../Services/MessagesServices';
import {getRdvs, getRdvsCommuns} from '../../Services/RDVServices';

var activeDestinataire = {id: null};
var activeConsultation = {id: null};
var activeListMessage = [];
var lastRead = null;

function autoscroll() {
    setTimeout(function() {
        const div = document.getElementById('messagesListe');
        div.scrollTop = div.scrollHeight;
    }, 150);
}


function Messagerie() {

    const { user, setUser } = useCredentials();
    const queryParams = new URLSearchParams(window.location.search);
    const [destinataires, setDestinataires] = useState([]);    
    const [consultations, setConsultations] = useState([]);
    const [listeMessages, setListeMessages] = useState([]);
    const [nouveauMessage, setNouveauMessage] = useState('');
    const [filtreDestinataires, setFiltreDestinataires] = useState();
    const [filtreConsultations, setFiltreConsultations] = useState();
    
    const [destParticuliers, setDestParticuliers] = useState(true);    
    const [destVeterinaires, setDestVeterinaires] = useState(true);

    const [, forceUpdate] = useReducer(o => !o);
  
    useEffect(() => {      

        const selected = queryParams.get('destinataire');
        const selectedRdv = queryParams.get('consultation');
        if(selected != null) activeDestinataire.id = selected;
        if(selectedRdv != null) activeConsultation.id = selectedRdv;

        if(user.is_veto) {
            getCabClients(user).then(res => {
                if(res != undefined && res.data != undefined && res.data.statut == "ok") {

                    let liste_destinataires = [...res.data.clients];

                    if(user.licence.avec_cas_referes) {
                        getCabPartenaires(user).then(res_p => {
                            if(res_p != undefined && res_p.data != undefined && res_p.data.statut == "ok") {
                                liste_destinataires = [...liste_destinataires, ...res_p.data.donnees];
                                setDestinataires(liste_destinataires);
                            }
                        });
                    }
                    else {
                        setDestinataires(liste_destinataires);
                    }

                    if(liste_destinataires.length > 0) { 
                        if(activeDestinataire.id == null) { activeDestinataire = liste_destinataires[0]; }
                        changeActiveDestinataire(activeDestinataire, selectedRdv);                    
                    }
                }
            });
        }
        else {
            getCabVeterinaires(user).then(res => {
                if(res != undefined && res.data != undefined && res.data.statut == "ok") {
                    setDestinataires(res.data.donnees);
                    if(res.data.donnees.length > 0) { 
                        if(activeDestinataire.id == null) { activeDestinataire = res.data.donnees[0]; }
                        changeActiveDestinataire(activeDestinataire);
                    }
                }
            });
        }

        const interval = setInterval(() => {
            getMessages(user, user.id, activeDestinataire.id, activeConsultation.id, lastRead).then(res => {
                if(res != undefined && res.data != undefined && res.data.statut == "ok") {        
                    if(res.data.donnees.length > 0) {
                        res.data.donnees.map((m, i) => {                    
                            activeListMessage.push({
                                id: m.id,
                                ownedByCurrentUser: (m.id_emetteur == user.id),
                                date_formatted: m.date_formatted,
                                emetteur: m.emetteur,
                                contenu: m.contenu
                            });
                            lastRead = m.id;
                        });
                        setListeMessages(activeListMessage);
                        autoscroll();     
                        forceUpdate();
                    }
                }
            });
        }, 2000);
        return () => clearInterval(interval);

    }, [user]);

    const toggleDestParticuliers = () => {
        setDestParticuliers(!destParticuliers);
    }
    const toggleDestVeterinaires = () => {
        setDestVeterinaires(!destVeterinaires);
    }

    const changeActiveDestinataire = (clientVals, selectedRdv = null) => {          
        getRdvsCommuns(user, clientVals.id, true).then(res_r => {
            if(res_r != undefined && res_r.data != undefined && res_r.data.statut == "ok") {

                setConsultations(res_r.data.donnees);

                if(res_r.data.donnees.length > 0) {
                    let consultation = {...activeConsultation};
                    if(selectedRdv != null) {
                        res_r.data.donnees.map((r, i) => { if(r.id == selectedRdv) consultation = r; });
                    } 
                    else { consultation = res_r.data.donnees[0]; }                    
                    changeActiveConsultation(consultation);
                }
            }
        }); 
        activeDestinataire = clientVals;
    }

    const changeActiveConsultation = (consultVals) => {
        getMessages(user, activeDestinataire.id, user.id, consultVals.id).then(res_m => {
            if(res_m != undefined && res_m.data != undefined && res_m.data.statut == "ok") {

                res_m.data.donnees.map((m, i) => {
                    res_m.data.donnees[i].ownedByCurrentUser = (m.id_emetteur == user.id);
                    lastRead = m.id;
                });
                setListeMessages(res_m.data.donnees);
                activeListMessage = res_m.data.donnees;
                autoscroll();
            }
        });  
        activeConsultation = consultVals;     
    }

    const handleChangeNouveauMessage = (e) => {
        setNouveauMessage(e.target.value);
    }

    const sendMessage = () => {
        setMessage(user, user.id, activeDestinataire.id, activeConsultation.id, nouveauMessage).then(res => {
            if(res != undefined && res.data != undefined && res.data.statut == "ok") {
                var nouvelleListe = [...listeMessages];
                nouvelleListe.push({
                    id: res.data.id_message,
                    ownedByCurrentUser: true,
                    date_formatted: res.data.date_formatted,
                    emetteur: user.prenom+' '+user.nom,
                    contenu: nouveauMessage
                });
                lastRead = res.data.id_message;
                setListeMessages(nouvelleListe);
                activeListMessage = nouvelleListe;
                autoscroll();   
                setNouveauMessage('');             
            }
        });        
    }


    const updateFiltreDestinataires = (e) => {
        setFiltreDestinataires(e.target.value);
    }
    const updateFiltreConsultations = (e) => {
        setFiltreConsultations(e.target.value);
    }


    return(
        <div className="fullpage">
        <Container className='full_container'>
            
            <UserWidget /> 
            {user.is_veto && <SideMenu user={user} setUser={setUser}/>}

            <Row className={user.is_veto ? [(user.menuClosed ? 'withSidemenu-closed' : 'withSidemenu'),'messagerie_page'].join(" ") : ['w-100',' ps-4','messagerie_page'].join(" ")}>
                <Col xs={12} className={['ps-5','pt-5','hmax-100'].join(" ")}>

                    <Row className="align-items-center">
                        <Col md={3}>
                            <h2>Messagerie</h2>
                            {!user.is_veto && <div>
                                <IconButton className="ms-0" aria-label="retour" onClick={() => window.open('/client/'+user.id, '_self')}>
                                    <ArrowBackIcon/>
                                </IconButton>
                                <span className='ms-2'>Retour</span>
                            </div>}
                        </Col>
                        {user.is_veto && <Col md={3}>
                            <FormControlLabel
                                value="start"
                                control={<Switch checked={destParticuliers} size="small" onChange={toggleDestParticuliers}/>}
                                label="Particuliers"
                                labelPlacement="start"
                                className='ms-0'
                            />
                            <FormControlLabel
                                value="start"
                                control={<Switch checked={destVeterinaires} size="small" onChange={toggleDestVeterinaires}/>}
                                label="Médecins"
                                labelPlacement="start"
                            />
                        </Col>}
                        {user.is_veto && <Col md={6} className="d-flex">
                            {activeConsultation.id != null && <Card className={['mx-2','p-0'].join(" ")} title="fiche client">
                                <Button variant="text" className={['listButton','p-3'].join(" ")} onClick={() => window.open('/client/'+activeConsultation.id_client)}>
                                    <PersonIcon className='me-3'/>
                                    <span>Voir la fiche de {activeConsultation.client}</span>
                                </Button>
                            </Card>}
                            {activeConsultation.id != null && <Card className={['mx-2','p-0'].join(" ")} title="fiche animal">
                                <Button variant="text" className={['listButton','p-3'].join(" ")} onClick={() => window.open('/animal/'+activeConsultation.id_animal)}>
                                    <PetsIcon className='me-3'/>
                                    <span>Voir la fiche de {activeConsultation.animal}</span>
                                </Button>
                            </Card>}
                        </Col>}
                    </Row>                
                    <Row className={['mt-4','h-75'].join(" ")}>
                        <Col className={['messagesCol','p-0'].join(" ")} md={2}>
                            <div className={['searchBar','mb-2','mx-3'].join(" ")}>
                                <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                                    <SearchIcon />
                                </IconButton>
                                <InputBase placeholder="Chercher une personne..." onChange={updateFiltreDestinataires}/>
                            </div>
                            <div id='destinatairesListe' className='custom-scrollbar'>
                                {destinataires.length > 0 && destinataires.map((p, i) => { 
                                    var match = (filtreDestinataires ? 
                                                    (p.nom.toLowerCase().indexOf(filtreDestinataires.toLowerCase()) > -1 
                                                    || p.prenom.toLowerCase().indexOf(filtreDestinataires.toLowerCase()) > -1)
                                                : false);

                                    if(!destParticuliers && !p.is_veto) return;
                                    if(!destVeterinaires && p.is_veto) return;

                                    if(!filtreDestinataires || match) {
                                        const button_class = (p.id == activeDestinataire.id ? 'textSecondary' : 'grey');  
                                        return <Button variant="text" className='listButton' data-id={p.id} onClick={() => {changeActiveDestinataire(p)}}>
                                            <p className={['bigText','uppercase','d-flex','align-items-center',button_class].join(" ")}>
                                                {p.nom}
                                                {p.is_veto ? <LocalHospitalOutlinedIcon className='ms-2'/> : <PortraitOutlinedIcon className='ms-2'/>}
                                            </p>
                                            <p className={['smallText',button_class].join(" ")}>{p.prenom}</p>
                                        </Button>
                                    }
                                })}
                            </div> 
                        </Col>
                        <Col className={['messagesCol','borderLeft','p-0'].join(" ")} md={2}>
                            <div className={['searchBar','mb-2','mx-3'].join(" ")}>
                                <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                                    <SearchIcon />
                                </IconButton>
                                <InputBase placeholder="Chercher un animal..." onChange={updateFiltreConsultations}/>
                            </div>
                            <div id='destinatairesListe' className='custom-scrollbar'>
                                {consultations.length > 0 && consultations.map((c, i) => { 
                                    var match = (filtreConsultations ? (c.animal.toLowerCase().indexOf(filtreConsultations.toLowerCase()) > -1 
                                                    || c.motif_consultation.toLowerCase().indexOf(filtreConsultations.toLowerCase()) > -1)
                                                : false);
                                    if(!filtreConsultations || match) {
                                        const button_class = (c.id == activeConsultation.id ? 'textSecondary' : 'grey');  
                                        const libelle_date = c.date_formatted != null ? 'le '+c.date_formatted : 'Sans date'
                                        return <Button variant="text" className='listButton' data-id={c.id} onClick={() => {changeActiveConsultation(c)}}>
                                            <p className={['fw-bold',button_class].join(" ")}>{libelle_date}</p>
                                            <p className={['fw-bold','smallText',button_class].join(" ")}>{c.animal}</p>
                                            <p className={['smallText',button_class].join(" ")}>{(c.motif_consultation.length > 40 ? c.motif_consultation.substr(0, 40)+'...' : c.motif_consultation) }</p>
                                        </Button>
                                    }
                                })}
                            </div> 
                        </Col>
                        <Col md={8} className={['messagesCol','borderLeft','ps-5','pe-3'].join(" ")}> 
                            <div id='messagesListe' className='custom-scrollbar'>
                                {listeMessages.length > 0 ? listeMessages.map((message, i) => (<div key={i} className={message.ownedByCurrentUser ? "sent" : "received"}>
                                    <div id={"m_"+message.id} className="d-block">
                                        <p className={['smallText', 'ps-3','mb-1','mt-2'].join(" ")}>le {message.date_formatted}</p>
                                        <Card className='message-item'>
                                            <CardContent>
                                                <strong className='bold'>{message.emetteur}</strong>
                                                <p className='smallText'>{message.contenu}</p>  
                                            </CardContent>
                                        </Card>
                                    </div>
                                </div>)) : (<div className="py-3">
                                    <p className='smallText'>Aucun message pour le moment...</p>
                                </div>)}     
                            </div>
                            
                            <div className={['d-block','mt-4'].join(" ")}>
                                <Row className="align-items-end">
                                    <Col md={5}>
                                        <TextField 
                                            id="nouveau_message" 
                                            label="Nouveau message" 
                                            variant="standard" 
                                            value={nouveauMessage}
                                            onChange={handleChangeNouveauMessage}
                                            className="mt-2 mb-1 w-100"
                                        />
                                    </Col>
                                    <Col>
                                    <IconButton aria-label="settings">
                                        <SendIcon onClick={sendMessage}/>
                                    </IconButton>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
        </div>
    );
}

export default Messagerie;