import {React} from 'react';
import axios from "axios";


export async function setMandat(datas, type='cabinet') {

  return axios.post(process.env.REACT_APP_API_URL+'payment/set_mandat', {
    token: process.env.REACT_APP_TOKEN,
    datas: JSON.stringify(datas),
    type: type
  }, {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
  .catch(function (error) {
    console.log(error.toJSON());
  });
}
