import { React, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Prompt } from 'react-router';
import { styled } from '@mui/material/styles';
import UserWidget from '../Dashboard/UserWidget';
import SideMenu from '../Dashboard/SideMenu';
import Documents from '../Documents/Documents';
import Consultations from '../Consultations/Consultations';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import CheckIcon from '@mui/icons-material/Check';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import DriveFileMoveOutlinedIcon from '@mui/icons-material/DriveFileMoveOutlined';
import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import CircularProgress from '@mui/material/CircularProgress';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Skeleton, TextField, Button, SwipeableDrawer} from '@mui/material';
import Badge from '@mui/material/Badge';
import Card from '@mui/material/Card';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import IconButton from '@mui/material/IconButton';
import dayjs from 'dayjs';
import swal from 'sweetalert';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Select from 'react-select'
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../App/App.css';

import {useCredentials} from '../../Services/authServices';
import {getEspeces, getRaces, getAnimal, setAnimal, setCommentaires, deleteCommentaire} from '../../Services/animalServices';
import {getRdvs, setRdv, demarreRdv} from '../../Services/RDVServices';
import {uploadPhoto} from '../../Services/FileServices';
import {getTypesPrestations} from '../../Services/prestationsServices';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

var max_id_commentaires = 1;

function FicheClient() {

    const { id } = useParams();  
    const queryParams = new URLSearchParams(window.location.search); 
    const { user, setUser } = useCredentials();
    const [editedAnimal, setEditedAnimal] = useState({});
    const [age, setAge] = useState(0);
    const [needSave, setNeedSave] = useState({});

    const [activeTab, setActiveTab] = useState('1');

    const [listeEspeces, setListeEspeces] = useState([]);
    const [listeRaces, setListeRaces] = useState([]);
    const [nomErrorMsg, setNomErrorMsg] = useState();
    const [puceErrorMsg, setPuceErrorMsg] = useState();
    const [photoError, setPhotoError] = useState();   
    
    const [loadingCall, setLoadingCall] = useState(false);
    const [editedRdv, setEditedRdv] = useState({});
    const [openRdv, setOpenRdv] = useState(false);
    const handleCloseRdv = () => setOpenRdv(false);   
    const [motifError, setMotifError] = useState();
    const [symptomesError, setSymptomesError] = useState();

    const [listeRdv, setListeRdv] = useState([]);
    const [listeDemandesRdv, setListeDemandesRdv] = useState([]);
    const [openDrawer, setOpenDrawer] = useState(false);

    const [typesPrestations, setTypesPrestations] = useState([]);
    
    useEffect(() => {

        const onglet = queryParams.get('onglet');
        if(onglet != null && parseInt(onglet) >= 1) setActiveTab(onglet);

        if(!user) { window.open('/login', '_self'); }
        else {

            getEspeces(user).then(res => {
                if(res != undefined && res.data != undefined && res.data.statut == "ok") {                    
                    let optionsEspeces = [];
                    res.data.donnees.map((c, i) => {
                        optionsEspeces.push({value: c.id, label: c.nom});
                    });
                    setListeEspeces(optionsEspeces);
                }
            });

            getTypesPrestations(user.cabinet.id).then(res_t => {
                if(res_t != undefined && res_t.data != undefined && res_t.data.statut == "ok") {
                setTypesPrestations(res_t.data.donnees);
                }
            });

            getAnimal(user, id).then(res => {
                if(res != undefined && res.data != undefined && res.data.statut == "ok") {

                    if(res.data.donnees.date_naissance) {
                        let ajd = new Date;
                        let date_naissance = new Date(res.data.donnees.date_naissance);
                        setAge( Math.floor((ajd - date_naissance) / (24*3600*1000*365)) );
                    }

                    getRaces(user, res.data.donnees.id_espece).then(res => {
                        if(res != undefined && res.data != undefined && res.data.statut == "ok") {
                            let optionsRaces = [];
                            res.data.donnees.map((c, i) => {
                                optionsRaces.push({value: c.id, label: c.nom});
                            });
                            setListeRaces(optionsRaces);
                        }
                    });

                    res.data.donnees.decede = (res.data.donnees.date_deces != null ? 'oui' : 'non');
                    res.data.donnees.sterilise = (res.data.donnees.date_sterilisation != null ? 'oui' : 'non');

                    res.data.donnees.commentaires.map((c, i) => {
                        if(c.id > max_id_commentaires) max_id_commentaires = (c.id+1);
                    });

                    setEditedAnimal(res.data.donnees);
                    handleNeedSave(false);

                    getRdvs(user, res.data.donnees.id_proprietaire).then(res => {
                        if(res != undefined && res.data != undefined && res.data.statut == "ok") { 
                            
                            let liste_rdv = [];
                            let liste_demandes = [];
                            res.data.donnees.rdv.map((r, i) => {
                                if(r.id_animal == id) { liste_rdv.push(r); }
                            });
                            res.data.donnees.demandes_rdv.map((r, i) => {
                                if(r.id_animal == id) { liste_demandes.push(r); }
                            });
                            setListeRdv(liste_rdv);                
                            setListeDemandesRdv(liste_demandes);
                        }
                    }); 
                }
            });

             
        } 

    }, [user]);    


    const handleNeedSave = (val) => {
        setNeedSave(val);  
        if(val) {
            window.onbeforeunload = () => true
        } else {
            window.onbeforeunload = undefined
        }
    }

    const changeActiveTab = (event, newValue) => {
        setActiveTab(newValue);
    };


    const updateAnimal = (e) => {
        let animalVals = {...editedAnimal};
        animalVals[e.target.id] = e.target.value;
        setEditedAnimal(animalVals);
        handleNeedSave(true);
    }
    const updateAnimalDateNaissance = (e) => {
        let animalVals = {...editedAnimal};
        animalVals['date_naissance'] = e['$y']+'-'+(e['$M']+1)+'-'+e['$D'];
        let ajd = new Date;
        let date_naissance = new Date(animalVals['date_naissance']);
        setAge( Math.floor((ajd - date_naissance) / (24*3600*1000*365)) );
        setEditedAnimal(animalVals);
        handleNeedSave(true);
    }    
    const updateAnimalDeces= (e) => {
        let animalVals = {...editedAnimal};
        animalVals['decede'] = e.target.value;
        if(e.target.value == "non") { animalVals['date_deces'] = null; }
        setEditedAnimal(animalVals);
        handleNeedSave(true);
    }
    const updateAnimalDateDeces = (e) => {
        let animalVals = {...editedAnimal};
        animalVals['date_deces'] = e['$y']+'-'+(e['$M']+1)+'-'+e['$D'];
        setEditedAnimal(animalVals);
        handleNeedSave(true);
    }        
    const updateAnimalSterilisation = (e) => {
        let animalVals = {...editedAnimal};
        animalVals['sterilise'] = e.target.value;
        if(e.target.value == "non") { animalVals['date_sterilisation'] = null; }
        setEditedAnimal(animalVals);
        handleNeedSave(true);
    }
    const updateAnimalDateSterilisation = (e) => {
        let animalVals = {...editedAnimal};
        animalVals['date_sterilisation'] = e['$y']+'-'+(e['$M']+1)+'-'+e['$D'];
        setEditedAnimal(animalVals);
        handleNeedSave(true);
    }
    const updateAnimalEspece = (e) => {
        let animalVals = {...editedAnimal};
        animalVals['id_espece'] = e.value;
        
        getRaces(user, e.value).then(res => {
            if(res != undefined && res.data != undefined && res.data.statut == "ok") {
                let optionsRaces = [];
                res.data.donnees.map((c, i) => {
                    optionsRaces.push({value: c.id, label: c.nom});
                });
                setListeRaces(optionsRaces);
                animalVals['id_race'] = res.data.donnees[0].id;
            }
        });

        setEditedAnimal(animalVals);
        handleNeedSave(true);
    }
    const updateAnimalRace = (e) => {
        let animalVals = {...editedAnimal};
        animalVals['id_race'] = e.value;
        setEditedAnimal(animalVals);
        handleNeedSave(true);
    }
    const updateAnimalSexe = (e) => {
        let animalVals = {...editedAnimal};
        animalVals['sexe'] = e.target.value;
        setEditedAnimal(animalVals);
        handleNeedSave(true);
    }

    const handlePhotoChange = (e) => {

        if (e.target.files) {

            const photo = e.target.files[0];
            if(photo.size > 5000000) { setPhotoError('L\'image est trop lourde'); }
            else if(photo.type != 'image/jpeg' && photo.type != 'image/png') { setPhotoError('Le fichier n\'est pas une image'); }
            else {
                setPhotoError(null);
                uploadPhoto(photo).then((res) => {
                    if(res != undefined && res.data != undefined && res.data.statut == "ok") {
                        let animalVals = {...editedAnimal};
                        animalVals['photo'] = res.data.filename;
                        setEditedAnimal(animalVals);
                        handleNeedSave(true);
                    }
                });                
            }            
        }
    }

    const saveAnimal = () => {

        let nb_errors = 0;

        if(!editedAnimal.numero_puce || editedAnimal.numero_puce.length == 0) { setPuceErrorMsg("Ce champ est obligatoire"); nb_errors++; }
        if(!editedAnimal.nom || editedAnimal.nom.length == 0) { setNomErrorMsg("Ce champ est obligatoire"); nb_errors++; }


        if(nb_errors == 0) {
            setAnimal(user, editedAnimal, editedAnimal.id_proprietaire).then(res => {
                if(res != undefined && res.data != undefined && res.data.statut == "ok") {
                    setNomErrorMsg(null);   
                    setPuceErrorMsg(null);    
                    handleNeedSave(false); 
                }
            });

            setCommentaires(user, editedAnimal.commentaires, id);
        }      
    }


    const updateCommentaire = (c_id, contenu) => {
        
        let animalVals = {...editedAnimal};
        animalVals.commentaires.map((c, i) => {
            if(c.id == c_id) { c.contenu = contenu; }
        });
        setEditedAnimal(animalVals);
        handleNeedSave(true);     
    }

    const addCommentaire = () => {

        let ajd = new Date;
        let animalVals = {...editedAnimal};
        animalVals.commentaires.push({
            'id': {...max_id_commentaires},
            'contenu' : '',
            'date' : (ajd.getDate() < 10 ? '0' : '')+ajd.getDate()+'/'+((ajd.getMonth()+1) < 10 ? '0' : '')+(ajd.getMonth()+1)+'/'+ajd.getFullYear()
        });
        setEditedAnimal(animalVals);
        handleNeedSave(true);
    }

    const supprCommentaire = (id_commentaire) => {
        swal({
            title: "Êtes-vous sûr(e) ?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          })
          .then((willDelete) => {
            if(willDelete) {
                deleteCommentaire(user, id_commentaire).then(function() {
                    let animalVals = {...editedAnimal};
                    animalVals.commentaires.map((c, i) => {
                        if(c.id == id_commentaire) { animalVals.commentaires.splice(i,1); }
                    });
                    setEditedAnimal(animalVals);
                    handleNeedSave(true);
                })
            }
        });        
    }


    const changeEditedRdv = (type='demande', id) => {
        if(id != null) {
            (type == 'demande' ? listeDemandesRdv : listeRdv).map((r, i) => {            
                if(r.id == id) {

                    let rdvVals = {...r}; 
                    rdvVals.id_rdv = id; 

                    let ajd = new Date();
                    let date_rdv = new Date(rdvVals['date_rdv']);
                    if(date_rdv.getFullYear() == ajd.getFullYear() && date_rdv.getMonth() == ajd.getMonth() && date_rdv.getDate() == ajd.getDate()) {
                        rdvVals.ready = true;
                    }
                    else {
                        rdvVals.ready = false;
                    }
                                  
                    setEditedRdv(rdvVals); 
                    setOpenDrawer(false);
                    setOpenRdv(true);
                }
            }); 
        }
        else {
            if(editedAnimal.id_gocardless) {
                let rdvVals = {}; 
                rdvVals.id_rdv = null;   
                rdvVals.ready = false;             
                setEditedRdv(rdvVals); 
                setOpenDrawer(false);
                setOpenRdv(true);
            }
            else {
                swal({
                    title: "Remplissez vos données bancaires",
                    text: "Avant de pouvoir faire une demande de consultation, vous devez d'abord enregistrer un mandat de prélèvement bancaire dans l'onglet \"Paiement\".",
                    icon: "warning",
                    dangerMode: true,
                });
            }
        }
    }

    const updateRdv = (e) => {
        let rdvVals = {...editedRdv};
        rdvVals[e.target.id] = e.target.value;
        setEditedRdv(rdvVals);
    }
    const updateDateRdv = (e) => {
        let rdvVals = {...editedRdv};
        rdvVals['date_rdv'] = e['$y']+'-'+((e['$M']+1) < 10 ? '0' : '')+(e['$M']+1)+'-'+(e['$D'] < 10 ? '0' : '')+e['$D']+' '+(e['$H'] < 10 ? '0' : '')+e['$H']+':'+(e['$m'] < 10 ? '0' : '')+e['$m'];
        setEditedRdv(rdvVals);
    }
    const updateTypePrestationRdv = (e) => {
      let rdvVals = {...editedRdv};
      rdvVals['type_prestation'] = e.value;
      setEditedRdv(rdvVals);
    }

    const isDateValid = (date = "") => {        
        let parsedDate = new Date(date);    
        return parsedDate.toString() !== "Invalid Date"; 
    }

    const saveRdv = () => {

        setMotifError(null);
        setSymptomesError(null);

        let rdvVals = {...editedRdv};
        rdvVals['id_veterinaire'] = (user.is_veto ? user.id : null);
        rdvVals['id_animal'] = id;

        if(!(rdvVals.motif_consultation && rdvVals.motif_consultation.length > 0)) { setMotifError('Ce champs est obligatoire'); }
        if(!(rdvVals.symptomes && rdvVals.symptomes.length > 0)) { setSymptomesError('Ce champs est obligatoire'); }

        let id_rdv = (rdvVals.id_rdv != undefined ? rdvVals.id_rdv : null);
        if(rdvVals.date_rdv != undefined && !isDateValid(rdvVals.date_rdv)) { rdvVals.date_rdv = null; }

        setRdv(user, id_rdv, rdvVals).then(res => {
            if(res != undefined && res.data != undefined && res.data.statut == "ok") {
                
                setOpenRdv(false);
                setEditedRdv({});

                rdvVals.animal = res.data.animal;
                let liste = [...listeRdv];
                let liste_demandes = [...listeDemandesRdv];
                    
                if(id_rdv != null) {

                    if(rdvVals.date_rdv && rdvVals.date_rdv.length > 0 && isDateValid(rdvVals.date_rdv)) {
                        let found = false;
                        liste.map((r, i) => {   
                            if(r.id == id_rdv) {
                                liste[i] = rdvVals;
                                found = true;
                            }
                        });
                        if(!found) {
                            liste_demandes.map((r, i) => {   
                                if(r.id == id_rdv) { liste_demandes.splice(i, 1); }
                            });
                            liste.push(rdvVals);
                        }  
                    }
                    else {
                        let found = false;
                        liste_demandes.map((r, i) => {   
                            if(r.id == id_rdv) {
                                liste_demandes[i] = rdvVals;
                                found = true;
                            }
                        });
                        if(!found) {
                            liste.map((r, i) => {   
                                if(r.id == id_rdv) { liste.splice(i, 1); }
                            });
                            liste_demandes.push(rdvVals);
                        }
                    }                     
                }
                else {
                    if(rdvVals.date_rdv && rdvVals.date_rdv.length > 0 && isDateValid(rdvVals.date_rdv)) {
                        liste.push(rdvVals);
                    }
                    else {
                        liste_demandes.push(rdvVals);
                    }
                }   

                setListeRdv(liste);                      
                setListeDemandesRdv(liste_demandes);

                swal({
                    title: "Ok !",
                    text: "La consultation est enregistrée.",
                    icon: "success",
                    buttons: true,
                });
            }
        });
    }

    
    const appel = (id_rdv) => {
        if(editedAnimal.id_gocardless) {
            setLoadingCall(true);
            demarreRdv(user, id_rdv).then(res => {
                if(res != undefined && res.data != undefined && res.data.statut == "ok") {
                    window.open(res.data.lien_rdv);
                    setLoadingCall(false);
                }
            });
        }
        else {
            swal({
                title: "Remplissez vos données bancaires",
                text: "Avant de pouvoir faire une demande de consultation, vous devez d'abord enregistrer un mandat de prélèvement bancaire dans l'onglet \"Paiement\" de votre fiche client.",
                icon: "warning",
                dangerMode: true,
            });
        }        
    }


    const listeTypesPrestations = [];
    typesPrestations.map((t, i) => {
        listeTypesPrestations.push({ value: t.id, label: t.nom });
    });


    let bg_class = 'animal_page_chien';
    switch(editedAnimal.espece) {
        case 'Chat' : bg_class = 'animal_page_chat'; break;
        case 'Cheval' : bg_class = 'animal_page_cheval'; break;
    }

    return(
        <div className="w-100">
        <Container className={['full_container','h-min-100',bg_class].join(" ")}>
            
            <UserWidget /> 
            {user.is_veto && <SideMenu user={user} setUser={setUser}/>}
            
            <Row className={user.is_veto ? [(user.menuClosed ? 'withSidemenu-closed' : 'withSidemenu'), 'h-auto'].join(" ") : ['w-100', 'h-auto', ' ps-4'].join(" ")}>

                <Col xs={10} className={user.is_veto ? ['enteteFiche','pt-5'].join(" ") : ['px-5','pt-5'].join(" ")}>
                    {editedAnimal != undefined ? (
                    <Row className="align-items-center">
                        {user.is_veto && <Col className={['d-flex','justify-content-end', 'pe-1'].join(" ")} md={1}>
                            <IconButton className="arrowButtonLeft" aria-label="retour" onClick={() => window.open('/client/'+editedAnimal.id_proprietaire, '_self')}>
                                <ArrowBackIcon/>
                            </IconButton>
                        </Col>}
                        <Col md={4}>                            
                            <h2 className="d-inline">{editedAnimal.nom}</h2><h3 className="d-inline"> ({age} ans)</h3>
                            {!user.is_veto && <div className={['d-block','mt-2'].join(" ")}>
                                <IconButton className="ms-0" aria-label="retour" onClick={() => window.open('/client/'+editedAnimal.id_proprietaire, '_self')}>
                                    <ArrowBackIcon/>
                                </IconButton>
                                <span className='ms-2'>Retour</span>
                            </div>}
                        </Col>
                        <Col md={7} className="d-flex">
                            {needSave == true ? (<Card className={['mx-2','p-2','waiting'].join(" ")} title="Sauvegarder">
                                <Button variant="text" className={['listButton','p-2'].join(" ")} onClick={saveAnimal}>
                                    <SaveIcon className='me-3'/>
                                    <span>Sauvegarder</span>
                                </Button>
                            </Card>) : (<Card className={['mx-2','p-2','align-items-center','success'].join(" ")}>
                                <Button variant="text" className={['listButton','success','p-2'].join(" ")}>
                                    <CheckIcon className='me-3'/>
                                    <span>A jour</span>
                                </Button>
                            </Card>)}
                            <Badge badgeContent={listeDemandesRdv.length} color="primary"><Card className={['mx-2','p-2'].join(" ")}>
                                <IconButton aria-label="retour" onClick={() => {setOpenDrawer(true)}} title="Consultations">
                                    <EventOutlinedIcon/>
                                </IconButton>
                            </Card></Badge>
                            {user.licence && user.licence.avec_cas_referes == true && 
                                <Card className={['mx-2','p-2'].join(" ")}>
                                    <IconButton aria-label="retour" title="Référer un cas" onClick={() => window.open('/nouveau_cas_refere?client='+editedAnimal.id_proprietaire+'&animal='+id,'_self')}>
                                        <DriveFileMoveOutlinedIcon/>
                                    </IconButton>
                                </Card>
                            }                         

                            <SwipeableDrawer
                                    anchor='right'
                                    open={openDrawer}
                                    onClose={() => {setOpenDrawer(false)}}
                                >
                                <div className="p-4">
                                    <p className={['bigText','bold'].join(" ")}>Prochaines<br></br>consultations</p>
                                    <hr width="75%" className="black_line"></hr>
                                </div>
                                {listeRdv.length > 0 ? listeRdv
                                    .map((r, i) => {
                                    return (<div key={r.id}> 
                                        <Button variant="text" className="listButton" data-id={r.id} onClick={() => {changeEditedRdv('rdv', r.id)}}>
                                            <p>{r.animal}</p>
                                            <p className='smallText'>{r.motif_consultation}</p>
                                            <p className='smallText'>le {r.date_formatted}</p>
                                        </Button>
                                    </div>)
                                    }) : (<div className='px-4'><p className='smallText'>Aucune consultation pour le moment</p></div>)
                                }

                                <div className="p-4">
                                    <p className={['bigText','bold'].join(" ")}>Demandes de<br></br>consultation</p>
                                    <hr width="75%" className="black_line"></hr>
                                </div>  
                                <div>
                                {listeDemandesRdv.length > 0 ? listeDemandesRdv
                                    .map((r, i) => {
                                    return (<div key={r.id}> 
                                        <Button variant="text" className="listButton" data-id={r.id} onClick={() => {changeEditedRdv('demande', r.id)}}>
                                            <p>{r.animal}</p>
                                            <p className='smallText'>{r.motif_consultation}</p>
                                        </Button>
                                    </div>)
                                    }) : (<div className='px-4'><p className={['smallText','py-2'].join(" ")}>Aucune demande pour le moment</p></div>)
                                }
                                    <Button variant="text" className="listButton" onClick={() => {changeEditedRdv('demande', null)}}>
                                        <AddBoxOutlinedIcon className='me-3' />
                                        <span>Nouvelle demande</span>
                                    </Button>
                                </div>                              
                            </SwipeableDrawer>
                        </Col>
                    </Row>) : (<div className="mt-2">
                        <Skeleton variant="text" sx={{ fontSize: '1.1rem' }} width={200} />
                        <Skeleton variant="text" sx={{ fontSize: '1.5rem' }} width={100} />
                    </div>)
                    }
                </Col>

                <Col md={12} className="px-5 pb-5">
                    <div className={['corpsFiche','borderLeft','pt-0','pe-0'].join(" ")}>
                        <TabContext value={activeTab}>
                            <TabList onChange={changeActiveTab} className='px-2'>
                                <Tab label="Identité" value="1" />                                
                                {user.is_veto && <Tab label="Consultations" value="2"/>}
                                <Tab label="Documents" value="3"/>
                            </TabList>
                            <TabPanel value="1">
                            <Row>
                                <Col md={4} className='pe-4'>
                                    <TextField 
                                        id="nom" 
                                        label="Nom" 
                                        variant="standard" 
                                        value={editedAnimal.nom ? editedAnimal.nom : ''}
                                        onChange={updateAnimal}
                                        className="mb-1 w-100"
                                        error={!!nomErrorMsg}
                                        helperText={nomErrorMsg}
                                    />
                                    <TextField 
                                        id="poids" 
                                        label="Poids (Kg)" 
                                        variant="standard" 
                                        value={editedAnimal.poids ? editedAnimal.poids : ''}
                                        onChange={updateAnimal}
                                        className="mt-2 mb-1 w-100"
                                    />
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            id="date_naissance" 
                                            label="Date de naissance"
                                            value={editedAnimal.date_naissance ? dayjs(editedAnimal.date_naissance) : ''}
                                            onChange={updateAnimalDateNaissance}
                                            ampm={false}
                                            format="DD-MM-YYYY"
                                            className="mt-3 mb-1 w-100"
                                        />
                                    </LocalizationProvider>
                                    <div>
                                    <FormControl className="mt-2 mb-1">
                                        <FormLabel id="decede-label">Décédé(e)</FormLabel>
                                        <RadioGroup
                                            row
                                            aria-labelledby="decede-label"
                                            id="decede"
                                            name="Décédé(e)" 
                                            value={editedAnimal.decede != undefined ? editedAnimal.decede : 'non'}
                                            onChange={updateAnimalDeces}                                    
                                        >
                                            <FormControlLabel value='non' control={<Radio />} label="Non" />
                                            <FormControlLabel value='oui' control={<Radio />} label="Oui" />
                                        </RadioGroup>
                                    </FormControl>   
                                    </div>                                 
                                    {editedAnimal.decede == 'oui' && <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            id="date_deces" 
                                            label="Date de décès"
                                            value={editedAnimal.date_deces ? dayjs(editedAnimal.date_deces) : ''}
                                            onChange={updateAnimalDateDeces}
                                            ampm={false}
                                            format="DD-MM-YYYY"
                                            className="mt-3 mb-1 w-100"
                                        />
                                    </LocalizationProvider>}
                                    <FormControl className="mt-2 mb-1">
                                        <FormLabel id="sexe-label">Sexe</FormLabel>
                                        <RadioGroup
                                            row
                                            aria-labelledby="sexe-label"
                                            id="sexe"
                                            name="sexe" 
                                            value={editedAnimal.sexe != undefined ? editedAnimal.sexe : 'M'}
                                            onChange={updateAnimalSexe}                                    
                                        >
                                            <FormControlLabel value="M" control={<Radio />} label="Male" />
                                            <FormControlLabel value="F" control={<Radio />} label="Femelle" />
                                        </RadioGroup>
                                    </FormControl>
                                </Col>
                                <Col md={4} className='px-4'>
                                    <TextField 
                                        id="numero_puce" 
                                        label="Numéro de puce" 
                                        variant="standard" 
                                        value={editedAnimal.numero_puce ? editedAnimal.numero_puce : ''}
                                        onChange={updateAnimal}
                                        className="mb-1 w-100"
                                        error={!!puceErrorMsg}
                                        helperText={puceErrorMsg}
                                    />
                                    <FormControl variant="standard" className='mt-3' sx={{ width: '90%' }}>
                                        <Select
                                            labelId="espece-label"
                                            id="espece"                                    
                                            value={editedAnimal.id_espece ? listeEspeces.filter((c) => c.value == editedAnimal.id_espece) : ''}
                                            onChange={updateAnimalEspece}
                                            label="Espèce"
                                            options={listeEspeces}
                                        >
                                        </Select>
                                    </FormControl>
                                    <FormControl variant="standard" className='mt-3' sx={{ width: '100%' }}>
                                        <Select
                                            labelId="race-label"
                                            id="race"                          
                                            value={editedAnimal.id_race ? listeRaces.filter((c) => c.value == editedAnimal.id_race) : ''}
                                            onChange={updateAnimalRace}
                                            label="Race"
                                            placeholder="Race"
                                            options={listeRaces}
                                        >
                                        </Select>
                                    </FormControl>

                                    <div>
                                    <FormControl className="mt-2 mb-1">
                                        <FormLabel id="sterilise-label">Stérilisé(e)</FormLabel>
                                        <RadioGroup
                                            row
                                            aria-labelledby="sterilise-label"
                                            id="sterilise"
                                            name="Stérilisé(e)" 
                                            value={editedAnimal.sterilise != undefined ? editedAnimal.sterilise : 'non'}
                                            onChange={updateAnimalSterilisation}                                    
                                        >
                                            <FormControlLabel value='non' control={<Radio />} label="Non" />
                                            <FormControlLabel value='oui' control={<Radio />} label="Oui" />
                                        </RadioGroup>
                                    </FormControl>   
                                    </div>                                 
                                    {editedAnimal.sterilise == 'oui' && <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            id="date_sterilisation" 
                                            label="Date de stérilisation"
                                            value={editedAnimal.date_sterilisation ? dayjs(editedAnimal.date_sterilisation) : ''}
                                            onChange={updateAnimalDateSterilisation}
                                            ampm={false}
                                            format="DD-MM-YYYY"
                                            className="mt-3 mb-1 w-100"
                                        />
                                    </LocalizationProvider>}
                                </Col>
                                <Col md={4} className={['ps-4','text-center'].join(" ")}>
                                    <div className={['d-flex','align-items-center','justify-content-center', 'position-relative'].join(" ")}>
                                        <div className="animal_photo" alt="animal_photo" style={{ backgroundImage: `url(${process.env.REACT_APP_IMG_URL}${editedAnimal.photo})` }}>
                                        </div>
                                        <Button component="label" className='photoButton' variant="contained" startIcon={<AddAPhotoIcon />} sx={{ right: '20% !important' }}>
                                            <VisuallyHiddenInput type="file" onChange={handlePhotoChange}/>
                                        </Button>
                                    </div>
                                    {photoError && <small className='textRed'>{photoError}</small>}
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <p className={['titre','bold','mt-5','mb-1'].join(" ")}>
                                        Commentaires
                                        <IconButton aria-label="ajouter" className='ms-2' onClick={addCommentaire}>
                                            <AddBoxOutlinedIcon />
                                        </IconButton>
                                    </p>

                                    {editedAnimal.commentaires && editedAnimal.commentaires.length > 0 ? editedAnimal.commentaires.map((c, i) => {	
                                        return (<div className = 'mt-3' key={c.id}>
                                            <Row className="align-items-center">
                                                <Col md={4}>
                                                    <TextField 
                                                        id="contenu" 
                                                        label="contenu" 
                                                        variant="standard" 
                                                        value={c.contenu ? c.contenu : ''}
                                                        onChange={e => {updateCommentaire(c.id, e.target.value);}}
                                                        className="mb-1 w-100"
                                                    />
                                                </Col>
                                                <Col md={1}>
                                                    <p>{c.date}</p>
                                                </Col>
                                                <Col md={1}>
                                                    <IconButton aria-label="supprimer" className="textRed" onClick={() => supprCommentaire(c.id)}>
                                                        <DeleteOutlineIcon data-id={c.id} />
                                                    </IconButton>
                                                </Col>
                                            </Row>
                                        </div>)
                                        }) : (<div></div>)
                                    }   
                                </Col>
                            </Row>
                            </TabPanel>
                            {user.is_veto && <TabPanel value="2">
                                <Consultations animal={editedAnimal} /> 
                            </TabPanel>}
                            <TabPanel value="3">
                                <Documents animal={editedAnimal} /> 
                            </TabPanel>
                        </TabContext>
                    </div>
                </Col>
               
            </Row>
        </Container>

        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={openRdv}
            onClose={handleCloseRdv}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: { timeout: 500 },
            }}
        >
            <Fade in={openRdv}>
                <Box className={!user.is_veto && "modal-sm"}>
                    <p className={['titre','bold','mb-2'].join(" ")}>{editedRdv.id_rdv ? 'Consultation' : 'Demande de consultation'}</p>

                    <IconButton aria-label="modifier" className="modalExit" onClick={handleCloseRdv}>
                        <CloseIcon/>
                    </IconButton>

                    <Row className="align-items-start">
                        {user.is_veto && <Col className='pe-4'>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                id="date_rdv" 
                                label="Date"
                                value={editedRdv.date_rdv ? dayjs(editedRdv.date_rdv) : ''}
                                onChange={updateDateRdv}
                                ampm={false}
                                format="DD/MM/YYYY HH:mm"
                                className="mt-3 mb-1 w-100"
                            />
                            </LocalizationProvider>
                            <FormControl variant="standard" sx={{ width: '100%' }} className='mt-2'>
                                <Select
                                id="type_prestation"
                                value={editedRdv.type_prestation ? listeTypesPrestations.filter((c) => c.value == editedRdv.type_prestation) : ''}
                                onChange={updateTypePrestationRdv}
                                placeholder="Type de prestation"
                                options={listeTypesPrestations}
                                >                      
                                </Select>
                            </FormControl>                            
                        </Col>}
                        <Col className={[(user.is_veto ? 'borderLeft' : ''),'ps-4'].join(" ")}>
                            <TextField 
                                id="motif_consultation" 
                                label="Motif de consultation" 
                                variant="standard" 
                                value={editedRdv.motif_consultation ? editedRdv.motif_consultation : ''}
                                onChange={updateRdv}
                                className="mb-1 w-100"
                                error={!!motifError}
                                helperText={motifError}
                            />
                            <TextField 
                                id="symptomes" 
                                label="Symptômes" 
                                variant="standard" 
                                value={editedRdv.symptomes ? editedRdv.symptomes : ''}
                                onChange={updateRdv}
                                className="mt-2 mb-1 w-100"
                                error={!!symptomesError}
                                helperText={symptomesError}
                            />
                            <TextField 
                                id="duree_symptomes" 
                                label="Durée des symptômes" 
                                variant="standard" 
                                value={editedRdv.duree_symptomes ? editedRdv.duree_symptomes : ''}
                                onChange={updateRdv}
                                className="mt-2 mb-1 w-100"
                            />
                            <TextField 
                                id="antecedents" 
                                label="Antécédents" 
                                variant="standard" 
                                value={editedRdv.antecedents ? editedRdv.antecedents : ''}
                                onChange={updateRdv}
                                className="mt-2 mb-1 w-100"
                            />
                        </Col>
                    </Row>                    

                    <Row>
                        <Col md={6}>
                            <Button variant="contained" className={['buttonSecondary','mt-5'].join(" ")} type="submit" startIcon={<CheckIcon />} onClick={saveRdv}>Sauvegarder</Button>
                        </Col>
                        <Col md={6} className={['d-flex','justify-content-end'].join(" ")}>
                            <Button variant="contained" className={(editedRdv.ready ? ['buttonSecondary','mt-5'] : ['mt-5']).join(" ")} startIcon={(loadingCall ? <CircularProgress/> : <LocalPhoneIcon />)} onClick={() => appel(editedRdv.id_rdv)} disabled={!editedRdv.ready}>{user.is_veto ? "Appeler" : "Rejoindre"}</Button>
                        </Col>
                    </Row>  
                </Box>
            </Fade>
        </Modal>

        <Prompt
            when={needSave}
            message='You have unsaved changes, are you sure you want to leave?'
        />
        </div>
    )
}

export default FicheClient;