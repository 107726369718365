import {React, useState} from 'react';
import axios from "axios";

export async function getCabDatas(user, past_rdv = false, cas_ref_ouverts = false) {
    
    return axios.post(process.env.REACT_APP_API_URL+'cabinets/get_one', {
        token: user.sessionToken,
        cabinet: user.cabinet.id,
        past_rdv: past_rdv, 
        cas_ref_ouverts: cas_ref_ouverts
    }, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    })
    .catch(function (error) {
        console.log(error.toJSON());
    });
}

export async function getCabDatasSimple(user) {
    
    return axios.post(process.env.REACT_APP_API_URL+'cabinets/get_one', {
        token: user.sessionToken,
        cabinet: user.cabinet.id,
        fulldatas: false
    }, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    })
    .catch(function (error) {
        console.log(error.toJSON());
    });
}


export async function getCabClients(user) {
    
    return axios.post(process.env.REACT_APP_API_URL+'cabinets/get_clients', {
        token: user.sessionToken,
        cabinet: user.cabinet.id
    }, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    })
    .catch(function (error) {
        console.log(error.toJSON());
    });
}


export async function getCabVeterinaires(user) {
    
    return axios.post(process.env.REACT_APP_API_URL+'cabinets/get_veterinaires', {
        token: user.sessionToken
    }, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    })
    .catch(function (error) {
        console.log(error.toJSON());
    });
}


export async function getCabPartenaires(user) {
    
    return axios.post(process.env.REACT_APP_API_URL+'cabinets/get_partenaires', {
        token: user.sessionToken
    }, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    })
    .catch(function (error) {
        console.log(error.toJSON());
    });
}


export function useCabCredentials() {

    const getCab = () => {
      const tokenString = localStorage.getItem('cabinet');
      const userToken = JSON.parse(tokenString);
      return userToken
    };
  
    const [cabinet, setCabinet] = useState(getCab());
  
    const saveCab = cabDatas => {
      localStorage.setItem('cabinet', JSON.stringify(cabDatas));
      setCabinet(cabDatas);
    };
  
    return {
      setCabinet: saveCab,
      cabinet
    }
}

export async function saveCabinet(datas, id_cabinet, user = null) {
    
    return axios.post(process.env.REACT_APP_API_URL+'cabinet/set', {
        token: (user != null ? user.sessionToken : process.env.REACT_APP_TOKEN),
        id_cabinet: (id_cabinet != null ? id_cabinet : 'new'),
        datas: JSON.stringify(datas)
    }, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    })
    .catch(function (error) {
        console.log(error.toJSON());
    });
}


export async function getCabFactures(user) {
    
    return axios.post(process.env.REACT_APP_API_URL+'cabinets/get_factures', {
        token: user.sessionToken,
        cabinet: user.cabinet.id
    }, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    })
    .catch(function (error) {
        console.log(error.toJSON());
    });
}