import { React, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {Button} from '@mui/material';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../App/App.css';

import {useCredentials} from '../../Services/authServices';
import {getClientDatas, valideInscription} from '../../Services/UserServices'; 


function ValidationClient() {

    const { id } = useParams();
    const { user } = useCredentials();
    const [client, setClient] = useState({});
    
    useEffect(() => {
        if(!user) { window.open('/login','_self'); }
        else {
            getClientDatas(user, id).then(res => {
                if(res != undefined && res.data != undefined && res.data.statut == "ok") {
                    setClient(res.data.donnees);
                }
            });

            valideInscription(user, user.id_gocardless).then(function(res) {
                if(res != undefined && res.data != undefined && res.data.statut == "ok") {
                    console.log(res);
                }
            });
        }
    }, []);


    return(
        <div className="fullpage">
        <Container className={['full_container','inscription_cabinet_page','align_items_start'].join(" ")}>
            
            <div className='user_widgets'>  
                <div className="logo">          
                    <img src='/img/logo.png' className="w-100" alt="logo"/>
                </div>
            </div>
            
            <Row className={['w-100',' ps-4'].join(" ")}>

                <Col xs={10} className={['px-5','pt-5'].join(" ")}>
                    <Row>
                        <Col md={8}>
                            <h2 className="bold">Merci !</h2>
                            <p className='textSecondary'>Un mail vous a été envoyé avec vos identifiants de connexion.</p>
                            <p className='textSecondary'>Cliquez sur le lien ci-dessous pour revenir à l'accueil</p>

                            <Button variant="contained" className={['buttonSecondary','mt-5'].join(" ")} startIcon={<ArrowBackIcon />} onClick={() => window.open('/','_self')}>Accueil</Button>
                        </Col>
                    </Row>
                </Col>
               
            </Row>
        </Container>

        </div>
    )
}

export default ValidationClient;