import { React, useEffect, useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../App/App.css';

import {useCredentials, getByToken} from '../../Services/authServices';
import {getClientDatas, setClientDatas} from '../../Services/UserServices';


function Autologin() {

    const { id } = useParams();  
    const queryParams = new URLSearchParams(window.location.search);
    const { user, setUser } = useCredentials();
    
    useEffect(() => {

        const sessionToken = queryParams.get('sessionToken');
        if(!user || !user.id) {
            if(!sessionToken) { window.open('/login', '_self'); }
            else {
                getByToken(sessionToken).then(res => {
                    if(res != undefined && res.data != undefined && res.data.statut == "ok") {
                        if(res.data.donnees.id == id) {
                            let user_datas = {...res.data.donnees};
                            user_datas.sessionToken = sessionToken;
                            setUser(user_datas);
                            window.open('/client/'+user_datas.id, '_self');
                        }
                        else {
                            window.open('/login', '_self');
                        }
                    }
                    else {
                        window.open('/login', '_self');
                    }
                });
            }
        }
    }, [user]); 

    if(user) {
        return <Redirect to={"/client/"+user.id} />
    }
}

export default Autologin;