import axios from "axios";

export async function getClientDatas(user, id_client) {
    
    return axios.post(process.env.REACT_APP_API_URL+'clients/get_one', {
        token: user.sessionToken,
        id_client: id_client
    }, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    })
    .catch(function (error) {
        console.log(error.toJSON());
    });
}

export async function setClientDatas(user, id_client, datas) {
    
    return axios.post(process.env.REACT_APP_API_URL+'client/set', {
        token: user.sessionToken,
        id_client: id_client,
        datas: JSON.stringify(datas)
    }, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    })
    .catch(function (error) {
        console.log(error.toJSON());
    });
}

export async function deleteClient(user, id_client) {
    
    return axios.post(process.env.REACT_APP_API_URL+'clients/delete', {
        token: user.sessionToken,
        id_client: id_client
    }, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    })
    .catch(function (error) {
        console.log(error.toJSON());
    });
}

export async function setVeterinaireDatas(user, id_veto, datas) {
    
    return axios.post(process.env.REACT_APP_API_URL+'veterinaire/set', {
        token: user.sessionToken,
        id_veto: id_veto,
        datas: JSON.stringify(datas)
    }, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    })
    .catch(function (error) {
        console.log(error.toJSON());
    });
}

export async function valideInscription(user, id_gocardless) {
    
    return axios.post(process.env.REACT_APP_API_URL+'client/valide_inscription', {
        token: user.sessionToken,
        id_gocardless: id_gocardless
    }, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    })
    .catch(function (error) {
        console.log(error.toJSON());
    });
}


export async function sendInvitation(user, id_client) {
    
    return axios.post(process.env.REACT_APP_API_URL+'clients/invitation', {
        token: user.sessionToken,
        id_client: id_client
    }, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    })
    .catch(function (error) {
        console.log(error.toJSON());
    });
}

export async function sendInvitationVeto(user, datas) {
    
    return axios.post(process.env.REACT_APP_API_URL+'veterinaire/invitation', {
        token: user.sessionToken,
        datas: JSON.stringify(datas)
    }, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    })
    .catch(function (error) {
        console.log(error.toJSON());
    });
}

export async function getClientFactures(user) {
    
    return axios.post(process.env.REACT_APP_API_URL+'clients/get_factures', {
        token: user.sessionToken
    }, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    })
    .catch(function (error) {
        console.log(error.toJSON());
    });
}

export async function getSpecialistes(user) {

    return axios.post(process.env.REACT_APP_API_URL+'veterinaires/get_specialistes', {
        token: user.sessionToken
    }, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    })
    .catch(function (error) {
        console.log(error.toJSON());
    });
}

