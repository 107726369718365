import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import '../fonts/arvo/Arvo-Bold.ttf';
import '../fonts/arvo/Arvo-Regular.ttf';
import '../fonts/lato/Lato-Bold.ttf';
import '../fonts/lato/Lato-Regular.ttf';
import '../fonts/lato/Lato-Light.ttf';
import '../fonts/lato/Lato-LightItalic.ttf';
import './App.css';

import Dashboard from '../Components/Dashboard/Dashboard';
import Autologin from '../Components/Login/Autologin';
import Login from '../Components/Login/Login';
import Logout from '../Components/Login/Logout';
import IndexClients from '../Components/Clients/Index';
import FicheClient from '../Components/Clients/Fiche';
import FicheAnimal from '../Components/Clients/FicheAnimal';
import NouveauCasReferes from '../Components/Clients/NouveauCasRefere';
import CasReferes from '../Components/Clients/CasReferes';
import Agenda from '../Components/Agenda/Agenda';
import Messagerie from '../Components/Messagerie/Messagerie';
import Factures from '../Components/Documents/Factures';
import Parametres from '../Components/Parametres/Parametres';
import InscriptionClient from '../Components/Inscription/InscriptionClients';
import InscriptionCabinet from '../Components/Inscription/InscriptionCabinet';
import InscriptionCabinet2 from '../Components/Inscription/InscriptionCabinet2';
import ValidationCabinet from '../Components/Inscription/ValidationCabinet';
import ValidationClient from '../Components/Inscription/ValidationClient';

import {useCredentials} from '../Services/authServices';

function App() {

  const { user } = useCredentials();
  if(!user && window.location.pathname.indexOf('autologin/') == -1 && window.location.pathname.indexOf('inscription_') == -1 && window.location.pathname.indexOf('validation_') == -1) { return <Login /> }

  return (
    <BrowserRouter>
      <Switch>
        <Route path="/autologin/:id" component={Autologin} /> 
        <Route path="/login" component={Login} /> 
        <Route path="/logout" component={Logout} /> 
        
        <Route path="/inscription_client/:id" component={InscriptionClient} />     
        <Route path="/inscription_cabinet" component={InscriptionCabinet} />     
        <Route path="/inscription_cabinet_2" component={InscriptionCabinet2} />   
        <Route path="/validation_cabinet" component={ValidationCabinet} />
        <Route path="/validation_client/:id" component={ValidationClient} />  

        <Route path="/client/:id" component={FicheClient}/>
        <Route path="/clients" component={IndexClients} />  
        <Route path="/animal/:id" component={FicheAnimal}/>
        <Route path="/cas_referes" component={CasReferes} /> 
        <Route path="/nouveau_cas_refere" component={NouveauCasReferes} />         
        <Route path="/agenda" component={Agenda} />    
        <Route path="/messagerie" component={Messagerie} /> 
        <Route path="/factures" component={Factures} /> 
        <Route path="/parametres" component={Parametres} /> 
        <Route path="/" component={Dashboard} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;