import { React, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Redirect, useParams } from 'react-router-dom';
import axios from "axios";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CheckIcon from '@mui/icons-material/Check';
import EditNoteIcon from '@mui/icons-material/EditNote';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import CloseIcon from '@mui/icons-material/Close';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Skeleton, TextField, Button} from '@mui/material';
import Card from '@mui/material/Card';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import IconButton from '@mui/material/IconButton';
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../App/App.css';

import {useCabCredentials, saveCabinet} from '../../Services/cabinetServices'; 
import {uploadPhoto} from '../../Services/FileServices';


const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});


function InscriptionCabinet() {

    const { cabinet, setCabinet } = useCabCredentials();
    const [nomErrorMsg, setNomErrorMsg] = useState();
    const [sirenErrorMsg, setSirenErrorMsg] = useState();
    const [cabTelErrorMsg, setCabTelErrorMsg] = useState();
    const [cabEmailErrorMsg, setCabEmailErrorMsg] = useState();
    const [collabErrorMsg, setCollabErrorMsg] = useState();

    const liste_specialites = ['Médecine interne', 'Oncologie', 'Cardiologie', 'Chirurgie', 'Imagerie', 'Dermatologie', 'Neurologie', 'Reproduction', 'Anesthesie', 'Urgences & soins intensifs', 'Comportement'];

    const [maxIdCollab, setMaxIdCollab] = useState(0);
    const [collaborateurs, setCollaborateurs] = useState([]);
    const [editedCollab, setEditedCollab] = useState({});  
    const [photoError, setPhotoError] = useState();    
    const [nomErrorMsg2, setNomErrorMsg2] = useState();  
    const [prenomErrorMsg, setPrenomErrorMsg] = useState();   
    const [emailErrorMsg, setEmailErrorMsg] = useState();
    const [open, setOpen] = useState(false);
    const handleClose = () => setOpen(false);
    
    useEffect(() => {
        if(cabinet == null) { setCabinet({}); }
        else if(cabinet.personnes && cabinet.personnes.length > 0) {
            setCollaborateurs(cabinet.personnes);
            cabinet.personnes.map((c, i) => {
                if(c.id_collab >= maxIdCollab) { setMaxIdCollab(c.id_collab); }
            });
        }
    }, []);


    
    const handleChange = (e) => {

        if(!cabinet.nom || cabinet.nom.length == 0) { setNomErrorMsg('Ce champ est obligatoire'); }
        else { setNomErrorMsg(null); }
        if(!cabinet.siren || cabinet.siren.length == 0) { setSirenErrorMsg('Ce champ est obligatoire'); }
        else { setSirenErrorMsg(null); }

        let cabVals = {...cabinet};
        cabVals[e.target.id] = e.target.value;
        setCabinet(cabVals);       
    }


    const changeEditedCollab = (id_collab) => {
        let collabVals = {};
        collaborateurs.map((c, i) => {
            if(c.id_collab == id_collab) { collabVals = {...c}; }
        });        
        setEditedCollab(collabVals);
        setOpen(true);
    }

    const addCollab = () => {
        setEditedCollab({'id':'new', 'id_collab': (maxIdCollab+1)});
        setOpen(true);
        setMaxIdCollab(maxIdCollab+1);
    }

    const updateCollab = (e) => {

        if(!editedCollab.nom || editedCollab.nom.length == 0) { setNomErrorMsg2('Ce champ est obligatoire'); }
        else { setNomErrorMsg2(null); }
        if(!editedCollab.prenom || editedCollab.prenom.length == 0) { setPrenomErrorMsg('Ce champ est obligatoire'); }
        else { setPrenomErrorMsg(null); }
        if(!editedCollab.email || editedCollab.email.length == 0) { setEmailErrorMsg('Ce champ est obligatoire'); }
        else { setCollabErrorMsg(null); }

        let collabVals = {...editedCollab};
        collabVals[e.target.id] = e.target.value;
        setEditedCollab(collabVals);
    }
    const updateCollabPoste = (e) => {
        let collabVals = {...editedCollab};
        collabVals['poste'] = e.target.value;
        setEditedCollab(collabVals); 
    }
    const updateCollabSpecialite = (e) => {
        let collabVals = {...editedCollab};
        collabVals['specialite'] = e.target.value;
        setEditedCollab(collabVals);
    }
    const updateCollabSexe = (e) => {
        let collabVals = {...editedCollab};
        collabVals['sexe'] = e.target.value;
        setEditedCollab(collabVals);
    }

    const handlePhotoChange = (e) => {

        if (e.target.files) {

            const photo = e.target.files[0];
            if(photo.size > 5000000) { setPhotoError('L\'image est trop lourde'); }
            else if(photo.type != 'image/jpeg' && photo.type != 'image/png') { setPhotoError('Le fichier n\'est pas une image'); }
            else {
                setPhotoError(null);
                uploadPhoto(photo).then((res) => {
                    if(res != undefined && res.data != undefined && res.data.statut == "ok") {
                        let collabVals = {...editedCollab};
                        collabVals['photo'] = res.data.filename;
                        setEditedCollab(collabVals);
                    }
                });                
            }            
        }
    }

    const saveCollab = () => {

        let nb_errors = 0;
        if(!editedCollab.nom || editedCollab.nom.length == 0) { setNomErrorMsg2('Ce champ est obligatoire'); nb_errors++; }
        else { setNomErrorMsg2(null); }
        if(!editedCollab.prenom || editedCollab.prenom.length == 0) { setPrenomErrorMsg('Ce champ est obligatoire'); nb_errors++; }
        else { setPrenomErrorMsg(null); }
        if(!editedCollab.email || editedCollab.email.length == 0) { setEmailErrorMsg('Ce champ est obligatoire'); nb_errors++; }
        else { setCollabErrorMsg(null); }

        if(nb_errors == 0) {
            setCollabErrorMsg(null);            

            let liste_collab = [...collaborateurs];
            let found = false;
            liste_collab.map((c, i) => {
                if(c.id_collab == editedCollab.id_collab) { 
                    liste_collab[i] = editedCollab; 
                    found = true;
                }
            });
            if(!found) { liste_collab.push(editedCollab); }
            setCollaborateurs(liste_collab);
            setOpen(false);

            let cabVals = {...cabinet};
            cabVals['personnes'] = liste_collab;
            setCabinet(cabVals);
        }        
    }

    const supprCollab = (id_collab) => {
        let liste_collab = [...collaborateurs];
        liste_collab.map((c, i) => {
            if(c.id_collab == id_collab) { liste_collab.splice(i, 1); }
        });
        setCollaborateurs(liste_collab);

        let cabVals = {...cabinet};
        cabVals['personnes'] = liste_collab;
        setCabinet(cabVals);
    }


    const valideInscription1 = () => {

        var nb_errors = 0;
        if(!cabinet.nom || cabinet.nom.length == 0) { setNomErrorMsg('Ce champ est obligatoire'); nb_errors++; }
        if(!cabinet.siren || cabinet.siren.length == 0) { setSirenErrorMsg('Ce champ est obligatoire'); nb_errors++; }
        if(!cabinet.telephone || cabinet.telephone.length == 0) { setCabTelErrorMsg('Ce champ est obligatoire'); nb_errors++; }
        if(!cabinet.email || cabinet.email.length == 0) { setCabEmailErrorMsg('Ce champ est obligatoire'); nb_errors++; }
        if(!cabinet.personnes || cabinet.personnes.length == 0) { setCollabErrorMsg('Vosu devez renseigner au moins 1 collaborateur'); nb_errors++; }

        if(nb_errors == 0) {
            window.open('/inscription_cabinet_2','_self');
        }
    }


    return(
        <div className="fullpage">
        <Container className={['full_container','inscription_cabinet_page'].join(" ")}>
            
            <div className='user_widgets'>  
                <div className="logo">          
                    <img src='/img/logo.png' className="w-100" alt="logo"/>
                </div>
            </div>
            
            <Row className={['w-100',' ps-4'].join(" ")}>

                <Col xs={10} className={['px-5','pt-5'].join(" ")}>
                    <Row className="align-items-center">
                        <Col md={8}>
                            <h2 className="bold">Bienvenue !</h2>
                            <p className='mb-1'>Etape 1/2 - <span className='textSecondary'>Informations générales </span></p>
                        </Col>
                    </Row>
                </Col>

                <Col xs={12} md={6} className="pb-5">
                    <div className={['corpsFiche','mt-3'].join(" ")}>
                        <p className={['titre','bold','mb-1'].join(" ")}>Informations du cabinet</p>

                        <Row>
                            <Col md={6}>
                                <TextField 
                                    required
                                    id="nom" 
                                    label="Nom du cabinet" 
                                    variant="standard"
                                    value={cabinet && cabinet.nom ? cabinet.nom : ''}
                                    onChange={handleChange}
                                    className="mt-2 mb-1 w-100"
                                    error={!!nomErrorMsg}
                                    helperText={nomErrorMsg}
                                />
                                <TextField 
                                    id="telephone" 
                                    label="Téléphone" 
                                    variant="standard" 
                                    value={cabinet && cabinet.telephone ? cabinet.telephone : ''}
                                    onChange={handleChange}
                                    className="mt-2 mb-1 w-100"
                                    error={!!cabTelErrorMsg}
                                    helperText={cabTelErrorMsg}
                                />
                                
                            </Col>
                            <Col md={6}>
                                <TextField 
                                    required
                                    id="siren" 
                                    label="SIREN" 
                                    variant="standard" 
                                    value={cabinet && cabinet.siren ? cabinet.siren : ''}
                                    onChange={handleChange}
                                    className="mt-2 mb-1 w-100"
                                    error={!!sirenErrorMsg}
                                    helperText={sirenErrorMsg}
                                />
                                <TextField 
                                    id="email" 
                                    label="Email" 
                                    variant="standard" 
                                    value={cabinet && cabinet.email ? cabinet.email : ''}
                                    onChange={handleChange}
                                    className="mt-2 mb-1 w-100"
                                    error={!!cabEmailErrorMsg}
                                    helperText={cabEmailErrorMsg}
                                />
                            </Col>
                        </Row>

                        <p className={['titre','bold','mt-5','mb-1'].join(" ")}>Adresse</p>
                        
                        <Row>
                            <Col md={6}>
                                <TextField 
                                    id="adresse" 
                                    label="Adresse" 
                                    variant="standard" 
                                    value={cabinet && cabinet.adresse ? cabinet.adresse : ''}
                                    onChange={handleChange}
                                    className="mt-2 mb-1 w-100"
                                />
                                <TextField 
                                    id="ville" 
                                    label="Ville" 
                                    variant="standard" 
                                    value={cabinet && cabinet.ville ? cabinet.ville : ''}
                                    onChange={handleChange}
                                    className="mt-2 mb-1 w-100"
                                />
                                
                            </Col>
                            <Col md={6}>
                                <TextField 
                                    id="cp" 
                                    label="Code postal" 
                                    variant="standard" 
                                    value={cabinet && cabinet.cp ? cabinet.cp : ''}
                                    onChange={handleChange}
                                    className="mt-2 mb-1 w-100"
                                />
                            </Col>
                        </Row>

                        <Button variant="contained" className={['buttonSecondary','mt-5'].join(" ")} type="submit" startIcon={<ArrowForwardIcon />} onClick={valideInscription1}>Valider et continuer</Button>
                    </div>
                </Col>

                <Col xs={12} md={6}>
                    <div className={['corpsFiche','borderLeft'].join(" ")}>

                        <p className={['titre','bold','mb-1'].join(" ")}>Liens sociaux</p>
                        
                        <Row>
                            <Col md={6}>
                                <TextField 
                                    id="site_web" 
                                    label="Site web" 
                                    variant="standard" 
                                    value={cabinet && cabinet.site_web ? cabinet.site_web : ''}
                                    onChange={handleChange}
                                    className="mt-2 mb-1 w-100"
                                />                                
                            </Col>
                            <Col md={6}>
                                <TextField 
                                    id="facebook" 
                                    label="Facebook" 
                                    variant="standard" 
                                    value={cabinet && cabinet.facebook ? cabinet.facebook : ''}
                                    onChange={handleChange}
                                    className="mt-2 mb-1 w-100"
                                />
                            </Col>
                        </Row>

                        <p className={['titre','bold', 'mt-5','mb-2'].join(" ")}>
                            Collaborateurs ({collaborateurs.length})
                            <IconButton aria-label="supprimer" className="ms-3" onClick={addCollab}>
                                <AddBoxOutlinedIcon />
                            </IconButton>
                        </p>

                        <Col md={7}>
                        {collaborateurs.length > 0 ? collaborateurs
                            .map((p, i) => {	
                            var mt = (i > 0 ? "mt-3" : "mt-1");
                            return (<div className = {mt} key={p.id} >
                                <Card className={['px-4','py-3','mt-3', 'w-auto'].join(" ")}>
                                    <Row className="align-items-center">
                                        <Col md={6} className='ps-4'>
                                            <p className="bigText">{p.nom}</p>
                                            <p className="smallText">{p.prenom}</p>
                                        </Col>
                                        <Col md={6} className="textRight">
                                            <IconButton aria-label="modifier" className="mr-2" onClick={() => changeEditedCollab(p.id_collab)}>
                                                <EditNoteIcon/>
                                            </IconButton>
                                            <IconButton aria-label="supprimer" className="textRed" onClick={() => supprCollab(p.id_collab)}>
                                                <DeleteOutlineIcon/>
                                            </IconButton>
                                        </Col>
                                    </Row>
                                </Card>
                            </div>)
                            }) : (<div></div>)
                        }
                        {collabErrorMsg && <p className='textRed'>{collabErrorMsg}</p>} 
                        </Col>
                    </div>
                </Col>
               
            </Row>
        </Container>

        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: { timeout: 500 },
            }}
        >
            <Fade in={open}>
                <Box>
                    <p className={['titre','bold','mb-2'].join(" ")}>{editedCollab.prenom || editedCollab.nom ? ((editedCollab.prenom ? editedCollab.prenom : '') + ' ' + (editedCollab.nom ? editedCollab.nom : '')) : 'Nouveau collaborateur'}</p>

                    <IconButton aria-label="modifier" className="modalExit" onClick={handleClose}>
                        <CloseIcon/>
                    </IconButton>

                    <Row className='mt-3'>
                        <Col md={6} className='text-center'>
                            <div className={['d-flex','align-items-center','justify-content-center', 'position-relative'].join(" ")}>
                                <div className="animal_photo" alt="animal_photo" style={{ backgroundImage: `url(${process.env.REACT_APP_IMG_URL}${editedCollab.photo})` }}>
                                </div>
                                <Button component="label" className='photoButton' variant="contained" startIcon={<AddAPhotoIcon />}>
                                    <VisuallyHiddenInput type="file" onChange={handlePhotoChange}/>
                                </Button>
                            </div>
                            {photoError && <small className='textRed'>{photoError}</small>}
                        </Col>
                        <Col md={6}>
                            <TextField 
                                id="nom" 
                                label="Nom" 
                                variant="standard" 
                                value={editedCollab.nom ? editedCollab.nom : ''}
                                onChange={updateCollab}
                                className="mt-2 mb-1 w-100"
                                error={!!nomErrorMsg2}                                
                                helperText={nomErrorMsg2}
                            />
                            <TextField 
                                id="prenom" 
                                label="Prénom" 
                                variant="standard" 
                                value={editedCollab.prenom ? editedCollab.prenom : ''}
                                onChange={updateCollab}
                                className="mt-2 mb-1 w-100"
                                error={!!prenomErrorMsg}                                
                                helperText={prenomErrorMsg}
                            />
                            <FormControl className="mt-3 mb-1">
                                <FormLabel id="sexe-label">Sexe</FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby="sexe-label"
                                    id="sexe"
                                    name="sexe" 
                                    value={editedCollab.sexe != undefined ? editedCollab.sexe : 'H'}
                                    onChange={updateCollabSexe}                                    
                                >
                                    <FormControlLabel value="H" control={<Radio />} label="Homme" />
                                    <FormControlLabel value="F" control={<Radio />} label="Femme" />
                                </RadioGroup>
                            </FormControl>                            
                            <TextField 
                                id="email" 
                                label="Email" 
                                variant="standard" 
                                value={editedCollab.email ? editedCollab.email : ''}
                                onChange={updateCollab}
                                className="mt-2 mb-1 w-100"
                                error={!!emailErrorMsg}                                
                                helperText={emailErrorMsg}
                            />
                        </Col>
                    </Row>
                    <Row className='mt-3'>
                        <Col md={6} className='ps-3'>
                            <FormControl variant="standard" sx={{ width: '100%' }} className='mt-2'>
                                <InputLabel id="poste">Poste</InputLabel>
                                <Select
                                    labelId="poste"
                                    id="poste"
                                    value={editedCollab.poste ? editedCollab.poste : ''}
                                    onChange={updateCollabPoste}
                                    label="Poste"
                                    >
                                    <MenuItem key='1' value='veterinaire'>Vétérinaire généraliste</MenuItem>
                                    <MenuItem key='2' value='specialiste'>Vétérinaire spécialiste</MenuItem>
                                    <MenuItem key='3' value='assistant'>Assistant(e) vétérinaire</MenuItem>
                                    <MenuItem key='4' value='secretaire'>Secrétaire</MenuItem>
                                </Select>
                            </FormControl>
                            {editedCollab.poste && (editedCollab.poste == 'veterinaire' || editedCollab.poste == 'specialiste') && 
                                <TextField 
                                    id="numero_ordre" 
                                    label="Numéro d'ordre des vétérinaires" 
                                    variant="standard" 
                                    value={editedCollab.numero_ordre ? editedCollab.numero_ordre : ''}
                                    onChange={updateCollab}
                                    className="mt-2 mb-1 w-100"
                                />
                            }
                            {editedCollab.poste && editedCollab.poste == 'specialiste' && 
                                <FormControl variant="standard" sx={{ width: '100%' }} className='mt-2'>
                                    <InputLabel id="specialite-label">Spécialité</InputLabel>
                                    <Select
                                        labelId="specialite-label"
                                        id="specialite"
                                        value={editedCollab.specialite ? editedCollab.specialite : ''}
                                        onChange={updateCollabSpecialite}
                                        label="Spécialité"
                                        placeholder="Spécialité"
                                        >          
                                        {liste_specialites.map((sp, i) => {return (<MenuItem key={i} value={sp}>{sp}</MenuItem>)} )}   
                                    </Select>
                                </FormControl>
                            }                            
                        </Col>
                        <Col md={6}>                      
                            <p className='smallText'>Présentation</p>
                            <BaseTextareaAutosize 
                                id="notes" 
                                label="Présentation" 
                                variant="standard" 
                                value={editedCollab.notes ? editedCollab.notes : ''}
                                onChange={updateCollab}
                                className="mt-1 mb-1 w-100"
                                minRows={3}
                                placeholder="..." 
                            />
                        </Col>
                    </Row>

                    <Button variant="contained" className={['buttonSecondary','mt-5'].join(" ")} type="submit" startIcon={<CheckIcon />} onClick={saveCollab}>Sauvegarder</Button>
                </Box>
            </Fade>
        </Modal>

        </div>
    )
}

export default InscriptionCabinet;