import { React, useEffect, useState } from 'react';
import { Redirect } from "react-router-dom";
import UserWidget from '../Dashboard/UserWidget';
import SideMenu from '../Dashboard/SideMenu';
import CheckIcon from '@mui/icons-material/Check';
import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Skeleton, TextField, Button, SwipeableDrawer} from '@mui/material';
import Badge from '@mui/material/Badge';
import CircularProgress from '@mui/material/CircularProgress';
import Card from '@mui/material/Card';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import dayjs from 'dayjs';
import swal from 'sweetalert';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateField } from '@mui/x-date-pickers/DateField';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import Select from 'react-select'
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../App/App.css';

import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'

import {getClientDatas} from '../../Services/UserServices'; 
import {useCredentials} from '../../Services/authServices';
import {getCabDatas} from '../../Services/cabinetServices'; 
import {getRdvs, setRdv, demarreRdv} from '../../Services/RDVServices';
import {getTypesPrestations} from '../../Services/prestationsServices';


function Agenda() {

    const { user, setUser } = useCredentials();
    const [cabRdv, setCabRdv] = useState([]);
    const [listeRdv, setListeRdv] = useState([]);
    const [listeDemandesRdv, setListeDemandesRdv] = useState([]);
    const [editedRdv, setEditedRdv] = useState({});
    const [openRdv, setOpenRdv] = useState(false);
    const handleOpenRdv = () => setOpenRdv(true);
    const handleCloseRdv = () => setOpenRdv(false);    
    const [animalError, setAnimalError] = useState();  
    const [motifError, setMotifError] = useState();
    const [symptomesError, setSymptomesError] = useState();
    
    const [openDrawer, setOpenDrawer] = useState(false);
    const [loadingCall, setLoadingCall] = useState(false);

    const [listeClients, setListeClients] = useState([]);
    const [listeAnimaux, setListeAnimaux] = useState([]);
    
    const [typesPrestations, setTypesPrestations] = useState([]);
  
    useEffect(() => {

        getCabDatas(user, true).then(res => {
            if(res != undefined && res.data != undefined && res.data.statut == "ok") {
                
                let optionsClients = [];
                res.data.donnees.clients.map((c, i) => {
                    optionsClients.push({value: c.id, label: c.prenom+' '+c.nom});
                }); 
                setListeClients(optionsClients);

                let liste = [];
                res.data.donnees.rdv.map((r, i) => {
                    liste.push({
                        title: r.client,
                        start: r.date_rdv.replace(' ', 'T'),
                        resourceId: r.id
                    });
                });             
                setListeRdv(liste);
                setCabRdv(res.data.donnees.rdv);
                setListeDemandesRdv(res.data.donnees.demandes_rdv);
            }
        });

        getTypesPrestations(user.cabinet.id).then(res_t => {
            if(res_t != undefined && res_t.data != undefined && res_t.data.statut == "ok") {
              setTypesPrestations(res_t.data.donnees);
            }
        });
        
    }, [user]);


    const updatelisteAnimaux = (e) => {
        let rdvVals = {...editedRdv};
        setListeAnimaux([]);
    
        getClientDatas(user, e.value).then(res => {
            if(res != undefined && res.data != undefined && res.data.statut == "ok") {
    
                let optionsAnimaux = [];
                res.data.donnees.animaux.map((c, i) => {
                  optionsAnimaux.push({value: c.id, label: c.nom});
                }); 
                setListeAnimaux(optionsAnimaux);
                if(res.data.donnees.animaux.length > 0) { rdvVals['id_animal'] = res.data.donnees.animaux[0].id; }
            }
        });
        
        rdvVals['id_client'] = e.value;
        setEditedRdv(rdvVals);
    }


    const isDateValid = (date = "") => {        
        let parsedDate = new Date(date);    
        return parsedDate.toString() !== "Invalid Date"; 
    }


    const nouveauRdv = () => {
        setEditedRdv({});
        handleOpenRdv();
    }


    const changeEditedRdv = (id) => {
        listeDemandesRdv.map((r, i) => {            
            if(r.id == id) {
                let rdvVals = {...r}; 
                rdvVals['id_rdv'] = id;              
                getClientDatas(user, r.id_client).then(res => {
                    if(res != undefined && res.data != undefined && res.data.statut == "ok") {
                        let optionsAnimaux = [];
                        res.data.donnees.animaux.map((c, i) => {
                            optionsAnimaux.push({value: c.id, label: c.nom});
                        }); 
                        setListeAnimaux(optionsAnimaux);
                        if(res.data.donnees.animaux.length > 0) { 
                            let rdvVals = {...editedRdv};
                            if(res.data.donnees.animaux.length > 0) { rdvVals['id_animal'] = res.data.donnees.animaux[0].id; }                            
                        }
                    }
                });     
                rdvVals.ready = false;             
                setEditedRdv(rdvVals); 
                setOpenDrawer(false);
                setOpenRdv(true);
            }
        }); 
    }

    const updateRdv = (e) => {
        let rdvVals = {...editedRdv};
        rdvVals[e.target.id] = e.target.value;
        setEditedRdv(rdvVals);
    }
    const updateDateRdv = (e) => {
        let rdvVals = {...editedRdv};
        rdvVals['date_rdv'] = e['$y']+'-'+((e['$M']+1) < 10 ? '0' : '')+(e['$M']+1)+'-'+(e['$D'] < 10 ? '0' : '')+e['$D']+' '+(e['$H'] < 10 ? '0' : '')+e['$H']+':'+(e['$m'] < 10 ? '0' : '')+e['$m'];
        setEditedRdv(rdvVals);
    }
    const updateAnimalRdv = (e) => {
        let rdvVals = {...editedRdv};
        rdvVals['id_animal'] = e.value;
        setEditedRdv(rdvVals);
    }
    const updateTypePrestationRdv = (e) => {
      let rdvVals = {...editedRdv};
      rdvVals['type_prestation'] = e.value;
      setEditedRdv(rdvVals);
    }

    const saveRdv = () => {

        setAnimalError(null);
        setMotifError(null);
        setSymptomesError(null);

        let rdvVals = {...editedRdv};
        rdvVals['id_veterinaire'] = (user.is_veto ? user.id : null);

        if(!(rdvVals.id_animal && rdvVals.id_animal.length > 0)) { setAnimalError('Ce champs est obligatoire'); }
        if(!(rdvVals.motif_consultation && rdvVals.motif_consultation.length > 0)) { setMotifError('Ce champs est obligatoire'); }
        if(!(rdvVals.symptomes && rdvVals.symptomes.length > 0)) { setSymptomesError('Ce champs est obligatoire'); }
        
        let id_rdv = (rdvVals.id_rdv != undefined ? rdvVals.id_rdv : null);
        if(rdvVals.date_rdv != undefined && !isDateValid(rdvVals.date_rdv)) { rdvVals.date_rdv = null; }

        setRdv(user, id_rdv, rdvVals).then(res => {
            if(res != undefined && res.data != undefined && res.data.statut == "ok") {
                
                setOpenRdv(false); 
                setEditedRdv({});     

                let liste = [...listeRdv];
                let liste_complete = [...cabRdv];
                let liste_demandes = [...listeDemandesRdv];
                    
                if(id_rdv != null) {

                    if(rdvVals.date_rdv && rdvVals.date_rdv.length > 0 && isDateValid(rdvVals.date_rdv)) {

                        let found = false;
                        liste.map((r, i) => {   
                            if(r.resourceId == id_rdv) {
                                liste[i] = {
                                    title: res.data.client,
                                    start: rdvVals.date_rdv.replace(' ', 'T'),
                                    resourceId: id_rdv
                                }
                                found = true;
                            }
                        });
                        if(!found) {
                            liste.push({
                                title: res.data.client,
                                start: rdvVals.date_rdv.replace(' ', 'T'),
                                resourceId: id_rdv
                            });
                        }

                        found = false;
                        liste_complete.map((r, i) => {   
                            if(r.id == id_rdv) {
                                liste_complete[i] = rdvVals;
                                found = true;
                            }
                        });
                        if(!found) {
                            liste_demandes.map((r, i) => {   
                                if(r.id == id_rdv) {
                                    liste_demandes.splice(i, 1);
                                    liste_complete.push(rdvVals);
                                }
                            });                                                       
                        }  
                    }
                    else {

                        liste.map((r, i) => {   
                            if(r.resourceId == id_rdv) {
                                liste.splice(i, 1);
                            }
                        });

                        let found = false;
                        liste_demandes.map((r, i) => {   
                            if(r.id == id_rdv) {
                                liste_demandes[i] = rdvVals;
                                found = true;
                            }
                        });
                        if(!found) {
                            liste_complete.map((r, i) => {   
                                if(r.id == id_rdv) {
                                    liste_complete.splice(i, 1);
                                    liste_demandes.push(rdvVals);
                                }
                            });                          
                        }
                    }                     
                }
                else {
                    if(rdvVals.date_rdv && rdvVals.date_rdv.length > 0 && isDateValid(rdvVals.date_rdv)) {
                        liste.push({
                            title: res.data.client,
                            start: rdvVals.date_rdv.replace(' ', 'T'),
                            resourceId: res.data.id_rdv
                        });
                        liste_complete.push(rdvVals);
                    }
                    else {
                        liste_demandes.push(rdvVals);
                    }
                }
        
                setListeRdv(liste);   
                setCabRdv(liste_complete);                    
                setListeDemandesRdv(liste_demandes);        
            }
        });
    }

    const renderEventContent = (e) => {

        let ajd = new Date;
        let rdv_id = e.event._def.extendedProps.resourceId;      
        cabRdv.map((r, i) => {   
            if(r.id == rdv_id) {
                let rdvVals = {...r}; 
                rdvVals['id_rdv'] = rdv_id;             
                getClientDatas(user, r.id_client).then(res => {
                    if(res != undefined && res.data != undefined && res.data.statut == "ok") {
                        let optionsAnimaux = [];
                        res.data.donnees.animaux.map((c, i) => {
                            optionsAnimaux.push({value: c.id, label: c.nom});
                        }); 
                        setListeAnimaux(optionsAnimaux);
                        if(res.data.donnees.animaux.length > 0) { 
                            let rdvVals = {...editedRdv};
                            if(res.data.donnees.animaux.length > 0) { rdvVals['id_animal'] = res.data.donnees.animaux[0].id; }                            
                        }
                    }
                });                 
                
                let date_rdv = new Date(rdvVals['date_rdv']);

                if(date_rdv.getFullYear() == ajd.getFullYear() && date_rdv.getMonth() == ajd.getMonth() && date_rdv.getDate() == ajd.getDate()) {
                    rdvVals.ready = true;
                }
                else {
                    rdvVals.ready = false;
                }

                setEditedRdv(rdvVals); 
                setOpenRdv(true);
            }
        }); 
    }


    const appel = (id_rdv) => {
        setLoadingCall(true);
        demarreRdv(user, id_rdv).then(res => {
            if(res != undefined && res.data != undefined && res.data.statut == "ok") {
                window.open(res.data.lien_rdv);
                setLoadingCall(false);
            }
        });
    }


    const listeTypesPrestations = [];
    typesPrestations.map((t, i) => {
        listeTypesPrestations.push({ value: t.id, label: t.nom });
    });


    if(!user) { return <Redirect to={"/login"} /> }
    if(user.is_veto) {
        return(
            <div className="fullpage">
            <Container className='full_container'>
                
                <UserWidget /> 
                {user.is_veto && <SideMenu user={user} setUser={setUser}/>}

                <Row className={user.is_veto ? (user.menuClosed ? 'withSidemenu-closed' : 'withSidemenu') : ['w-100',' ps-4'].join(" ")}>
                    <Col xs={12} className={['ps-5','pt-5'].join(" ")}>

                        <Row className="align-items-start">
                            <Col md={4}>
                                <h2>Agenda</h2>
                                <hr width="50%" className="black_line"></hr>
                            </Col>
                            <Col md={7} className="d-flex">
                                <Card className={['mx-2','p-2'].join(" ")}>
                                    <IconButton aria-label="retour" onClick={nouveauRdv}>
                                        <EventOutlinedIcon/>
                                    </IconButton>
                                </Card>
                            </Col>
                        </Row>                
                        <Row className={['align-items-center','mt-4'].join(" ")}>
                            <Col md={11} className="pb-5">        
                                <FullCalendar id="fullcalendar"
                                    plugins={[ dayGridPlugin ]}
                                    initialView="dayGridMonth"
                                    headerToolbar={{start:'prev', center:'title', end:'next'}}
                                    locale='fr'
                                    fixedWeekCount={false}
                                    hiddenDays={[0]}
                                    aspectRatio={1.8}
                                    eventClick={renderEventContent}
                                    events={listeRdv}
                                />                    
                            </Col>
                            <Col md={1} className={['d-flex','justify-content-center'].join(" ")}> 

                                <Badge badgeContent={listeDemandesRdv.length} color="primary">
                                <Card className={['mx-2','p-2'].join(" ")}>
                                    <IconButton aria-label="retour" onClick={() => {setOpenDrawer(true)}}>
                                        <MenuIcon/>
                                    </IconButton>                                
                                </Card>
                                </Badge>
                            
                                <SwipeableDrawer
                                    anchor='right'
                                    open={openDrawer}
                                    onClose={() => {setOpenDrawer(false)}}
                                >
                                <div className="p-4">
                                    <p className={['bigText','bold'].join(" ")}>Demandes de<br></br>consultation</p>
                                    <hr width="75%" className="black_line"></hr>
                                </div>  
                                <div>
                                {listeDemandesRdv.length > 0 ? listeDemandesRdv
                                    .map((r, i) => {
                                    return (<div key={r.id}> 
                                        <Button variant="text" className="listButton" data-id={r.id} onClick={() => {changeEditedRdv(r.id)}}>
                                            <p>{r.client}</p>
                                            <p className='smallText'>{r.motif_consultation}</p>
                                        </Button>
                                    </div>)
                                    }) : (<div className='px-4'><p className='smallText'>Aucune demande pour le moment</p></div>)
                                }
                                </div>                              
                                </SwipeableDrawer>                       
                                
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openRdv}
                onClose={handleCloseRdv}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: { timeout: 500 },
                }}
            >
                <Fade in={openRdv}>
                    <Box>
                        <p className={['titre','bold','mb-2'].join(" ")}>Rendez-vous</p>

                        <IconButton aria-label="modifier" className="modalExit" onClick={handleCloseRdv}>
                            <CloseIcon/>
                        </IconButton>

                        <Row className="align-items-start">
                            <Col md={6} className='pe-4'>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateTimePicker
                                    id="date_rdv" 
                                    label="Date"
                                    value={editedRdv.date_rdv ? dayjs(editedRdv.date_rdv) : ''}
                                    onChange={updateDateRdv}
                                    ampm={false}
                                    format="DD/MM/YYYY HH:mm"
                                    className="mt-3 mb-1 w-100"
                                />
                                </LocalizationProvider>
                                {user.is_veto ? (
                                    <FormControl variant="standard" sx={{ width: '100%' }} className='mt-2'>
                                        <InputLabel id="rdv_animal">Client</InputLabel>
                                        <Select
                                            labelId="rdv_animal"
                                            id="id_client"
                                            value={editedRdv.id_client ? listeClients.filter((c) => c.value == editedRdv.id_client) : ''}
                                            onChange={updatelisteAnimaux}
                                            label="Client"
                                            options={listeClients}
                                        >   
                                        </Select>
                                    </FormControl>
                                ) : (<div></div>)}
                                <FormControl variant="standard" sx={{ width: '100%' }} className='mt-2' error={!!animalError}>
                                    <InputLabel id="rdv_animal">Animal</InputLabel>
                                    <Select
                                        labelId="rdv_animal"
                                        id="id_animal"
                                        value={editedRdv.id_animal ? listeAnimaux.filter((c) => c.value == editedRdv.id_animal) : ''}
                                        onChange={updateAnimalRdv}
                                        label="Animal"
                                        options={listeAnimaux}
                                    >
                                    </Select>
                                </FormControl>
                                <FormControl variant="standard" sx={{ width: '100%' }} className='mt-2'>
                                    <Select
                                    id="type_prestation"
                                    value={editedRdv.type_prestation ? listeTypesPrestations.filter((c) => c.value == editedRdv.type_prestation) : ''}
                                    onChange={updateTypePrestationRdv}
                                    placeholder="Type de prestation"
                                    options={listeTypesPrestations}
                                    >                      
                                    </Select>
                                </FormControl>
                            </Col>
                            <Col md={6} className={['borderLeft','ps-4'].join(" ")}>
                                <TextField 
                                    id="motif_consultation" 
                                    label="Motif de consultation" 
                                    variant="standard" 
                                    value={editedRdv.motif_consultation ? editedRdv.motif_consultation : ''}
                                    onChange={updateRdv}
                                    className="mb-1 w-100"
                                    error={!!motifError}
                                    helperText={motifError}
                                />
                                <TextField 
                                    id="symptomes" 
                                    label="Symptômes" 
                                    variant="standard" 
                                    value={editedRdv.symptomes ? editedRdv.symptomes : ''}
                                    onChange={updateRdv}
                                    className="mt-2 mb-1 w-100"
                                    error={!!symptomesError}
                                    helperText={symptomesError}
                                />
                                <TextField 
                                    id="duree_symptomes" 
                                    label="Durée des symptômes" 
                                    variant="standard" 
                                    value={editedRdv.duree_symptomes ? editedRdv.duree_symptomes : ''}
                                    onChange={updateRdv}
                                    className="mt-2 mb-1 w-100"
                                />
                                <TextField 
                                    id="antecedents" 
                                    label="Antécédents" 
                                    variant="standard" 
                                    value={editedRdv.antecedents ? editedRdv.antecedents : ''}
                                    onChange={updateRdv}
                                    className="mt-2 mb-1 w-100"
                                />
                            </Col>
                        </Row>                    
                        
                        <Row>
                            <Col md={6}>
                                <Button variant="contained" className={['buttonSecondary','mt-5'].join(" ")} type="submit" startIcon={<CheckIcon />} onClick={saveRdv}>Sauvegarder</Button>
                            </Col>
                            <Col md={6} className={['d-flex','justify-content-end'].join(" ")}>
                                <Button variant="contained" className={(editedRdv.ready ? ['buttonSecondary','mt-5'] : ['mt-5']).join(" ")} startIcon={(loadingCall ? <CircularProgress/> : <LocalPhoneIcon />)} onClick={() => appel(editedRdv.id_rdv)} disabled={!editedRdv.ready}>Appeler</Button>
                            </Col>
                        </Row>                        
                    </Box>
                </Fade>
            </Modal>
            </div>
        );
    }
    else {
        return <Redirect to={"/client/"+user.id} />
    }
}

export default Agenda;