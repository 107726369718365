import React,{ Component }  from 'react'; 
import {Button} from '@mui/material';
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import PeopleOutlineOutlinedIcon from '@mui/icons-material/PeopleOutlineOutlined';
import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import DriveFileMoveOutlinedIcon from '@mui/icons-material/DriveFileMoveOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../App/App.css';

import {useCredentials} from '../../Services/authServices';


class SideMenu extends Component { 

    constructor(props) { 
        super(props);  
    }

    toggleSideMenu = () => {
        let menuClosed = (this.props.user.menuClosed != undefined ? this.props.user.menuClosed : false);
        var _user = {...this.props.user};
        _user.menuClosed = !menuClosed;
        this.props.setUser(_user);
    }

    render() { 
        return(
            <div id="sidemenu" className={['d-flex','align-items-center', (this.props.user.menuClosed ? 'closed' : '')].join(" ")}>
                <ul>
                    <li><Button variant="text" startIcon={<GridViewOutlinedIcon />} onClick={() => window.open('/','_self')}>
                        {!this.props.user.menuClosed && <p className='ms-2'>Cabinet</p>}
                    </Button></li>
                    <li><Button variant="text" startIcon={<PeopleOutlineOutlinedIcon />} onClick={() => window.open('/clients','_self')}>
                        {!this.props.user.menuClosed && <p className='ms-2'>Clients</p>}
                    </Button></li>
                    <li><Button variant="text" startIcon={<EventOutlinedIcon />} onClick={() => window.open('/agenda','_self')}>
                        {!this.props.user.menuClosed && <p className='ms-2'>Agenda</p>}
                    </Button></li>                    
                    {this.props.user.licence && this.props.user.licence.avec_cas_referes == true && <li>
                            <Button variant="text" startIcon={<DriveFileMoveOutlinedIcon />} onClick={() => window.open('/cas_referes','_self')}>
                                {!this.props.user.menuClosed && <p className='ms-2'>Cas référés</p>}
                            </Button>
                    </li>}
                    <li><Button variant="text" startIcon={<DescriptionOutlinedIcon />} onClick={() => window.open('/factures','_self')}>
                        {!this.props.user.menuClosed && <p className='ms-2'>Factures</p>}
                    </Button></li>
                    <li><Button variant="text" startIcon={<MailOutlinedIcon />} onClick={() => window.open('/messagerie','_self')}>
                        {!this.props.user.menuClosed && <p className='ms-2'>Messagerie</p>}
                    </Button></li>
                    <li><Button variant="text" startIcon={<SettingsIcon />} onClick={() => window.open('/parametres','_self')}>
                        {!this.props.user.menuClosed && <p className='ms-2'>Paramètres</p>}
                    </Button></li>
                </ul>
                <div className='toggleButton'>
                    <IconButton aria-label="supprimer" className="textWhite" onClick={() => this.toggleSideMenu()}>
                        {this.props.user.menuClosed ? <ChevronRightOutlinedIcon /> : <ChevronLeftOutlinedIcon />}
                    </IconButton>
                </div>
            </div>
        );
    }
}

export default SideMenu;