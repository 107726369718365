import axios from "axios";

export async function getDocuments(user, id_animal) {
    
    return axios.post(process.env.REACT_APP_API_URL+'documents/get', {
        token: user.sessionToken,
        id_animal: id_animal,
    }, {
        headers: { 'Content-Type': 'multipart/form-data' }
    })
    .catch(function (error) {
        console.log(error.toJSON());
    });
}

export async function uploadDocument(user, doc, format, nom, id_animal, type, sous_type, ids_rdvs) {
    
    return axios.post(process.env.REACT_APP_API_URL+'document/upload', {
        token: user.sessionToken,
        doc: doc,
        format: format,
        nom: nom,
        id_animal: id_animal,
        type: type,
        sous_type: sous_type,
        ids_rdvs: ids_rdvs,
    }, {
        headers: { 'Content-Type': 'multipart/form-data' }
    })
    .catch(function (error) {
        console.log(error.toJSON());
    });
}

export async function downloadDocument(user, id_document) {
    
    return axios.post(process.env.REACT_APP_API_URL+'document/download', {
        token: user.sessionToken,
        id_document: id_document,
    }, {
        headers: { 'Content-Type': 'multipart/form-data' }
    })
    .catch(function (error) {
        console.log(error.toJSON());
    });
}

export async function supprDocument(user, id_document) {
    
    return axios.post(process.env.REACT_APP_API_URL+'document/delete', {
        token: user.sessionToken,
        id_document: id_document,
    }, {
        headers: { 'Content-Type': 'multipart/form-data' }
    })
    .catch(function (error) {
        console.log(error.toJSON());
    });
}



export async function uploadPhoto(photo) {
    
    return axios.post(process.env.REACT_APP_API_URL+'photo/upload', {
        token: process.env.REACT_APP_TOKEN,
        photo: photo,
    }, {
        headers: { 'Content-Type': 'multipart/form-data' }
    })
    .catch(function (error) {
        console.log(error.toJSON());
    });
}