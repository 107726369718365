import { React, useEffect, useState, useReducer } from 'react';
import { Redirect } from "react-router-dom";
import UserWidget from '../Dashboard/UserWidget';
import SideMenu from '../Dashboard/SideMenu';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import SearchIcon from '@mui/icons-material/Search';
import EditNoteIcon from '@mui/icons-material/EditNote';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import AddCardIcon from '@mui/icons-material/AddCard';
import DriveFileMoveOutlinedIcon from '@mui/icons-material/DriveFileMoveOutlined';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from '@mui/material/Card';
import swal from 'sweetalert';
import { Skeleton } from '@mui/material';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../App/App.css';

import {useCredentials} from '../../Services/authServices';
import {getCabClients} from '../../Services/cabinetServices'; 
import {deleteClient} from '../../Services/UserServices'; 

function IndexClients() {

  const { user, setUser } = useCredentials();
  const queryParams = new URLSearchParams(window.location.search);
  const [listeClientsTot, setListeClientsTot] = useState(); 
  const [numPage, setNumPage] = useState(0);
  const [nbPages, setNbPages] = useState(0);
  const nbPerPage = 5; 
  const [filtre, setFiltre] = useState();
  
  useEffect(() => {

    const queryPage = queryParams.get('page');
    if(queryPage != null) setNumPage(queryPage);

    getCabClients(user).then(res => {
        if(res != undefined && res.data != undefined && res.data.statut == "ok") {     
          
          var liste_clients = [];
          res.data.clients.map((c, i) => {
            c.inPage = false;
            if(i >= numPage*nbPerPage && i < (numPage+1)*nbPerPage) { c.inPage = true; }
            liste_clients.push(c);
          });
          setListeClientsTot(res.data.clients); 
          setNbPages(Math.trunc(res.data.clients.length/nbPerPage)+1);
        }
    });
  }, [user]);


  const supprClient = (id_client) => {
    swal({
        title: "Êtes-vous sûr(e) ?",
        text: "Cette action supprimera le patient avec ses animaux, ses messages et ses rendez-vous.",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
      .then((willDelete) => {
        if(willDelete) {
            deleteClient(user, id_client).then(res => {
                if(res != undefined && res.data != undefined && res.data.statut == "ok") {
                  var liste_clients = [];
                  listeClientsTot.map((c, i) => {
                    if(c.id != id_client) {
                      c.inPage = false;
                      if(i >= (numPage-1)*nbPerPage && i < (numPage)*nbPerPage) { c.inPage = true; }
                      liste_clients.push(c); 
                    }
                  });
                  setListeClientsTot(liste_clients);
                }
            });
        }
    });        
  }


  const nextPage = () => {    
    var liste_clients = [];
    listeClientsTot.map((c, i) => {
      c.inPage = false;
      if(i >= (numPage+1)*nbPerPage && i < (numPage+2)*nbPerPage) { c.inPage = true; }
      liste_clients.push(c); 
    });
    setListeClientsTot(liste_clients);
    setNumPage((numPage+1));
  }

  const prevPage = () => {
    if(numPage > 0) {      
      var liste_clients = [];
      listeClientsTot.map((c, i) => {
        c.inPage = false;
        if(i >= (numPage-1)*nbPerPage && i < (numPage)*nbPerPage) { c.inPage = true; }
        liste_clients.push(c); 
      });
      setListeClientsTot(liste_clients);
      setNumPage((numPage-1));
    }
  }

  const updateFiltre = (e) => {
    if(e.target.value.length == 0 || e.target.value.length > 2) setFiltre(e.target.value);
  }

  if(user.is_veto) {
    return (
      <div className="fullpage">
        <Container className={['full_container','clients_page'].join(" ")}>
          
          <UserWidget /> 
          <SideMenu user={user} setUser={setUser}/>
          
          <Row className={user.is_veto ? (user.menuClosed ? 'withSidemenu-closed' : 'withSidemenu') : ""}>
            <Col md={12} className={['ps-5','py-5','hmax-100'].join(" ")}>

              <Row>
                <Col md={3}>
                  <h2>
                    {listeClientsTot && listeClientsTot.length} Clients
                    <IconButton aria-label="supprimer" className={['mb-1','ms-3'].join(" ")} onClick={() => window.open('/client/new', '_self')}>
                      <AddBoxOutlinedIcon />
                    </IconButton>
                  </h2>
                  <hr width="80%" className="black_line"></hr>
                </Col>
                <Col md={4}>
                  <div className={['searchBar','mb-2','mx-3'].join(" ")}>
                    <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                        <SearchIcon />
                    </IconButton>
                    <InputBase placeholder="Rechercher..." className='w-100' onChange={updateFiltre}/>
                  </div>
                </Col>
              </Row>

              <Row className='mt-4'>
                  <Col md={12} className={['custom-scrollbar','h-100'].join(" ")}>                    
                      {listeClientsTot ? listeClientsTot.map((p, i) => {	
                        var match = (filtre ? 
                          (p.nom.toLowerCase().indexOf(filtre.toLowerCase()) > -1 
                          || p.prenom.toLowerCase().indexOf(filtre.toLowerCase()) > -1)
                          : false);
                          if((!filtre && p.inPage) || (filtre && match)) {
                            var mt = (i > 0 ? "mt-3" : "mt-1");
                            return (<div className = {mt}>
                                <Card className={['px-4','py-3','mt-3','w-50'].join(" ")}>
                                    <Row className="align-items-center">
                                        <Col md={3}>
                                            <p className="bigText">{p.nom && p.nom.toUpperCase()}</p>
                                            <p className="smallText">{p.prenom}</p>
                                        </Col>
                                        <Col md={3} className={['borderLeft','ps-4'].join(" ")}>
                                            <p className="bigText">{p.animaux.length}</p>
                                            <p className="smallText">{p.animaux.length > 1 ? "animaux" : "animal"}</p>
                                        </Col>
                                        <Col md={3}>
                                          {p.refere == true && <span title="Cas référé"><DriveFileMoveOutlinedIcon className={['text-muted','me-3'].join(" ")}/></span>}
                                          {p.id_gocardless ? <span title="Mandat en place"><CreditScoreIcon className='textSuccess'/></span> : <span title="Mandat en attente"><AddCardIcon className='textWarning'/></span>}
                                        </Col>
                                        <Col md={3} className="textRight">
                                            <IconButton aria-label="message" className="mr-2" onClick={() => window.open('/messagerie?destinataire='+p.id, '_self')}>
                                                <ChatOutlinedIcon />
                                            </IconButton>
                                            <IconButton aria-label="modifier" className="mr-2" onClick={() => window.open('/client/'+p.id, '_self')}>
                                                <EditNoteIcon />
                                            </IconButton>
                                            <IconButton aria-label="supprimer" className="textRed" onClick={() => supprClient(p.id)}>
                                                <DeleteOutlineIcon />
                                            </IconButton>
                                        </Col>
                                    </Row>
                                </Card>
                            </div>)
                          }
                        }) : (<div>
                          <Skeleton variant="rounded" height={80} className={['mt-3','w-50'].join(" ")}/>
                          <Skeleton variant="rounded" height={80} className={['mt-3','w-50'].join(" ")}/>
                          <Skeleton variant="rounded" height={80} className={['mt-3','w-50'].join(" ")}/>
                          <Skeleton variant="rounded" height={80} className={['mt-3','w-50'].join(" ")}/>
                        </div>)
                      } 

                      <div className='mt-2'>
                        {numPage > 0 && <IconButton aria-label="appel" className="mr-2" onClick={prevPage}>
                            <KeyboardArrowLeftIcon />
                        </IconButton>} 
                        {numPage+1} / {nbPages}
                        {listeClientsTot && (numPage+1)*nbPerPage < listeClientsTot.length && <IconButton aria-label="message" className="mr-2" onClick={nextPage}>
                            <KeyboardArrowRightIcon />
                        </IconButton>}                                      
                      </div>
                  </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>);
  } else {
    return <Redirect to={"/client/"+user.id} />
  }
}

export default IndexClients;