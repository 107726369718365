import {React, useEffect, useState, useReducer} from 'react';
import { useParams } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Skeleton, TextField, Button} from '@mui/material';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Select from 'react-select'
import IconButton from '@mui/material/IconButton';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';
import DriveFileMoveOutlinedIcon from '@mui/icons-material/DriveFileMoveOutlined';
import Switch from '@mui/material/Switch';
import swal from 'sweetalert';
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { Table, Column, HeaderCell, Cell } from 'rsuite-table';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../App/App.css';
import "rsuite-table/dist/css/rsuite-table.css";

import {useCredentials} from '../../Services/authServices';
import {getAnimalRdvs} from '../../Services/animalServices';
import {getCabVeterinaires} from '../../Services/cabinetServices'; 
import {getDocuments, uploadDocument, downloadDocument, supprDocument} from '../../Services/FileServices';
import {setRdv} from '../../Services/RDVServices';
import {getTypesPrestations} from '../../Services/prestationsServices';


function b64toBlob(b64Data, contentType='', sliceSize=512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
  
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
  
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
  
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
      
    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
}


function Consultations(props) {

    const { id } = useParams(); 
    const { user } = useCredentials();
    const [listeDocuments, setListeDocuments] = useState();
    const [veterinaires, setVeterinaires] = useState([]);    
    
    const [dateDebut, setDateDebut] = useState();
    const [dateFin, setDateFin] = useState();
    const [listeRdvs, setListeRdvs] = useState();
    const [enCours, setEnCours] = useState(true);    
    const [rdvNeedSave, setRdvNeedSave] = useState([]);
    const type_documents = ['lettre_refere','formulaire_antecedents','resultats','compte_rendu','ordonnance','autre'];
    const [listeSousTypesDocument, setListeSousTypesDocument] = useState([]);
    const sous_types_resultats = ['Biochimie', 'Hématologie', 'Analyse Urinaires', 'Radiographie', 'Echographie', 'Scanner', 'IRM', 'Scintigraphie', 'Cytopathologie', 'Histopathologie', 'Autres analyses'];
    const sous_types_cr = ['Médecine interne', 'Oncologie', 'Cardiologie', 'Chirurgie', 'Imagerie', 'Dermatologie', 'Neurologie', 'Reproduction', 'Anesthesie', 'Urgences & soins intensifs', 'Comportement'];

    const [expanded, setExpanded] = useState(false);
    const [typesPrestations, setTypesPrestations] = useState([]);

    const [open, setOpen] = useState(false);
    const [editedDoc, setEditedDoc] = useState({}); 
    const [nomError, setNomError] = useState();
    const [typeDocError, setTypeDocError] = useState();
    const [fileError, setFileError] = useState();
    const [consultError, setConsultError] = useState();
    const [loading, setLoading] = useState(false);
    const handleClose = () => setOpen(false);  
    
    const [sortColumn, setSortColumn] = useState();
    const [sortType, setSortType] = useState();
    
    const [editedRdv, setEditedRdv] = useState({}); 
    const [motifError, setMotifError] = useState();
    const [symptomesError, setSymptomesError] = useState();

    const [pageWidth, setPageWidth] = useState(window.innerWidth);    
    const [, forceUpdate] = useReducer(o => !o);

    useEffect(() => {
        
        getDocuments(user, id).then(res => {
            if(res != undefined && res.data != undefined && res.data.statut == "ok") {                    
                setListeDocuments(res.data.donnees);
            }            
        });

        getAnimalRdvs(user, id).then(res => {
            if(res != undefined && res.data != undefined && res.data.statut == "ok") {    
                let liste_rdvs = [];
                let list_rdv_need_save = [];
                res.data.donnees.map((r, i) => {
                    r.cloture = (r.date_cloture != null ? 'oui' : 'non');
                    r.heures = (r.duree != null ? Math.trunc(r.duree/60) : 0);
                    r.minutes = (r.duree != null ? (r.duree - Math.trunc(r.duree/60)*60) : 0);
                    list_rdv_need_save[r.id] = false;
                    liste_rdvs.push(r);
                });
                setListeRdvs(liste_rdvs);
                setRdvNeedSave(list_rdv_need_save);
            }
        });

        getTypesPrestations(user.cabinet.id).then(res_t => {
            if(res_t != undefined && res_t.data != undefined && res_t.data.statut == "ok") {
            setTypesPrestations(res_t.data.donnees);
            }
        });

        getCabVeterinaires(user).then(res => {
            if(res != undefined && res.data != undefined && res.data.statut == "ok") {
                setVeterinaires(res.data.donnees);
            }
        });

        let ajd = new Date;
        setDateDebut(ajd.getFullYear()+'-'+(ajd.getMonth())+'-'+(ajd.getDate()+1));

        let fin = new Date;
        fin.setMonth(fin.getMonth() + 1);
        setDateFin(fin.getFullYear()+'-'+(fin.getMonth()+1)+'-'+(fin.getDate()+1));

        window.onresize = function() {
            setPageWidth(window.innerWidth);
        }

    }, []);

    const toggleEnCours = () => {
        setEnCours(!enCours);
    }
    const updateDateDebut = (e) => {
        setDateDebut(e != null ? e['$y']+'-'+(e['$M']+1)+'-'+e['$D'] : null);
    } 
    const updateDateFin = (e) => {
        setDateFin(e != null ? e['$y']+'-'+(e['$M']+1)+'-'+e['$D'] : null);
    }   

    const handleChangeAccordion = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
      
      listeRdvs.map((rdv, i) => {
        if(rdv.id == panel.replace('rdv_','')) { setEditedRdv(rdv); }
      });
    };

    const libelleType = (type_doc) => {

        let nom = '';
        switch (type_doc) {
            case 'lettre_refere': nom = 'Lettre(s) de référés'; break;
            case 'formulaire_antecedents': nom = 'Formulaire(s) d\'antécédents'; break;
            case 'resultats': nom = 'Résultats de laboratoire'; break;
            case 'compte_rendu': nom = 'Compte-rendu(s) de spécialiste(s)'; break;
            case 'ordonnance': nom = 'Ordonnance(s)'; break;
            case 'autre': nom = 'Autres documents'; break;
        }
        return nom;
    }


    const updateDoc = (e) => {
        let docVals = {...editedDoc};
        docVals[e.target.id] = e.target.value;
        setEditedDoc(docVals);
    }    
    const updateTypeDocument = (type) => {
        let docVals = {...editedDoc};
        docVals['type_document'] = type;
        setEditedDoc(docVals);

        let liste = [];
        if(type == 'resultats') {
            sous_types_resultats.map((t, i) => {
                liste.push({ value: t, label: t});
            });
        }
        else if(type == 'compte_rendu') {
            sous_types_cr.map((t, i) => {
                liste.push({ value: t, label: t});
            });
        }
        setListeSousTypesDocument(liste);
    }  
    const updateSousTypeDocument = (e) => {
        let docVals = {...editedDoc};
        docVals['sous_type_document'] = e.value;
        setEditedDoc(docVals);
    }
    const updateRdvs = (selected) => {        
        let tab = []
        let docVals = {...editedDoc};
        if(selected) {
            selected.map((rdv, i) => { tab.push(rdv.value); });
        }
        docVals['ids_rdvs'] = tab.join(',');
        setEditedDoc(docVals);
    }

    const handleFileChange = (e) => {

        if (e.target.files) {

            const file = e.target.files[0];
            const allowed_formats = ['application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'image/jpeg', 'image/png', 'application/pdf'];

            if(file.size > 5000000) { setFileError('L\'image est trop lourde'); }
            else if(allowed_formats.indexOf(file.type) == -1) { setFileError('Les formats autorisés sont pdf, docx, jpg et png'); }
            else {
                setFileError(null);
                let docVals = {...editedDoc};
                docVals['doc'] = file;
                docVals['format'] = file.type;
                docVals['nom_fichier'] = file.name;
                setEditedDoc(docVals);            
            }            
        }
    }

    const importDoc = () => {

        setNomError(null);
        setTypeDocError(null);
        setFileError(null); 
        setConsultError(null);

        let nb_errors = 0;
        if(!editedDoc.nom || editedDoc.nom.length == 0) { setNomError('Ce champ est obligatoire'); nb_errors++; }
        if(!editedDoc.type_document || editedDoc.type_document.length == 0) { setTypeDocError('Ce champ est obligatoire'); nb_errors++; }
        if(!editedDoc.doc) { setFileError('Ce champ est obligatoire'); nb_errors++;}
        if(!editedDoc.ids_rdvs || editedDoc.ids_rdvs.length == 0) { setConsultError('Ce champ est obligatoire'); nb_errors++; }

        if(nb_errors == 0) {
            setLoading(true);
            uploadDocument(user, editedDoc.doc, editedDoc.format, editedDoc.nom, id, editedDoc.type_document, editedDoc.sous_type_document, editedDoc.ids_rdvs).then((res) => {
                if(res != undefined && res.data != undefined && res.data.statut == "ok") {

                    let liste_docs = [...listeDocuments];

                    let ajd = new Date;
                    let docVals = {...editedDoc};
                    docVals.id = res.data.id_document;
                    docVals.date_ajout = (ajd.getDate() < 10 ? '0' : '')+ajd.getDate()+'/'+((ajd.getMonth()+1) < 10 ? '0' : '')+(ajd.getMonth()+1)+'/'+ajd.getFullYear();
                    docVals.statut = 'nouveau';

                    let consultations = [];
                    editedDoc.ids_rdvs.split(',').map((rdv, i) => { consultations.push({id: parseInt(rdv)}); });
                    docVals.consultations = consultations;

                    liste_docs.push(docVals);
                    setListeDocuments(liste_docs);                 
                    setOpen(false);
                }
                setLoading(false);
            }); 
        }  
    }

    const download = (id_document, methode = 'download') => {
        downloadDocument(user, id_document).then((res) => {
            if(res != undefined && res.data != undefined && res.data.statut == "ok") {
                const blob = b64toBlob(res.data.donnees, res.data.format);
                const blobUrl = URL.createObjectURL(blob);

                if(methode == 'apercu') { window.open(blobUrl); }
                else {
                    var link = document.createElement("a");
                    link.href = blobUrl;
                    link.download = res.data.nom;
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                }
            }
        });
    };    

    const supprDoc = (id_document) => {
        swal({
            title: "Êtes-vous sûr(e) ?",
            text: "Cette action supprimera définitivement le document.",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          })
          .then((willDelete) => {
            if(willDelete) {
                supprDocument(user, id_document).then((res) => {
                    if(res != undefined && res.data != undefined && res.data.statut == "ok") {

                        let liste_docs = [];
                        Object.entries(listeDocuments).forEach(([d, i]) => {
                            if(d.id != id_document) {
                                liste_docs.push(d);
                            }
                        });
                        setListeDocuments(liste_docs);                           
                        forceUpdate();
                    }
                });  
            }  
        });
    }


    const handleSortColumn = (sortColumn, sortType) => {
        setSortColumn(sortColumn);
        setSortType(sortType);
    };

    const dateFrToTime = (d) => {
        let _d = d.split('/');
        let d_obj = new Date(_d[2]+'-'+_d[1]+'-'+_d[0]);
        return d_obj.getTime();
    }; 

    const listeTriee = (id_rdv, sortColumn, sortType = 'desc') => {
        if(listeDocuments) {

            let liste = [...listeDocuments];
            let liste_filtree = [];
            liste.map((rdv, i) => {
                rdv.consultations.map((c, j) => { if(c.id == id_rdv) liste_filtree.push(rdv); });
            });

            return liste_filtree.sort((a, b) => {

                let x = a[sortColumn];
                let y = b[sortColumn];
                
                if(sortColumn == 'date_ajout') { 
                    x = dateFrToTime(x);
                    y = dateFrToTime(y);
                    return (sortType === 'asc' ? x - y : y - x);
                }
                else if(typeof x == 'string' && typeof y == 'string') {
                    if (typeof x != 'string') {x = x.toString();}
                    if (typeof y != 'string') {y = y.toString();}
                    return "a5b".localeCompare("a21b", undefined, { numeric: true }) * (sortType === 'asc' ? -1 : 1);
                }
                else {
                    return (sortType === 'asc' ? x - y : y - x);
                }
            });
        }
        else return [];
    }



    const handleRdvNeedSave = (rdv_id, val) => {
        let list_rdv_need_save = [...rdvNeedSave];
        listeRdvs.map((r, i) => {
            if(r.id == rdv_id) list_rdv_need_save[r.id] = val;
        });
        setRdvNeedSave(list_rdv_need_save);
    }

    const updateRdv = (e) => {
        let rdvVals = {...editedRdv};
        rdvVals[e.target.id] = e.target.value;
        setEditedRdv(rdvVals);
        handleRdvNeedSave(editedRdv.id, true);
    }
    const updateDateCrationRdv = (e) => {
        let rdvVals = {...editedRdv};
        rdvVals['date_creation'] = e['$y']+'-'+((e['$M']+1) < 10 ? '0' : '')+(e['$M']+1)+'-'+(e['$D'] < 10 ? '0' : '')+e['$D']+' '+(e['$H'] < 10 ? '0' : '')+e['$H']+':'+(e['$m'] < 10 ? '0' : '')+e['$m'];
        setEditedRdv(rdvVals);
        handleRdvNeedSave(editedRdv.id, true);
    }
    const updateDateRdv = (e) => {
        let rdvVals = {...editedRdv};
        rdvVals['date_rdv'] = e['$y']+'-'+((e['$M']+1) < 10 ? '0' : '')+(e['$M']+1)+'-'+(e['$D'] < 10 ? '0' : '')+e['$D']+' '+(e['$H'] < 10 ? '0' : '')+e['$H']+':'+(e['$m'] < 10 ? '0' : '')+e['$m'];
        setEditedRdv(rdvVals);
        handleRdvNeedSave(editedRdv.id, true);
    }
    const updateVeterinaireRdv = (e) => {
        let rdvVals = {...editedRdv};
        rdvVals['id_veterinaire'] = e.value;
        setEditedRdv(rdvVals);
        handleRdvNeedSave(editedRdv.id, true);
    }  
    const updateSpecialisteRdv = (e) => {
        let rdvVals = {...editedRdv};
        rdvVals['id_specialiste'] = e.value;
        setEditedRdv(rdvVals);
        handleRdvNeedSave(editedRdv.id, true);
    }   
    const updateTypePrestationRdv = (e) => {
        let rdvVals = {...editedRdv};
        rdvVals['type_prestation'] = e.value;
        setEditedRdv(rdvVals);
        handleRdvNeedSave(editedRdv.id, true);
    }
    const updateRdvTemps = (type, val) => {
        let rdvVals = {...editedRdv};
        rdvVals[type] = val;
        rdvVals.duree = (parseInt(rdvVals.heures*60) + parseInt(rdvVals.minutes));
        setEditedRdv(rdvVals);
        handleRdvNeedSave(editedRdv.id, true);
    }
    const updateClotureRdv = (e) => {
        let rdvVals = {...editedRdv};
        rdvVals['cloture'] = e.target.value;
        if(e.target.value == "non") { rdvVals['date_cloture'] = null; }
        setEditedRdv(rdvVals);
        handleRdvNeedSave(editedRdv.id, true);
    }
    const updateDateClotureRdv = (e) => {
        let rdvVals = {...editedRdv};
        rdvVals['date_cloture'] = e['$y']+'-'+(e['$M']+1)+'-'+e['$D'];
        setEditedRdv(rdvVals);
        handleRdvNeedSave(editedRdv.id, true);
    }
    const updateFactureRdv = (e) => {
        let rdvVals = {...editedRdv};
        rdvVals['facture_a'] = e.value;
        setEditedRdv(rdvVals);
        handleRdvNeedSave(editedRdv.id, true);
    }

    const isDateValid = (date = "") => {        
        let parsedDate = new Date(date);    
        return parsedDate.toString() !== "Invalid Date"; 
    }

    const saveRdv = (id_rdv) => {

        setMotifError(null);
        setSymptomesError(null);

        let rdvVals = {...editedRdv};
        rdvVals['id_animal'] = id;

        if(!(rdvVals.motif_consultation && rdvVals.motif_consultation.length > 0)) { setMotifError('Ce champs est obligatoire'); }
        if(!(rdvVals.symptomes && rdvVals.symptomes.length > 0)) { setSymptomesError('Ce champs est obligatoire'); }

        if(rdvVals.date_rdv != undefined && !isDateValid(rdvVals.date_rdv)) { rdvVals.date_rdv = null; }

        setRdv(user, id_rdv, rdvVals).then(res => {
            if(res != undefined && res.data != undefined && res.data.statut == "ok") {

                rdvVals.animal = res.data.animal;
                let liste = [...listeRdvs];
                    
                if(rdvVals.date_rdv && rdvVals.date_rdv.length > 0 && isDateValid(rdvVals.date_rdv)) {
                    let found = false;
                    liste.map((r, i) => {   
                        if(r.id == id_rdv) {
                            liste[i] = rdvVals;
                            found = true;
                        }
                    });
                    if(!found) {
                        liste.push(rdvVals);
                    }  
                }
                setListeRdvs(liste); 
                setEditedRdv({});
                handleRdvNeedSave(id_rdv, false);
            }
        });
    }


    const listeTypesDocument = [];
    type_documents.map((t, i) => {
        listeTypesDocument.push({ value: t, label: libelleType(t)});
    });

    const listeTypesPrestations = [];
    typesPrestations.map((t, i) => {
        listeTypesPrestations.push({ value: t.id, label: t.nom });
    });

    const listeVeterinaires = [];
    veterinaires.map((v, i) => {
        listeVeterinaires.push({ value: v.id, label: v.prenom+' '+v.nom });
    });
  

    return(<div>

        {pageWidth < 1400 && <Card className={['p-2','px-3','mb-2'].join(" ")} style={{position: 'relative', zIndex: 20, overflow: 'visible'}}>
        <Row className={['d-flex','align-items-center'].join(" ")}>
            <Col md={4} className='borderRight'>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        id="date_naissance" 
                        label="Date de naissance"
                        value={dateDebut ? dayjs(dateDebut) : ''}
                        onChange={updateDateDebut}
                        ampm={false}
                        format="DD-MM-YYYY"
                        className="mt-3 mb-1 w-100"
                    />
                </LocalizationProvider>
            </Col>
            <Col md={4} className='borderRight'>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        id="date_naissance" 
                        label="Date de naissance"
                        value={dateFin ? dayjs(dateFin) : ''}
                        onChange={updateDateFin}
                        ampm={false}
                        format="DD-MM-YYYY"
                        className="mt-3 mb-1 w-100"
                    />
                </LocalizationProvider>
            </Col>
            <Col md={4} className='borderRight'>
                <FormControlLabel
                    value="start"
                    control={<Switch checked={enCours} onChange={toggleEnCours} className='ms-2' />}
                    label="Consultations en cours"
                    labelPlacement="start"
                    className='ms-1'
                />
            </Col>
        </Row>
        </Card>}
        <Row>
            <Col md={(pageWidth < 1400 ? 12 : 9)}> 
                {listeRdvs && listeRdvs.length == 0 && <em className='text-muted'>Aucune consultation</em>}               
                {listeRdvs && listeRdvs.map((rdv, i) => {
                    const non_modifiable = (editedRdv.id_specialiste != null && user.id != editedRdv.id_specialiste);
                    if(enCours && rdv.date_cloture != null) return;
                    if(rdv.date_rdv != null) {
                        let date_rdv = new Date(rdv.date_rdv);
                        if(dateDebut != null) {
                            let date_debut = new Date(dateDebut);
                            if(date_rdv < date_debut) return;
                        }
                        if(dateFin != null) {
                            let date_fin = new Date(dateFin);
                            if(date_rdv > date_fin) return;
                        }
                    }

                    return <Accordion expanded={expanded === 'rdv_'+rdv.id} onChange={handleChangeAccordion('rdv_'+rdv.id)}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                            >
                            <div className={['d-flex','align-items-center'].join(" ")}>
                                <strong className='arvo'>{rdv.date_formatted != null ? 'Le '+rdv.date_formatted : 'Sans date'}</strong>
                                <div className={['etiquette',(rdv.date_cloture != null ? 'success' : 'warning'),'ms-3'].join(" ")}>{(rdv.date_cloture != null ? 'Terminée' : 'En cours')}</div>
                                {rdv.id_specialiste != null && <span title="Cas référé"><DriveFileMoveOutlinedIcon className={['text-muted','ms-3'].join(" ")}/></span>}
                                {rdv.a_distance == true && <span title="En téléconsultation"><LocalPhoneOutlinedIcon className={['text-muted','ms-3'].join(" ")}/></span>}
                            </div>                            
                            <span>
                                {rdvNeedSave && rdvNeedSave[rdv.id] == true && <IconButton type="button"  className='waiting-icon me-2' onClick={() => saveRdv(rdv.id)}>
                                    <SaveOutlinedIcon />
                                </IconButton>}
                                <IconButton aria-label="message" className="mr-2" onClick={() => window.open('/messagerie?destinataire='+rdv.id_client+'&consultation='+rdv.id, '_self')}>
                                    <ChatOutlinedIcon />
                                </IconButton>    
                            </span>                       
                        </AccordionSummary>
                        <AccordionDetails className='px-4'>                            
                            <Row className={['d-flex','align-items-center'].join(" ")}>
                                <Col md={6}>
                                    <p className='smallText'>Type de prestation</p>
                                    {non_modifiable ? <p>{editedRdv.prestation}</p> :
                                        <Select
                                            id="type_prestation"
                                            value={editedRdv.type_prestation ? listeTypesPrestations.filter((c) => c.value == editedRdv.type_prestation) : ''}
                                            onChange={updateTypePrestationRdv}
                                            placeholder="Type de prestation"
                                            options={listeTypesPrestations}
                                            >                      
                                        </Select>
                                    }
                                </Col>
                                {rdv.id_specialiste != null && <Col>
                                    <p className='smallText'>Spécialité</p>
                                    <p>{editedRdv.specialite != null ? editedRdv.specialite : 'Non renseignée'}</p>
                                </Col>}
                            </Row>
                            <Row className='mt-3'>
                                <Col>
                                    <p className='smallText'>Motif de consultation</p>
                                    <BaseTextareaAutosize 
                                        id="motif_consultation" 
                                        label="Motif de consultation" 
                                        variant="standard" 
                                        value={editedRdv.motif_consultation ? editedRdv.motif_consultation : ''}
                                        onChange={updateRdv}
                                        className="mt-1 mb-1 w-100"
                                        minRows={3}
                                        placeholder="..." 
                                    />
                                </Col>
                            </Row>
                            <Row className={['d-flex','align-items-center','mt-3'].join(" ")}>
                                <Col className='ps-3'>                                    
                                    {non_modifiable ? <div>
                                        <p className='smallText'>Date de création</p>
                                        <p>{editedRdv.date_creation != null ? 'le '+editedRdv.date_creation_formatted : 'Sans date'}</p>
                                        </div> :
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DateTimePicker
                                                id="date_rdv" 
                                                label="Date de consultation"
                                                value={editedRdv.date_creation ? dayjs(editedRdv.date_creation) : ''}
                                                disabled={true}
                                                ampm={false}
                                                format="DD-MM-YYYY HH:mm"
                                                className="mt-3 mb-2 w-100"
                                            />
                                        </LocalizationProvider>
                                    }
                                </Col>
                                <Col>
                                    <p className='smallText'>Réalisée par</p>
                                    {non_modifiable ? <p>{editedRdv.specialiste}</p> : 
                                        (user.id == editedRdv.id_specialiste ?
                                            <Select
                                                id="id_specialiste"
                                                value={editedRdv.id_specialiste ? listeVeterinaires.filter((c) => c.value == editedRdv.id_specialiste) : ''}
                                                onChange={updateSpecialisteRdv}
                                                placeholder="Spécialiste"
                                                options={listeVeterinaires}
                                                >                      
                                            </Select> : 
                                            <Select
                                                id="id_veterinaire"
                                                value={editedRdv.id_veterinaire ? listeVeterinaires.filter((c) => c.value == editedRdv.id_veterinaire) : ''}
                                                onChange={updateVeterinaireRdv}
                                                placeholder="Vétérinaire"
                                                options={listeVeterinaires}
                                                >                      
                                            </Select>
                                        )
                                    }
                                </Col>
                            </Row>
                            <Row className={['d-flex','align-items-center','mt-2'].join(" ")}>
                                <Col className='ps-3'>                                    
                                    {non_modifiable ? <div>
                                        <p className='smallText'>Date de consultation</p>
                                        <p>{editedRdv.date_rdv != null ? 'le '+editedRdv.date_formatted : 'Sans date'}</p>
                                        </div> :
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DateTimePicker
                                                id="date_rdv" 
                                                label="Date de consultation"
                                                value={editedRdv.date_rdv ? dayjs(editedRdv.date_rdv) : ''}
                                                onChange={updateDateRdv}
                                                ampm={false}
                                                format="DD-MM-YYYY HH:mm"
                                                className="mt-3 mb-2 w-100"
                                            />
                                        </LocalizationProvider>
                                    }
                                </Col>
                                <Col>
                                    <p className='smallText'>Facturé à</p>
                                    <p>{editedRdv.facture_a == 'veterinaire' ? editedRdv.cabinet : editedRdv.client}</p>
                                </Col>
                            </Row>
                            <Row className='mt-2'>
                                <Col className='ps-3'>
                                    <p className='smallText'>Délai</p>
                                    <p>{editedRdv.delai != null ? editedRdv.delai : 'Non renseigné'}</p>
                                </Col>
                                <Col>
                                    <p className='smallText'>Temps passé</p>
                                    {non_modifiable ? <p>{editedRdv.heures}h {editedRdv.minutes}min</p> :
                                        <Row>
                                            <Col md={4}>
                                                <FormControl variant="standard">
                                                    <Input
                                                        id="heures"
                                                        endAdornment={<InputAdornment position="end">h</InputAdornment>}
                                                        aria-describedby="heures"
                                                        value={editedRdv.heures}
                                                        disabled={editedRdv.a_distance}
                                                        onChange={e => {updateRdvTemps('heures', e.target.value);}}
                                                    />
                                                </FormControl>
                                            </Col>
                                            <Col md={4}>
                                                <FormControl variant="standard">
                                                    <Input
                                                        id="minutes"
                                                        endAdornment={<InputAdornment position="end">min</InputAdornment>}
                                                        aria-describedby="minutes"
                                                        value={editedRdv.minutes}
                                                        disabled={editedRdv.a_distance}
                                                        onChange={e => {updateRdvTemps('minutes', e.target.value);}}
                                                    />
                                                </FormControl>
                                            </Col>
                                        </Row>
                                    }
                                </Col>
                            </Row>
                            <Row className='mt-2'>
                                <Col className='ps-3'>
                                    <FormControl className="mb-1">
                                        <FormLabel id="cloture-label" className='smallText'>Clôturée</FormLabel>
                                        {non_modifiable ? <p>{editedRdv.date_cloture != null ? 'le '+editedRdv.date_cloture_formatted : 'Non'}</p> :
                                            <RadioGroup
                                                row
                                                aria-labelledby="decede-label"
                                                id="cloture"
                                                name="Clôturée" 
                                                value={editedRdv.cloture != undefined ? editedRdv.cloture : 'non'}
                                                onChange={updateClotureRdv}                                    
                                            >
                                                <FormControlLabel value='non' control={<Radio />} label="Non" />
                                                <FormControlLabel value='oui' control={<Radio />} label="Oui" />
                                            </RadioGroup>
                                        }
                                    </FormControl>  
                                </Col>
                                <Col>
                                    {editedRdv.cloture == 'oui' && !non_modifiable && <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            id="date_cloture" 
                                            label="Date de clôture"
                                            value={editedRdv.date_cloture ? dayjs(editedRdv.date_cloture) : ''}
                                            onChange={updateDateClotureRdv}
                                            ampm={false}
                                            format="DD-MM-YYYY"
                                            className="mt-3 mb-1 w-100"
                                        />
                                    </LocalizationProvider>
                                    }
                                </Col>
                            </Row>

                            <hr></hr>

                            <p className='smallText'>Documents associés</p>
                            <Table 
                                className='w-100'
                                wordWrap="break-word" 
                                autoHeight
                                data={listeTriee(rdv.id, sortColumn, sortType)}
                                sortColumn={sortColumn}
                                sortType={sortType}
                                onSortColumn={handleSortColumn}
                            >
                                <Column flexGrow={1} sortable>
                                    <HeaderCell>Nom</HeaderCell>
                                    <Cell dataKey="nom" />
                                </Column>
                                <Column flexGrow={1} sortable>
                                    <HeaderCell>Type</HeaderCell>
                                    <Cell dataKey="type_document">{rowData => libelleType(rowData.type_document)}</Cell>
                                </Column>                        
                                <Column flexGrow={1} sortable>
                                    <HeaderCell>Sous-type</HeaderCell>
                                    <Cell dataKey="sous_type_document" />
                                </Column>
                                <Column flexGrow={1} sortable>
                                    <HeaderCell>Date</HeaderCell>
                                    <Cell dataKey="date_ajout" />
                                </Column>                          
                                <Column flexGrow={1} sortable>
                                    <HeaderCell>Statut</HeaderCell>
                                    <Cell dataKey="statut">{rowData => (rowData.statut=="nouveau" ? <strong className='textSecondary'>Nouveau</strong> : <span>Lu</span>)}</Cell>
                                </Column>                            
                                <Column flexGrow={2}>
                                    <HeaderCell>Action</HeaderCell>
                                    <Cell className='p-0'>
                                        {rowData => <div className={['d-flex','align-items-center','h-100'].join(" ")}>
                                            <IconButton type="button" className='ms-1' onClick={() => download(rowData.id, 'apercu')}>
                                                <SearchIcon />
                                            </IconButton>
                                            <IconButton type="button" className='ms-1' onClick={() => download(rowData.id)}>
                                                <FileDownloadOutlinedIcon />
                                            </IconButton>
                                            {rowData.id_proprietaire == user.id && <IconButton type="button" className='textRed' onClick={() => supprDoc(rowData.id)}>
                                                <DeleteOutlineIcon />
                                            </IconButton>}
                                        </div>}
                                    </Cell>
                                </Column>
                            </Table> 
                        </AccordionDetails>
                    </Accordion>
                })
            }
            </Col>
            {pageWidth >= 1400 &&<Col md={3}>
                <Card>                   
                    <CardContent>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                id="date_debut" 
                                label="Date de début"
                                value={dateDebut ? dayjs(dateDebut) : ''}
                                onChange={updateDateDebut}
                                ampm={false}
                                format="DD-MM-YYYY"
                                className="mt-3 mb-1 w-100"
                            />
                        </LocalizationProvider>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                id="date_fin" 
                                label="Date de fin"
                                value={dateFin ? dayjs(dateFin) : ''}
                                onChange={updateDateFin}
                                ampm={false}
                                format="DD-MM-YYYY"
                                className="mt-3 mb-1 w-100"
                            />
                        </LocalizationProvider>
                        <hr></hr>
                        <FormControlLabel
                            value="start"
                            control={<Switch checked={enCours} onChange={toggleEnCours} className='ms-2' />}
                            label="Consultations en cours"
                            labelPlacement="start"
                            className='ms-1'
                        />
                    </CardContent>
                </Card>
            </Col>}       
        </Row>

        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: { timeout: 500 },
            }}
        >
        <Fade in={open}>
            <Box className="modal-sm">
                <p className={['titre','bold','mb-2'].join(" ")}>Nouveau document</p>

                <IconButton aria-label="modifier" className="modalExit" onClick={handleClose}>
                    <CloseIcon/>
                </IconButton>

                <TextField 
                    id="nom" 
                    label="Nom" 
                    variant="standard" 
                    value={editedDoc.nom ? editedDoc.nom : ''}
                    onChange={updateDoc}
                    className="w-100"
                    error={!!nomError}
                    helperText={nomError}
                />
                <FormControl variant="standard" sx={{ width: '100%' }}>
                    <Select
                        id="type_document"
                        value={editedDoc.type_document ? listeTypesDocument.filter((c) => c.value == editedDoc.type_document) : ''}
                        className='mt-3'
                        onChange={(e) => updateTypeDocument(e.value)}
                        placeholder="Type de document"
                        options={listeTypesDocument}
                        error={!!typeDocError}
                    >                      
                    </Select>
                </FormControl>
                {typeDocError && <small className='textRed'>{typeDocError}</small>}     
                {(editedDoc.type_document == 'compte_rendu' || editedDoc.type_document == 'resultats') &&
                    <FormControl variant="standard" sx={{ width: '100%' }}>
                        <Select
                            id="sous_type_document"
                            value={editedDoc.sous_type_document ? listeSousTypesDocument.filter((c) => c.value == editedDoc.sous_type_document) : ''}
                            className='mt-3'
                            onChange={updateSousTypeDocument}
                            placeholder="Sous-type de document"
                            options={listeSousTypesDocument}
                        >                      
                        </Select>
                    </FormControl>
                }

                <Select
                    isMulti
                    name="rdvs"
                    options={listeRdvs}
                    className="basic-multi-select mt-3"
                    classNamePrefix="select"
                    placeholder="Consultations liées"
                    onChange={updateRdvs}
                />
                {consultError && <small className='textRed'>{consultError}</small>}   

                <div>
                    <Button variant="outlined" className={['w-100','mt-3','py-4','px-2'].join(" ")} onClick={() => document.getElementById('nouveau_document').click()} startIcon={<FileUploadOutlinedIcon />}>
                        {editedDoc.nom_fichier ? editedDoc.nom_fichier : 'Parcourir'}
                        <input id="nouveau_document" className='d-none' type="file" onChange={handleFileChange} />
                    </Button>
                    {fileError && <small className='textRed'>{fileError}</small>}                    
                </div>
                
                <Button variant="contained" className={['buttonSecondary','mt-5'].join(" ")} type="submit" startIcon={(loading ? <CircularProgress/> : <CheckIcon />)} onClick={importDoc}>Importer</Button> 
            </Box>
        </Fade> 
        </Modal>
    </div>);
}

export default Consultations;