import axios from "axios";

export async function getRdvs(user, id_client, id_veto, past_rdv = false) {
    
    return axios.post(process.env.REACT_APP_API_URL+'rendezvous/get_all', {
        token: user.sessionToken,
        id_client: id_client,
        id_veto: id_veto,
        past_rdv: past_rdv
    }, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    })
    .catch(function (error) {
        console.log(error.toJSON());
    });
}


export async function getRdvsCommuns(user, id_user2, past_rdv = false) {
    
    return axios.post(process.env.REACT_APP_API_URL+'rendezvous/get_rdvs_communs', {
        token: user.sessionToken,
        id_user2: id_user2,
        past_rdv: past_rdv
    }, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    })
    .catch(function (error) {
        console.log(error.toJSON());
    });
}


export async function setRdv(user, id_rdv, datas) {
    
    return axios.post(process.env.REACT_APP_API_URL+'rendezvous/set', {
        token: user.sessionToken,
        id_rdv: (id_rdv != null ? id_rdv : 'new'),
        id_animal: datas.id_animal,
        id_veterinaire: datas.id_veterinaire,
        datas: JSON.stringify(datas)
    }, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    })
    .catch(function (error) {
        console.log(error.toJSON());
    });
}


export async function demarreRdv(user, id_rdv) {
    
    return axios.post(process.env.REACT_APP_API_URL+'rendezvous/demarrage', {
        token: user.sessionToken,
        id_rdv: id_rdv
    }, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    })
    .catch(function (error) {
        console.log(error.toJSON());
    });
}


export async function getRdvsReferes(user, id_cabinet, id_veto) {
    
    return axios.post(process.env.REACT_APP_API_URL+'rendezvous/get_cas_referes', {
        token: user.sessionToken,
        id_cabinet: id_cabinet,
        id_veto: id_veto
    }, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    })
    .catch(function (error) {
        console.log(error.toJSON());
    });
}