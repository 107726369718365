import React, { useState } from 'react';

import {TextField, Button} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import Container from 'react-bootstrap/Container';
import CircularProgress from '@mui/material/CircularProgress';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../App/App.css';

import {loginUser, useCredentials} from '../../Services/authServices';

export default function Login() {
  
  const { setUser } = useCredentials();
  const [username, setUserName] = useState();
  const [password, setPassword] = useState();
  const [emailErrorMsg, setEmailErrorMsg] = useState();
  const [mdpErrorMsg, setMdpErrorMsg] = useState();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async e => {
    e.preventDefault();

    setLoading(true);

    loginUser({username, password}).then((res) => {
      if(res.data.statut == "ok") {
        setUser(res.data.donnees);        
        setLoading(false);
        window.open('/', '_self');
      }
      else {
        if(res.data.champ == "email") { setEmailErrorMsg("Email invalide"); }
        else { setMdpErrorMsg("Mot de passe erroné"); }
        setLoading(false);
      } 
    });

    setEmailErrorMsg(null);
    setMdpErrorMsg(null);       
  }

  return(
    <div className={['fullpage','login_page','d-flex','align-items-center'].join(" ")}>
      <Container>
        <Row>
          <Col className="p-4">
            <h1>Vetlife</h1>
            <hr width="75%" className="login_line"></hr>
            <form onSubmit={handleSubmit}>
              <p><TextField 
                id="outlined-basic" 
                label="Email" 
                variant="standard" 
                onChange={e => setUserName(e.target.value)}
                error={!!emailErrorMsg}
                helperText={emailErrorMsg}
              /></p>
              <p className="mt-2"><TextField id="outlined-password-input" 
                label="Password" 
                variant="standard" 
                type="password" 
                autoComplete="current-password" 
                onChange={e => setPassword(e.target.value)}
                error={!!mdpErrorMsg}
                helperText={mdpErrorMsg}
              /></p>
              <p className="mt-4">
                <Button variant="contained" className="buttonSecondary" type="submit" startIcon={(loading ? <CircularProgress/> : <CheckIcon />)}>Connexion</Button>
                <Button variant="text" className="ms-3" onClick={() => window.open('/inscription_cabinet', '_self')}>Créer un compte</Button>
              </p>
            </form>
          </Col>
          <Col></Col>
        </Row>
      </Container>
    </div>
  )
}