import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
    
    palette: {
        primary: {
            main: '#00CFC3',
        },
        secondary: {
            main: '#00CFC3',
        },
    },
    typography: {
        fontFamily: '"Lato", sans-serif !important;',
    }
});
