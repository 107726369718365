import React, { useState } from 'react';

import {Button, IconButton} from '@mui/material';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../App/App.css';

import {useCredentials} from '../../Services/authServices';
import { LogoutOutlined } from '@mui/icons-material';


function UserWidget() {

    const { user } = useCredentials();
    const [clicked, setClicked] = useState(false);

    const toggleClicked = () => {
        setClicked(!clicked);
    }

    var initials = user.prenom.charAt(0) + '.' + user.nom.charAt(0) + '.';

    return(
        <div className={clicked ? ['clicked','user_widgets'].join(" ") : 'user_widgets'}>

            <IconButton className='logout_icon' aria-label="déconnexion" onClick={() => window.open('/logout','_self')}>
                <LogoutOutlined />
            </IconButton>
            
            <Button className='initiales' onClick={toggleClicked}>
                <p>{initials}</p>
            </Button>            
        </div>
    );
}

export default UserWidget;