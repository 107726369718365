import { React, useEffect, useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import axios from "axios";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CheckIcon from '@mui/icons-material/Check';
import EditNoteIcon from '@mui/icons-material/EditNote';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Skeleton, TextField, Button} from '@mui/material';
import Card from '@mui/material/Card';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import dayjs from 'dayjs';
import swal from 'sweetalert';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateField } from '@mui/x-date-pickers/DateField';
import IconButton from '@mui/material/IconButton';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../App/App.css';

import {useCredentials, getByToken} from '../../Services/authServices';
import {getClientDatas, setClientDatas} from '../../Services/UserServices'; 
import {getEspeces, getRaces, setAnimal, deleteAnimal} from '../../Services/animalServices'; 
import {setMandat} from '../../Services/GCServices';

function getAnimalIcon(espece) {

    let png = '/img/Dog.png';
    switch(espece) {
        case 'Chien': png = '/img/Dog.png'; break;
        case 'Chat': png = '/img/Cat.png'; break;
        case 'Oiseau': png = '/img/Bird.png'; break;
    }

    return <img src={png} className="animal_icon" alt="animal_icon"/>;
}

function InscriptionClient() {

    const { id } = useParams();
    const queryParams = new URLSearchParams(window.location.search);
    const { user, setUser } = useCredentials();
    const [client, setClient] = useState({});
    const [emailErrorMsg, setEmailErrorMsg] = useState();

    const [listeEspeces, setListeEspeces] = useState([]);
    const [listeRaces, setListeRaces] = useState([]);
    
    const [editedAnimal, setEditedAnimal] = useState({});
    const [open, setOpen] = useState(false);
    const handleClose = () => setOpen(false);
    const [puceErrorMsg, setPuceErrorMsg] = useState();
    
    useEffect(() => {

        const sessionToken = queryParams.get('sessionToken');
        if(!user || !user.id) {
            if(!sessionToken) { window.open('/login', '_self'); }
            else {
                getByToken(sessionToken).then(res => {
                    if(res != undefined && res.data != undefined && res.data.statut == "ok") {
                        let user_datas = {...res.data.donnees};
                        user_datas.sessionToken = sessionToken;
                        setUser(user_datas);
                    }
                });
            }
        }
        
        if(user) {
            getEspeces(user).then(res => {
                if(res != undefined && res.data != undefined && res.data.statut == "ok") {
                    setListeEspeces(res.data.donnees);
                }
            });
            getClientDatas(user, id).then(res => {
                if(res != undefined && res.data != undefined && res.data.statut == "ok") {
                    setClient(res.data.donnees);
                }
            });
        }
    }, [user]);


    
    const handleChange = (e) => {
        let clientVals = {...client};
        clientVals[e.target.id] = e.target.value;
        setClient(clientVals);       
    }

    const save = (e) => {
        if(client.email && client.email.length > 0) {
            setClientDatas(user, id, client);
        }
        else {
            setEmailErrorMsg("Email invalide");
        }  
    }


    const changeEditedAnimal = (e) => {

        if(e.target.dataset.id != undefined) {

            let id_animal = e.target.dataset.id;
            let animalVals = {};
            client.animaux.map((a, i) => {
                if(a.id == id_animal) { animalVals = {...a}; }
            });        
            setEditedAnimal(animalVals);
            
            getRaces(user, animalVals.id_espece).then(res => {
                if(res != undefined && res.data != undefined && res.data.statut == "ok") {
                    setListeRaces(res.data.donnees);
                }
            });

            setOpen(true);
        }
    }


    const addAnimal = () => {
        setEditedAnimal({'id':'new'});
        setOpen(true);
    }

    const updateAnimal = (e) => {
        let animalVals = {...editedAnimal};
        animalVals[e.target.id.replace('animal-', '')] = e.target.value;
        setEditedAnimal(animalVals);
    }
    const updateAnimalDateNaissance = (e) => {
        let animalVals = {...editedAnimal};
        animalVals['date_naissance'] = e['$y']+'-'+(e['$M']+1)+'-'+e['$D'];
        setEditedAnimal(animalVals);
    }
    const updateAnimalEspece = (e) => {
        let animalVals = {...editedAnimal};
        animalVals['id_espece'] = e.target.value;
        
        getRaces(user, e.target.value).then(res => {
            if(res != undefined && res.data != undefined && res.data.statut == "ok") {
                setListeRaces(res.data.donnees);
                animalVals['id_race'] = res.data.donnees[0].id;
            }
        });

        setEditedAnimal(animalVals);
    }
    const updateAnimalRace = (e) => {
        let animalVals = {...editedAnimal};
        animalVals['id_race'] = e.target.value;
        setEditedAnimal(animalVals);
    }
    const updateAnimalSexe = (e) => {
        let animalVals = {...editedAnimal};
        animalVals['sexe'] = e.target.value;
        setEditedAnimal(animalVals);
    }

    const saveAnimal = () => {
        if(editedAnimal.numero_puce && editedAnimal.numero_puce.length > 0) {
            setAnimal(user, editedAnimal, id).then(res => {
                if(res != undefined && res.data != undefined && res.data.statut == "ok") {
                    if(editedAnimal.id != 'new') {
                        client.animaux.map((a, i) => {
                            if(a.id == editedAnimal.id) { 
                                client.animaux[i] = {...editedAnimal}; 
                                client.animaux[i].espece = res.data.espece;
                                client.animaux[i].race = res.data.race;
                            }
                        });
                    }
                    else {
                        let animalVals = {...editedAnimal};
                        animalVals.espece = res.data.espece;
                        animalVals.race = res.data.race;
                        animalVals.id = res.data.id_animal;
                        client.animaux.push(animalVals);
                    }
                    setOpen(false); 
                    setPuceErrorMsg(null);     
                }
            });
        }
        else {
            setPuceErrorMsg("Ce champ est obligatoire");
        }         
    }

    const supprAnimal = (id_animal) => {
        swal({
            title: "Êtes-vous sûr(e) ?",
            text: "Cette action supprimera l'animal, avec ses rendez-vous.",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          })
          .then((willDelete) => {
            if(willDelete) {
                deleteAnimal(user, id_animal).then(res => {
                    if(res != undefined && res.data != undefined && res.data.statut == "ok") {
                        client.animaux.map((a, i) => {
                            if(a.id == id_animal) { 
                                client.animaux.splice(i, 1); 
                                setEditedAnimal({'id':'new'});
                            }
                        }); 
                    }
                });
            }
        });        
    }

    const lienGoCardless = () => {

        let user_datas = {...user};
        user_datas.adresse = client.adresse;
        user_datas.cp = client.cp;
        user_datas.ville = client.ville;
        setUser(user_datas);

        setClientDatas(user, id, client).then(res => {
            setMandat(user, 'client').then(res2 => {
                if(res2 != undefined && res2.data != undefined && res2.data.statut == "ok") {
    
                    let user_datas = {...user};
                    user_datas.id_gocardless = res2.data.id_gocardless;
                    setUser(user_datas);
                    window.open(res2.data.lien,'_self');
                }
            }); 
        });           
    }

    const EspecesMenuItems = listeEspeces.map((e, i) => <MenuItem key={e.id} value={e.id}>{e.nom}</MenuItem>);
    const RacesMenuItems = listeRaces.map((e, i) => <MenuItem key={e.id} value={e.id}>{e.nom}</MenuItem>);
    const listeAnimaux = (client.animaux != undefined && client.animaux.length > 0 ? client.animaux.map((e, i) => <MenuItem key={e.id} value={e.id}>{e.nom}</MenuItem>) : []);

    return(
        <div className="fullpage">
        <Container className={['full_container','inscription_client_page'].join(" ")}>
            
            <div className='user_widgets'>  
                <div className="logo">          
                    <img src='/img/logo.png' className="w-100" alt="logo"/>
                </div>
            </div>
            
            <Row className={['w-100',' ps-4'].join(" ")}>

                <Col xs={10} className={['px-5','pt-5'].join(" ")}>
                    {client != undefined ? (
                    <Row className="align-items-center">
                        <Col md={8}>
                            <h2 className="bold">Bienvenue !</h2>
                            <p className={['mb-1','textSecondary'].join(" ")}>Vous êtes invité à remplir votre dossier par le cabinet {client.cabinet}</p>
                        </Col>
                    </Row>) : (<div className="mt-2">
                        <Skeleton variant="text" sx={{ fontSize: '1.1rem' }} width={200} />
                        <Skeleton variant="text" sx={{ fontSize: '1.5rem' }} width={100} />
                    </div>)
                    }
                </Col>

                <Col xs={12} md={6} className="pb-5">
                    <div className='corpsFiche'>
                        <p className={['titre','bold','mb-2'].join(" ")}>Informations</p>

                        <Row>
                            <Col md={6}>
                                <TextField 
                                    required
                                    id="prenom" 
                                    label="Prénom(s)" 
                                    variant="standard"
                                    value={client.prenom ? client.prenom : ''}
                                    onChange={handleChange}
                                    className="mt-2 mb-1 w-100"
                                />
                                <TextField 
                                    id="nom" 
                                    label="Nom" 
                                    variant="standard" 
                                    value={client.nom ? client.nom : ''}
                                    onChange={handleChange}
                                    className="mt-2 mb-1 w-100"
                                />
                                <TextField 
                                    id="email" 
                                    label="Adresse email" 
                                    variant="standard" 
                                    value={client.email ? client.email : ''}
                                    onChange={handleChange}
                                    className="mt-2 mb-1 w-100"
                                    error={!!emailErrorMsg}
                                    helperText={emailErrorMsg}
                                />
                            </Col>
                            <Col md={6}>
                                <TextField 
                                    id="telephone" 
                                    label="Téléphone" 
                                    variant="standard" 
                                    value={client.telephone ? client.telephone : ''}
                                    onChange={handleChange}
                                    className="mt-2 mb-1 w-100"
                                />
                                <TextField 
                                    id="telephone2" 
                                    label="Téléphone 2" 
                                    variant="standard" 
                                    value={client.telephone2 ? client.telephone2 : ''}
                                    onChange={handleChange}
                                    className="mt-2 mb-1 w-100"
                                />
                            </Col>
                        </Row>

                        <p className={['titre','bold','mt-5','mb-2'].join(" ")}>Notes</p>
                        
                        <Row>
                            <Col md={8}>                            
                                <TextField 
                                    id="adresse" 
                                    label="Adresse" 
                                    variant="standard" 
                                    value={client.adresse ? client.adresse : ''}
                                    onChange={handleChange}
                                    className="mt-2 mb-1 w-100"
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4}>
                                <TextField 
                                    id="cp" 
                                    label="Code postal" 
                                    variant="standard" 
                                    value={client.cp ? client.cp : ''}
                                    onChange={handleChange}
                                    className="mt-2 mb-1 w-100"
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <TextField 
                                    id="ville" 
                                    label="Ville" 
                                    variant="standard" 
                                    value={client.ville ? client.ville : ''}
                                    onChange={handleChange}
                                    className="mt-2 mb-1 w-100"
                                />
                            </Col>
                        </Row>
                        
                        <Button variant="contained" className={['buttonSecondary','mt-5'].join(" ")} type="submit" startIcon={<CheckIcon />} onClick={save}>Sauvegarder</Button>
                    </div>
                </Col>

                <Col xs={12} md={6}>
                    <div className={['corpsFiche','borderLeft'].join(" ")}>

                        <p className={['titre','bold','mb-1'].join(" ")}>
                            Coordonnées bancaires
                            <IconButton aria-label="supprimer" className="ms-3" onClick={lienGoCardless}>
                                <ArrowForwardIcon />
                            </IconButton>
                        </p>
                        <p className="smallText">Paiement sécurisé avec GoCardless</p>

                        <p className={['titre','bold','mt-5','mb-2'].join(" ")}>
                            Animaux ({client.animaux != undefined ? client.animaux.length : 0})
                            <IconButton aria-label="supprimer" className="ms-3" onClick={addAnimal}>
                                <AddBoxOutlinedIcon />
                            </IconButton>
                        </p>

                        <Col md={7}>
                        {client.animaux != undefined ? client.animaux
                            .map((p, i) => {	
                            var mt = (i > 0 ? "mt-3" : "mt-1");
                            return (<div className = {mt} key={p.id} >
                                <Card className={['px-4','py-3','mt-3', 'w-auto'].join(" ")}>
                                    <Row className="align-items-center">
                                        <Col md={2}>
                                            {getAnimalIcon(p.espece)}
                                        </Col>
                                        <Col md={6} className='ps-4'>
                                            <p className="bigText">{p.nom}</p>
                                            <p className="smallText">{p.race}</p>
                                        </Col>
                                        <Col md={4} className="textRight">
                                            <IconButton aria-label="modifier" className="mr-2" onClick={changeEditedAnimal}>
                                                <EditNoteIcon data-id={p.id}/>
                                            </IconButton>
                                            <IconButton aria-label="supprimer" className="textRed" onClick={() => supprAnimal(p.id)}>
                                                <DeleteOutlineIcon />
                                            </IconButton>
                                        </Col>
                                    </Row>
                                </Card>
                            </div>)
                            }) : (<div></div>)
                        } 
                        </Col>
                    </div>
                </Col>
               
            </Row>
        </Container>

        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: { timeout: 500 },
            }}
        >
            <Fade in={open}>
                <Box>
                    <p className={['titre','bold','mb-2'].join(" ")}>{editedAnimal.nom ? editedAnimal.nom : 'Nouvel animal'}</p>

                    <Row>
                        <Col md={5} className={['d-flex','align-items-center','justify-content-center'].join(" ")}>
                            <div src={editedAnimal.photo ? editedAnimal.photo : ''} className="animal_photo" alt="animal_photo"></div>
                        </Col>
                        <Col md={7}>
                            <TextField 
                                id="animal-nom" 
                                label="Nom" 
                                variant="standard" 
                                value={editedAnimal.nom ? editedAnimal.nom : ''}
                                onChange={updateAnimal}
                                className="mt-2 mb-1 w-100"
                            />
                            <TextField 
                                id="animal-numero_puce" 
                                label="Numéro de puce" 
                                variant="standard" 
                                value={editedAnimal.numero_puce ? editedAnimal.numero_puce : ''}
                                onChange={updateAnimal}
                                className="mt-2 mb-1 w-100"
                                error={!!puceErrorMsg}
                                helperText={puceErrorMsg}
                            />
                            <TextField 
                                id="animal-poids" 
                                label="Poids (Kg)" 
                                variant="standard" 
                                value={editedAnimal.poids ? editedAnimal.poids : ''}
                                onChange={updateAnimal}
                                className="mt-2 mb-1 w-100"
                            />
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateField
                                    id="animal-date_naissance" 
                                    label="Date de naissance"
                                    value={editedAnimal.date_naissance ? dayjs(editedAnimal.date_naissance) : ''}
                                    onChange={updateAnimalDateNaissance}
                                    format="DD-MM-YYYY"
                                    className="mt-3 mb-1 w-100"
                                />
                            </LocalizationProvider>
                            <FormControl className="mt-2 mb-1">
                                <FormLabel id="animal-sexe-label">Sexe</FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby="animal-sexe-label"
                                    id="animal-sexe"
                                    name="animal-sexe" 
                                    value={editedAnimal.sexe != undefined ? editedAnimal.sexe : 'M'}
                                    onChange={updateAnimalSexe}                                    
                                >
                                    <FormControlLabel value="M" control={<Radio />} label="Male" />
                                    <FormControlLabel value="F" control={<Radio />} label="Femelle" />
                                </RadioGroup>
                            </FormControl>
                        </Col>
                    </Row>
                    <Row className="mt-4">
                        <Col md={5}>
                            <FormControl variant="standard" sx={{ width: '90%' }}>
                                <InputLabel id="animal-espece-label">Espèce</InputLabel>
                                <Select
                                    labelId="animal-espece-label"
                                    id="animal-espece"
                                    value={editedAnimal.id_espece ? editedAnimal.id_espece : ''}
                                    onChange={updateAnimalEspece}
                                    label="Espèce"
                                    >
                                    {EspecesMenuItems}
                                </Select>
                            </FormControl>
                        </Col>
                        <Col md={7}>
                            <FormControl variant="standard" sx={{ width: '100%' }}>
                                <InputLabel id="animal-race-label">Race</InputLabel>
                                <Select
                                    labelId="animal-race-label"
                                    id="animal-race"
                                    value={editedAnimal.id_race ? editedAnimal.id_race : ''}
                                    onChange={updateAnimalRace}
                                    label="Race"
                                    >
                                    {RacesMenuItems}
                                </Select>
                            </FormControl>
                        </Col>
                    </Row>

                    <Button variant="contained" className={['buttonSecondary','mt-5'].join(" ")} type="submit" startIcon={<CheckIcon />} onClick={saveAnimal}>Sauvegarder</Button>
                </Box>
            </Fade>
        </Modal>

        </div>
    )
}

export default InscriptionClient;