import { React, useEffect, useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { Prompt } from 'react-router';
import { styled } from '@mui/material/styles';
import UserWidget from '../Dashboard/UserWidget';
import SideMenu from '../Dashboard/SideMenu';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import CheckIcon from '@mui/icons-material/Check';
import FolderOpenOutlinedIcon from '@mui/icons-material/FolderOpenOutlined';
import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import EditNoteIcon from '@mui/icons-material/EditNote';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import AddCardIcon from '@mui/icons-material/AddCard';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Skeleton, TextField, Button, SwipeableDrawer} from '@mui/material';
import Select from '@mui/material/Select';
import Badge from '@mui/material/Badge';
import Card from '@mui/material/Card';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import dayjs from 'dayjs';
import swal from 'sweetalert';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateField } from '@mui/x-date-pickers/DateField';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../App/App.css';

import {useCredentials} from '../../Services/authServices';
import {setVeterinaireDatas, deleteClient} from '../../Services/UserServices'; 
import {getCabDatas, getCabVeterinaires, saveCabinet} from '../../Services/cabinetServices';
import {getTypesPrestations, setTypePrestation, supprTypePrestation} from '../../Services/prestationsServices';
import {uploadPhoto} from '../../Services/FileServices';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

var max_id_types_prestation = 1;

function Parametres() {
 
    const { user, setUser } = useCredentials();
    const [cabinet, setCabinet] = useState({});
    const [needSave, setNeedSave] = useState({});
    const [activeTab, setActiveTab] = useState('1');
  
    const [nomErrorMsg, setNomErrorMsg] = useState();
    const [sirenErrorMsg, setSirenErrorMsg] = useState();
    const [cabTelErrorMsg, setCabTelErrorMsg] = useState();
    const [cabEmailErrorMsg, setCabEmailErrorMsg] = useState();
    const [collabErrorMsg, setCollabErrorMsg] = useState();

    const [maxIdCollab, setMaxIdCollab] = useState(0);
    const [collaborateurs, setCollaborateurs] = useState([]);
    const [editedCollab, setEditedCollab] = useState({});  
    const [photoError, setPhotoError] = useState();    
    const [nomErrorMsg2, setNomErrorMsg2] = useState();  
    const [prenomErrorMsg, setPrenomErrorMsg] = useState();   
    const [emailErrorMsg, setEmailErrorMsg] = useState();
    const [open, setOpen] = useState(false);
    const handleClose = () => setOpen(false);

    const liste_specialites = ['Médecine interne', 'Oncologie', 'Cardiologie', 'Chirurgie', 'Imagerie', 'Dermatologie', 'Neurologie', 'Reproduction', 'Anesthesie', 'Urgences & soins intensifs', 'Comportement'];

    const [typesPrestations, setTypesPrestations] = useState([]);
    const [usedTypesPrestations, setUsedTypesPrestations] = useState([]);
    const [erreursPrixPrestations, setErreursPrixPrestations] = useState([]);

    const [openPrest, setOpenPrest] = useState(false);
    const handleClosePrest = () => setOpenPrest(false);
    const [nomPrestation, setNomPrestation] = useState();
    const [nomPrestationError, setNomPrestationError] = useState();
    
    var max_id_prestation = 1;
    
    useEffect(() => {

        if(!user) { window.open('/login', '_self'); }
        else {
            getCabDatas(user).then(res => {
                if(res != undefined && res.data != undefined && res.data.statut == "ok") {   
                    let cabVals = {...res.data.donnees};  
                    delete cabVals.personnes;
                    delete cabVals.clients;
                    delete cabVals.rdv;   
                    delete cabVals.demandes_rdv;         
                    setCabinet(cabVals);

                    let erreursPrix = [...erreursPrixPrestations];
                    cabVals.prestations.map((p, i) => {
                        erreursPrix[p.id] = null;
                    });
                    setErreursPrixPrestations(erreursPrix);

                    getTypesPrestations(cabVals.id).then(res_t => {
                        if(res_t != undefined && res_t.data != undefined && res_t.data.statut == "ok") {
                            setTypesPrestations(res_t.data.donnees);
                            max_id_types_prestation = res.data.next_id;
                        }
                    });
            
                    let _usedTypesP = [];
                    if(cabVals.prestations) {
                        cabVals.prestations.map((p, i) => {
                            if(p.id >= max_id_prestation) { max_id_prestation = (p.id+1); }       
                            if(_usedTypesP.indexOf(p.type_prestation) == -1) { _usedTypesP.push(p.type_prestation); }          
                        }); 
                    }
                    setUsedTypesPrestations(_usedTypesP); 
                }
            });   

            getCabVeterinaires(user).then(res => {
                if(res != undefined && res.data != undefined && res.data.statut == "ok") {
                    setCollaborateurs(res.data.donnees);
                }
            });
        } 

    }, [user]);    


    const handleNeedSave = (val) => {
        setNeedSave(val);  
        if(val) {
            window.onbeforeunload = () => true
        } else {
            window.onbeforeunload = undefined
        }
    }

    const changeActiveTab = (event, newValue) => {
        setActiveTab(newValue);
    };


    const handleChange = (e) => {
        let cabVals = {...cabinet};
        cabVals[e.target.id] = e.target.value;
        setCabinet(cabVals);
        handleNeedSave(true);        
    }

    const save = (e) => {
        var nb_errors = 0;
        if(!cabinet.nom || cabinet.nom.length == 0) { setNomErrorMsg('Ce champ est obligatoire'); nb_errors++; }
        if(!cabinet.siren || cabinet.siren.length == 0) { setSirenErrorMsg('Ce champ est obligatoire'); nb_errors++; }
        if(!cabinet.telephone || cabinet.telephone.length == 0) { setCabTelErrorMsg('Ce champ est obligatoire'); nb_errors++; }
        if(!cabinet.email || cabinet.email.length == 0) { setCabEmailErrorMsg('Ce champ est obligatoire'); nb_errors++; }

        let erreursPrix = [...erreursPrixPrestations];
        if(cabinet.prestations) {
            cabinet.prestations.map((p, i) => {   
                if(p.prix_fixe == "") p.prix_fixe = 0;
                if(p.prix_horaire == "") p.prix_horaire = 0;         
                if(p.prix_horaire == 0 && p.prix_fixe == 0) { erreursPrix[p.id] = 'Le prix doit être supérieur à 0'; nb_errors++; }
                else erreursPrix[p.id] = null;
            });
        }
        if(cabinet.prestations_ref) {
            cabinet.prestations_ref.map((p, i) => {   
                if(p.prix_fixe == "") p.prix_fixe = 0;
                if(p.prix_horaire == "") p.prix_horaire = 0;         
                if(p.prix_horaire == 0 && p.prix_fixe == 0) { erreursPrix[p.id] = 'Le prix doit être supérieur à 0'; nb_errors++; }
                else erreursPrix[p.id] = null;
            });
        }
        setErreursPrixPrestations(erreursPrix);

        if(nb_errors == 0) {
            saveCabinet(cabinet, cabinet.id, user).then(res => {
                if(res != undefined && res.data != undefined && res.data.statut == "ok") {
                    handleNeedSave(false);             
                }
            });   
        }
    }


    
    const changeEditedCollab = (id_collab) => {
        let collabVals = {};
        collaborateurs.map((c, i) => {
            if(c.id == id_collab) { collabVals = {...c}; }
        });        
        setEditedCollab(collabVals);
        setOpen(true);
    }

    const addCollab = () => {
        setEditedCollab({'id':'new', 'id_collab': (maxIdCollab+1)});
        setOpen(true);
        setMaxIdCollab(maxIdCollab+1);
    }

    const updateCollab = (e) => {

        if(!editedCollab.nom || editedCollab.nom.length == 0) { setNomErrorMsg2('Ce champ est obligatoire'); }
        else { setNomErrorMsg2(null); }
        if(!editedCollab.prenom || editedCollab.prenom.length == 0) { setPrenomErrorMsg('Ce champ est obligatoire'); }
        else { setPrenomErrorMsg(null); }
        if(!editedCollab.email || editedCollab.email.length == 0) { setEmailErrorMsg('Ce champ est obligatoire'); }
        else { setCollabErrorMsg(null); }

        let collabVals = {...editedCollab};
        collabVals[e.target.id] = e.target.value;
        setEditedCollab(collabVals);
    }
    const updateCollabPoste = (e) => {
        let collabVals = {...editedCollab};
        collabVals['poste'] = e.target.value;
        setEditedCollab(collabVals); 
    }
    const updateCollabSpecialite = (e) => {
        let collabVals = {...editedCollab};
        collabVals['specialite'] = e.target.value;
        setEditedCollab(collabVals);
    }
    const updateCollabSexe = (e) => {
        let collabVals = {...editedCollab};
        collabVals['sexe'] = e.target.value;
        setEditedCollab(collabVals);
    }

    const handlePhotoChange = (e) => {

        if (e.target.files) {

            const photo = e.target.files[0];
            if(photo.size > 5000000) { setPhotoError('L\'image est trop lourde'); }
            else if(photo.type != 'image/jpeg' && photo.type != 'image/png') { setPhotoError('Le fichier n\'est pas une image'); }
            else {
                setPhotoError(null);
                uploadPhoto(photo).then((res) => {
                    if(res != undefined && res.data != undefined && res.data.statut == "ok") {
                        let collabVals = {...editedCollab};
                        collabVals['photo'] = res.data.filename;
                        setEditedCollab(collabVals);
                    }
                });                
            }            
        }
    }

    const saveCollab = () => {

        let nb_errors = 0;
        if(!editedCollab.nom || editedCollab.nom.length == 0) { setNomErrorMsg2('Ce champ est obligatoire'); nb_errors++; }
        else { setNomErrorMsg2(null); }
        if(!editedCollab.prenom || editedCollab.prenom.length == 0) { setPrenomErrorMsg('Ce champ est obligatoire'); nb_errors++; }
        else { setPrenomErrorMsg(null); }
        if(!editedCollab.email || editedCollab.email.length == 0) { setEmailErrorMsg('Ce champ est obligatoire'); nb_errors++; }
        else { setCollabErrorMsg(null); }

        if(nb_errors == 0) {
            setVeterinaireDatas(user, editedCollab.id, editedCollab).then((res) => {                  
                setCollabErrorMsg(null);
                let liste_collab = [...collaborateurs];
                let found = false;
                liste_collab.map((c, i) => {
                    if(c.id == editedCollab.id) { 
                        liste_collab[i] = editedCollab; 
                        found = true;
                    }
                });
                if(!found) { liste_collab.push(editedCollab); }
                setCollaborateurs(liste_collab);
                setOpen(false);
            });
        }        
    }

    const supprCollab = (id_collab) => {

        swal({
            title: "Êtes-vous sûr(e) ?",
            text: "Cette action supprimera le patient avec ses animaux, ses messages et ses rendez-vous.",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          })
          .then((willDelete) => {
            if(willDelete) {
                deleteClient(user, id_collab).then(res => {
                    if(res != undefined && res.data != undefined && res.data.statut == "ok") {
                        let liste_collab = [...collaborateurs];
                        liste_collab.map((c, i) => {
                            if(c.id == id_collab) { liste_collab.splice(i, 1); }
                        });
                        setCollaborateurs(liste_collab);
                    }
                });
            }
        });
    }


    
    const addPrestation = (type = "std") => {
        let cabVals = {...cabinet};
        if(!cabVals.prestations) { cabVals.prestations = []; }
        if(!cabVals.prestations_ref) { cabVals.prestations_ref = []; }

        let typePDispo = 0;
        typesPrestations.map((t, i) => {
            if(usedTypesPrestations.indexOf(t.id) == -1) { typePDispo = i; }
        });

        (type == "ref" ? cabVals.prestations_ref : cabVals.prestations).push({
            'id': {...max_id_prestation},
            'type_prestation': typesPrestations[typePDispo].id,
            'nom_prestation': typesPrestations[typePDispo].nom,
            'a_distance': true,
            'delai': '1J',
            'prix_fixe': 0.0,
            'prix_horaire': 0.0
        });
        setCabinet(cabVals); 

        let _usedTypesPrestations = [...usedTypesPrestations];
        if(_usedTypesPrestations.indexOf(typesPrestations[typePDispo].id) == -1) { 
            _usedTypesPrestations.push(typesPrestations[typePDispo].id); 
            setUsedTypesPrestations(_usedTypesPrestations);
        }

        max_id_prestation++;
    }

    const ajoutePrestationPerso = () => {

        if(!nomPrestation || nomPrestation.length == 0) { setNomPrestationError('Ce champ est obligatoire'); }
        else { 
            setTypePrestation(user, cabinet.id, nomPrestation).then(res => {
                if(res != undefined && res.data != undefined && res.data.statut == "ok") {
                    setNomPrestationError(null); 

                    let _types = [...typesPrestations];
                    _types.push({id: res.data.id_type, nom: nomPrestation});
                    setTypesPrestations(_types);
                }
            });       
            setOpenPrest(false); 
            setNomPrestation(null);     
        }        
    }

    const supprPrestationPerso = (e, t_id) => {

        e.preventDefault(true);

        supprTypePrestation(user, t_id).then((res) => {
            let _types = [...typesPrestations];            
            _types.map((t, i) => {
                if(t.id == t_id) { _types.splice(i, 1); }
            });
            setTypesPrestations(_types);
        });
    }


    const updateTypePrestation = (p_id, val, type='std') => {        
        let cabVals = {...cabinet};
        let _usedTypesP = [];

        if(val == -1) { setOpenPrest(true); }
        else {
            (type == "ref" ? cabVals.prestations_ref : cabVals.prestations).map((p, i) => {
                if(p.id == p_id) { 
                    p.type_prestation = val; 
                    typesPrestations.map((t, j) => {
                        if(t.id == val) { p.nom_prestation = t.nom; }
                    });
                }
                if(_usedTypesP.indexOf(p.type_prestation) == -1) { _usedTypesP.push(p.type_prestation); }
            });
            setCabinet(cabVals);
            setUsedTypesPrestations(_usedTypesP);
            handleNeedSave(true);
        }        
    }
    const updateADistance = (p_id, val, type='std') => {        
        let cabVals = {...cabinet};
        (type == "ref" ? cabVals.prestations_ref : cabVals.prestations).map((p, i) => {
            if(p.id == p_id) { 
                p.a_distance = val;
                if(val == false) { p.prix_horaire = 0; }
            }
        });
        setCabinet(cabVals); 
        handleNeedSave(true);
    }  
    const updateDelai = (p_id, val, type='std') => {        
        let cabVals = {...cabinet};
        (type == "ref" ? cabVals.prestations_ref : cabVals.prestations).map((p, i) => {
            if(p.id == p_id) { p.delai = val; }
        });
        setCabinet(cabVals); 
        handleNeedSave(true);
    }
    const updatePrixFPrestation = (p_id, prix, type='std') => {

        if(typeof prix == "string") { prix = prix.replace(/[^0-9]/g, ''); }
        if(prix == "") prix = 0;

        let cabVals = {...cabinet};
        (type == "ref" ? cabVals.prestations_ref : cabVals.prestations).map((p, i) => {
            if(p.id == p_id) { p.prix_fixe = prix; }
        });
        setCabinet(cabVals);
        handleNeedSave(true);
    }
    const updatePrixHPrestation = (p_id, prix, type='std') => {

        if(typeof prix == "string") { prix = prix.replace(/[^0-9]/g, ''); }
        if(prix == "") prix = 0;

        let cabVals = {...cabinet};
        (type == "ref" ? cabVals.prestations_ref : cabVals.prestations).map((p, i) => {
            if(p.id == p_id) { p.prix_horaire = prix; }
        });
        setCabinet(cabVals);
        handleNeedSave(true); 
    }

    const supprPrestation = (p_id, type='std') => {
        let cabVals = {...cabinet};        
        let _usedTypesP = [];
        (type == "ref" ? cabVals.prestations_ref : cabVals.prestations).map((p, i) => {
            if(p.id == p_id) { (type == "ref" ? cabVals.prestations_ref : cabVals.prestations).splice(i, 1); }    
            else if(_usedTypesP.indexOf(p.type_prestation) == -1) { _usedTypesP.push(p.type_prestation); }     
        });
        setCabinet(cabVals);
        setUsedTypesPrestations(_usedTypesP);
        handleNeedSave(true);     
    }

    
    const listeTypesPrestations = (typesPrestations != undefined && typesPrestations.length > 0 ? 
        typesPrestations.map((t, i) => <MenuItem disabled={(usedTypesPrestations.indexOf(t.id) > -1)} key={t.id} value={t.id} className={['d-flex','justify-content-between'].join(" ")}>
            <span>{t.nom}</span>
            {t.id > 4 && <IconButton className={['ms-2','smallIconButton'].join(" ")} aria-label="retour" onClick={(e) => supprPrestationPerso(e, t.id)}><DeleteOutlineIcon/></IconButton>}
        </MenuItem>) : 
    []);
    listeTypesPrestations.push(<MenuItem key={-1} value={-1} className={['textSecondary','italic'].join(" ")}><span>+ Prestation personnalisée</span></MenuItem>)


    if(user.is_veto) {
        return (
          <div className="fullpage">
            <Container className='full_container'>
              
                <UserWidget /> 
                <SideMenu user={user} setUser={setUser}/>
                
                <Row className={(user.menuClosed ? 'withSidemenu-closed' : 'withSidemenu')}>
                    <Col md={12} className={['ps-5','py-5','hmax-100'].join(" ")}>
        
                    <Row>
                        <Col md={4}>
                        <h2>{cabinet.nom}</h2>
                        </Col>
                        <Col md={4} className={['d-flex','align-items-baseline'].join(" ")}>
                            {needSave == true ? (<Card className={['mx-2','p-2','waiting'].join(" ")} title="Sauvegarder">
                                <Button variant="text" className={['listButton','p-2'].join(" ")} onClick={save}>
                                    <SaveIcon className='me-3'/>
                                    <span>Sauvegarder</span>
                                </Button>
                            </Card>) : (<Card className={['mx-2','p-2','align-items-center','success'].join(" ")}>
                                <Button variant="text" className={['listButton','success','p-2'].join(" ")}>
                                    <CheckIcon className='me-3'/>
                                    <span>A jour</span>
                                </Button>
                            </Card>)}
                        </Col>
                    </Row>
    
                    <Row className='mt-4'>
                        <Col md={12} className="pb-5">
                            <div className={['corpsFiche','borderLeft'].join(" ")}>
                                <TabContext value={activeTab}>
                                    <TabList onChange={changeActiveTab} className='px-2'>
                                        <Tab label="Informations" value="1" />
                                        <Tab label={"Collaborateurs ("+collaborateurs.length+")"} value="2" />
                                        <Tab label="Prestations" value="3" />
                                    </TabList>
                                    <TabPanel value="1">
                                        <Col md={9}>
                                        <Row>
                                            <Col>
                                                <TextField 
                                                    required
                                                    id="nom" 
                                                    label="Nom du cabinet" 
                                                    variant="standard"
                                                    value={cabinet && cabinet.nom ? cabinet.nom : ''}
                                                    onChange={handleChange}
                                                    className="mt-2 mb-1 w-100"
                                                    error={!!nomErrorMsg}
                                                    helperText={nomErrorMsg}
                                                />
                                                <TextField 
                                                    id="telephone" 
                                                    label="Téléphone" 
                                                    variant="standard" 
                                                    value={cabinet && cabinet.telephone ? cabinet.telephone : ''}
                                                    onChange={handleChange}
                                                    className="mt-2 mb-1 w-100"
                                                    error={!!cabTelErrorMsg}
                                                    helperText={cabTelErrorMsg}
                                                />                                                
                                            </Col>
                                            <Col md={6}>
                                                <TextField 
                                                    required
                                                    id="siren" 
                                                    label="SIREN" 
                                                    variant="standard" 
                                                    value={cabinet && cabinet.siren ? cabinet.siren : ''}
                                                    onChange={handleChange}
                                                    className="mt-2 mb-1 w-100"
                                                    error={!!sirenErrorMsg}
                                                    helperText={sirenErrorMsg}
                                                />
                                                <TextField 
                                                    id="email" 
                                                    label="Email" 
                                                    variant="standard" 
                                                    value={cabinet && cabinet.email ? cabinet.email : ''}
                                                    onChange={handleChange}
                                                    className="mt-2 mb-1 w-100"
                                                    error={!!cabEmailErrorMsg}
                                                    helperText={cabEmailErrorMsg}
                                                />
                                            </Col>
                                        </Row>

                                        <p className={['titre','bold','mt-5','mb-1'].join(" ")}>Adresse</p>
                                        <Row>
                                            <Col md={6}>
                                                <TextField 
                                                    id="adresse" 
                                                    label="Adresse" 
                                                    variant="standard" 
                                                    value={cabinet && cabinet.adresse ? cabinet.adresse : ''}
                                                    onChange={handleChange}
                                                    className="mt-2 mb-1 w-100"
                                                />
                                                <TextField 
                                                    id="ville" 
                                                    label="Ville" 
                                                    variant="standard" 
                                                    value={cabinet && cabinet.ville ? cabinet.ville : ''}
                                                    onChange={handleChange}
                                                    className="mt-2 mb-1 w-100"
                                                />
                                                
                                            </Col>
                                            <Col md={3}>
                                                <TextField 
                                                    id="cp" 
                                                    label="Code postal" 
                                                    variant="standard" 
                                                    value={cabinet && cabinet.cp ? cabinet.cp : ''}
                                                    onChange={handleChange}
                                                    className="mt-2 mb-1 w-100"
                                                />
                                            </Col>
                                        </Row>

                                        <p className={['titre','bold','mt-5','mb-1'].join(" ")}>Liens sociaux</p>
                                        <Row>
                                            <Col md={4}>
                                                <TextField 
                                                    id="site" 
                                                    label="Site web" 
                                                    variant="standard" 
                                                    value={cabinet && cabinet.site ? cabinet.site : ''}
                                                    onChange={handleChange}
                                                    className="mt-2 mb-1 w-100"
                                                />                                
                                            </Col>
                                            <Col md={4}>
                                                <TextField 
                                                    id="facebook" 
                                                    label="Facebook" 
                                                    variant="standard" 
                                                    value={cabinet && cabinet.facebook ? cabinet.facebook : ''}
                                                    onChange={handleChange}
                                                    className="mt-2 mb-1 w-100"
                                                />
                                            </Col>
                                        </Row>
                                        </Col>
                                    </TabPanel>
                                    <TabPanel value="2">
                                        <Col md={6}>
                                        {collaborateurs.length > 0 ? collaborateurs.map((p, i) => {	
                                            var mt = (i > 0 ? "mt-3" : "mt-1");
                                            return (<div className = {mt} key={p.id} >
                                                <Card className={['px-4','py-3','mt-3', 'w-auto'].join(" ")}>
                                                    <Row className="align-items-center">
                                                        <Col md={6} className='ps-4'>
                                                            <p className="bigText">{p.nom}</p>
                                                            <p className="smallText">{p.prenom}</p>
                                                        </Col>
                                                        <Col md={6} className="textRight">
                                                            <IconButton aria-label="modifier" className="mr-2" onClick={() => changeEditedCollab(p.id)}>
                                                                <EditNoteIcon/>
                                                            </IconButton>
                                                            <IconButton aria-label="supprimer" className="textRed" onClick={() => supprCollab(p.id)}>
                                                                <DeleteOutlineIcon/>
                                                            </IconButton>
                                                        </Col>
                                                    </Row>
                                                </Card>
                                            </div>)
                                            }) : (<div></div>)
                                        }
                                        {collabErrorMsg && <p className='textRed'>{collabErrorMsg}</p>} 
                                          
                                        <div className={['mt-2','pt-2'].join(" ")}>
                                            <IconButton aria-label="ajouter" onClick={addCollab}>
                                                <AddBoxOutlinedIcon />
                                            </IconButton> 
                                        </div>
                                        </Col>
                                    </TabPanel>
                                    <TabPanel value="3">   
                                    {cabinet.licence && cabinet.licence.avec_consultations == true && <div>

                                        <p className={['bigText','textSecondary','mt-1','mb-1'].join(" ")}>Consultations générales</p>

                                        {cabinet.prestations && cabinet.prestations.length > 0 ? cabinet.prestations.map((p, i) => {	
                                            var mt = (i > 0 ? "mt-3" : "mt-1");
                                            return (<div className = {mt} key={p.id}>
                                                <Row className="align-items-center">
                                                    <Col md={3}>
                                                        <FormControl variant="standard" sx={{ width: '100%' }} className='mt-2'>
                                                            <Select
                                                                id="pst-type_prestation"
                                                                value={p.type_prestation}
                                                                onChange={e => {updateTypePrestation(p.id, e.target.value);}}
                                                                >
                                                                {listeTypesPrestations}
                                                            </Select>
                                                            <FormHelperText>Type de prestation</FormHelperText>
                                                        </FormControl>
                                                    </Col>
                                                    <Col md={2} className='px-2'>
                                                        <FormControl variant="standard" sx={{ width: '100%' }} className='mt-2'>
                                                            <Select
                                                                id="pst-a_distance"
                                                                value={p.a_distance}
                                                                onChange={e => {updateADistance(p.id, e.target.value);}}
                                                                >
                                                                <MenuItem value={true}>Téléconsultation</MenuItem>
                                                                <MenuItem value={false}>Rdv physique</MenuItem>
                                                            </Select>
                                                            <FormHelperText>Format</FormHelperText>
                                                        </FormControl>
                                                    </Col>
                                                    <Col md={1}>
                                                        <FormControl variant="standard">
                                                            <Input
                                                                id="prix_fixe"
                                                                endAdornment={<InputAdornment position="end">€</InputAdornment>}
                                                                aria-describedby="prix_fixe-text"
                                                                value={p.prix_fixe}
                                                                onChange={e => {updatePrixFPrestation(p.id, e.target.value);}}
                                                                error={!!erreursPrixPrestations[p.id]}
                                                                helperText={erreursPrixPrestations[p.id]}
                                                            />
                                                            <FormHelperText id="prix_fixe-text">Prix Fixe</FormHelperText>
                                                        </FormControl>
                                                    </Col>
                                                    {p.a_distance == true && <Col md={1}>
                                                        <FormControl variant="standard">
                                                            <Input
                                                                id="prix_horaire"
                                                                endAdornment={<InputAdornment position="end">€</InputAdornment>}
                                                                aria-describedby="prix_horaire-text"
                                                                value={p.prix_horaire}
                                                                onChange={e => {updatePrixHPrestation(p.id, e.target.value);}}
                                                                error={!!erreursPrixPrestations[p.id]}
                                                                helperText={erreursPrixPrestations[p.id]}
                                                            />
                                                            <FormHelperText id="prix_horaire-text">Prix /min.</FormHelperText>
                                                        </FormControl>
                                                    </Col>}
                                                    <Col md={1}>
                                                        <IconButton aria-label="supprimer" className="textRed" onClick={() => supprPrestation(p.id)}>
                                                            <DeleteOutlineIcon data-id={p.id} />
                                                        </IconButton>
                                                    </Col>
                                                    <Col md={4} className='ps-0'>
                                                        {erreursPrixPrestations[p.id] && <small className='textRed'>{erreursPrixPrestations[p.id]}</small>}
                                                    </Col>
                                                </Row>
                                            </div>)
                                            }) : (<div></div>)
                                        }    
                                        <div className={['d-flex','align-items-center','mt-3'].join(" ")}>
                                            <Card className={['w-auto','p-2','pe-3'].join(" ")}>
                                                <IconButton aria-label="supprimer" className="me-2" onClick={addPrestation}>
                                                    <AddBoxOutlinedIcon />
                                                </IconButton> 
                                                Ajouter une prestation
                                            </Card>
                                        </div>
                                    </div>}
                                    {cabinet.licence && cabinet.licence.avec_cas_ref == true && <div>
                                         
                                        <p className={['bigText','textSecondary','mt-5','mb-1'].join(" ")}>Cas référés</p>

                                        {cabinet.prestations_ref && cabinet.prestations_ref.length > 0 ? cabinet.prestations_ref.map((p, i) => {	
                                            var mt = (i > 0 ? "mt-3" : "mt-1");
                                            return (<div className = {mt} key={p.id}>
                                                <Row className="align-items-center">
                                                    <Col md={3}>
                                                        <FormControl variant="standard" sx={{ width: '100%' }} className='mt-2'>
                                                            <Select
                                                                id="pst-type_prestation"
                                                                value={p.type_prestation}
                                                                onChange={e => {updateTypePrestation(p.id, e.target.value, 'ref');}}
                                                                >
                                                                {listeTypesPrestations}
                                                            </Select>
                                                            <FormHelperText>Type de prestation</FormHelperText>
                                                        </FormControl>
                                                    </Col>
                                                    <Col md={2} className='px-2'>
                                                        <FormControl variant="standard" sx={{ width: '100%' }} className='mt-2'>
                                                            <Select
                                                                id="pst-a_distance"
                                                                value={p.a_distance}
                                                                onChange={e => {updateADistance(p.id, e.target.value, 'ref');}}
                                                                >
                                                                <MenuItem value={true}>Téléconsultation</MenuItem>
                                                                <MenuItem value={false}>Rdv physique</MenuItem>
                                                            </Select>
                                                            <FormHelperText>Format</FormHelperText>
                                                        </FormControl>
                                                    </Col>
                                                    <Col md={2} className='px-2'>
                                                        <FormControl variant="standard" sx={{ width: '100%' }} className='mt-2'>
                                                            <Select
                                                                id="pst-delai"
                                                                value={p.delai}
                                                                onChange={e => {updateDelai(p.id, e.target.value, 'ref');}}
                                                                >
                                                                <MenuItem value='1H'>1H</MenuItem>
                                                                <MenuItem value='1J'>1J</MenuItem>
                                                                <MenuItem value='2J'>2J</MenuItem>
                                                                <MenuItem value='4J'>4J</MenuItem>
                                                            </Select>
                                                            <FormHelperText>Délai de réponse</FormHelperText>
                                                        </FormControl>
                                                    </Col>
                                                    <Col md={1}>
                                                        <FormControl variant="standard">
                                                            <Input
                                                                id="prix_fixe"
                                                                endAdornment={<InputAdornment position="end">€</InputAdornment>}
                                                                aria-describedby="prix_fixe-text"
                                                                value={p.prix_fixe}
                                                                onChange={e => {updatePrixFPrestation(p.id, e.target.value, 'ref');}}
                                                                error={!!erreursPrixPrestations[p.id]}
                                                                helperText={erreursPrixPrestations[p.id]}
                                                            />
                                                            <FormHelperText id="prix_fixe-text">Prix Fixe</FormHelperText>
                                                        </FormControl>
                                                    </Col>
                                                    {p.a_distance == true && <Col md={1}>
                                                        <FormControl variant="standard">
                                                            <Input
                                                                id="prix_horaire"
                                                                endAdornment={<InputAdornment position="end">€</InputAdornment>}
                                                                aria-describedby="prix_horaire-text"
                                                                value={p.prix_horaire}
                                                                onChange={e => {updatePrixHPrestation(p.id, e.target.value, 'ref');}}
                                                                error={!!erreursPrixPrestations[p.id]}
                                                                helperText={erreursPrixPrestations[p.id]}
                                                            />
                                                            <FormHelperText id="prix_horaire-text">Prix /min.</FormHelperText>
                                                        </FormControl>
                                                    </Col>}
                                                    <Col md={1}>
                                                        <IconButton aria-label="supprimer" className="textRed" onClick={() => supprPrestation(p.id, 'ref')}>
                                                            <DeleteOutlineIcon data-id={p.id} />
                                                        </IconButton>
                                                    </Col>
                                                    <Col md={2} className='ps-0'>
                                                        {erreursPrixPrestations[p.id] && <small className='textRed'>{erreursPrixPrestations[p.id]}</small>}
                                                    </Col>
                                                </Row>
                                            </div>)
                                            }) : (<div></div>)
                                        }    
                                        <div className={['d-flex','align-items-center','mt-3'].join(" ")}>
                                            <Card className={['w-auto','p-2','pe-3'].join(" ")}>
                                                <IconButton aria-label="supprimer" className="me-2" onClick={() => addPrestation('ref')}>
                                                    <AddBoxOutlinedIcon />
                                                </IconButton> 
                                                Ajouter une prestation
                                            </Card>
                                        </div>
                                    </div>}

                                    </TabPanel>
                                </TabContext>
                            </div>
                        </Col>                
                    </Row>
                </Col>                
            </Row>
        </Container>

        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: { timeout: 500 },
            }}
        >
            <Fade in={open}>
                <Box>
                    <p className={['titre','bold','mb-2'].join(" ")}>{editedCollab.prenom || editedCollab.nom ? ((editedCollab.prenom ? editedCollab.prenom : '') + ' ' + (editedCollab.nom ? editedCollab.nom : '')) : 'Nouveau collaborateur'}</p>

                    <IconButton aria-label="modifier" className="modalExit" onClick={handleClose}>
                        <CloseIcon/>
                    </IconButton>

                    <Row className='mt-3'>
                        <Col md={6} className='text-center'>
                            <div className={['d-flex','align-items-center','justify-content-center', 'position-relative'].join(" ")}>
                                <div className="animal_photo" alt="animal_photo" style={{ backgroundImage: `url(${process.env.REACT_APP_IMG_URL}${editedCollab.photo})` }}>
                                </div>
                                <Button component="label" className='photoButton' variant="contained" startIcon={<AddAPhotoIcon />}>
                                    <VisuallyHiddenInput type="file" onChange={handlePhotoChange}/>
                                </Button>
                            </div>
                            {photoError && <small className='textRed'>{photoError}</small>}
                        </Col>
                        <Col md={6}>
                            <TextField 
                                id="nom" 
                                label="Nom" 
                                variant="standard" 
                                value={editedCollab.nom ? editedCollab.nom : ''}
                                onChange={updateCollab}
                                className="mt-2 mb-1 w-100"
                                error={!!nomErrorMsg2}                                
                                helperText={nomErrorMsg2}
                            />
                            <TextField 
                                id="prenom" 
                                label="Prénom" 
                                variant="standard" 
                                value={editedCollab.prenom ? editedCollab.prenom : ''}
                                onChange={updateCollab}
                                className="mt-2 mb-1 w-100"
                                error={!!prenomErrorMsg}                                
                                helperText={prenomErrorMsg}
                            />
                            <FormControl className="mt-3 mb-1">
                                <FormLabel id="sexe-label">Sexe</FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby="sexe-label"
                                    id="sexe"
                                    name="sexe" 
                                    value={editedCollab.sexe != undefined ? editedCollab.sexe : 'H'}
                                    onChange={updateCollabSexe}                                    
                                >
                                    <FormControlLabel value="H" control={<Radio />} label="Homme" />
                                    <FormControlLabel value="F" control={<Radio />} label="Femme" />
                                </RadioGroup>
                            </FormControl>                            
                            <TextField 
                                id="email" 
                                label="Email" 
                                variant="standard" 
                                value={editedCollab.email ? editedCollab.email : ''}
                                onChange={updateCollab}
                                className="mt-2 mb-1 w-100"
                                error={!!emailErrorMsg}                                
                                helperText={emailErrorMsg}
                            />
                        </Col>
                    </Row>
                    <Row className='mt-3'>
                        <Col md={6} className='ps-3'>
                            <FormControl variant="standard" sx={{ width: '100%' }} className='mt-2'>
                                <InputLabel id="poste-label">Poste</InputLabel>
                                <Select
                                    labelId="poste-label"
                                    id="poste"
                                    value={editedCollab.poste ? editedCollab.poste : ''}
                                    onChange={updateCollabPoste}
                                    label="Poste"
                                    >
                                    <MenuItem key='1' value='veterinaire'>Vétérinaire généraliste</MenuItem>
                                    <MenuItem key='2' value='specialiste'>Vétérinaire spécialiste</MenuItem>
                                    <MenuItem key='3' value='assistant'>Assistant(e) vétérinaire</MenuItem>
                                    <MenuItem key='4' value='secretaire'>Secrétaire</MenuItem>
                                </Select>
                            </FormControl>
                            {editedCollab.poste && (editedCollab.poste == 'veterinaire' || editedCollab.poste == 'specialiste') && 
                                <TextField 
                                    id="numero_ordre" 
                                    label="Numéro d'ordre des vétérinaires" 
                                    variant="standard" 
                                    value={editedCollab.numero_ordre ? editedCollab.numero_ordre : ''}
                                    onChange={updateCollab}
                                    className="mt-2 mb-1 w-100"
                                />
                            }
                            {editedCollab.poste && editedCollab.poste == 'specialiste' && 
                                <FormControl variant="standard" sx={{ width: '100%' }} className='mt-2'>
                                    <InputLabel id="specialite-label">Spécialité</InputLabel>
                                    <Select
                                        labelId="specialite-label"
                                        id="specialite"
                                        value={editedCollab.specialite ? editedCollab.specialite : ''}
                                        onChange={updateCollabSpecialite}
                                        label="Spécialité"
                                        placeholder="Spécialité"
                                        >          
                                        {liste_specialites.map((sp, i) => {return (<MenuItem key={i} value={sp}>{sp}</MenuItem>)} )}   
                                    </Select>
                                </FormControl>
                            }                           
                        </Col>
                        <Col md={6}>                      
                            <p className='smallText'>Présentation</p>
                            <BaseTextareaAutosize 
                                id="notes" 
                                label="Présentation" 
                                variant="standard" 
                                value={editedCollab.notes ? editedCollab.notes : ''}
                                onChange={updateCollab}
                                className="mt-1 mb-1 w-100"
                                minRows={3}
                                placeholder="..." 
                            />
                        </Col>
                    </Row>

                    <Button variant="contained" className={['buttonSecondary','mt-5'].join(" ")} type="submit" startIcon={<CheckIcon />} onClick={saveCollab}>Sauvegarder</Button>
                </Box>
            </Fade>
        </Modal>

        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={openPrest}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: { timeout: 500 },
            }}
        >
            <Fade in={openPrest}>
                <Box>
                    <p className={['titre','bold','mb-2'].join(" ")}>Prestation personnalisée</p>

                    <IconButton aria-label="modifier" className="modalExit" onClick={handleClosePrest}>
                        <CloseIcon/>
                    </IconButton>

                    <TextField 
                        id="nom" 
                        label="Nom" 
                        variant="standard"
                        className="mt-2 mb-1 w-100"
                        value={nomPrestation}
                        onChange={(e) => setNomPrestation(e.target.value)}
                        error={!!nomPrestationError}                                
                        helperText={nomPrestationError}
                    />

                    <Button variant="contained" className={['buttonSecondary','mt-5'].join(" ")} type="submit" startIcon={<CheckIcon />} onClick={ajoutePrestationPerso}>Sauvegarder</Button>
                </Box>
            </Fade>
        </Modal>

        <Prompt
            when={needSave}
            message='You have unsaved changes, are you sure you want to leave?'
        />
        </div>);
    } else {
        return <Redirect to={"/client/"+user.id} />
    }
}

export default Parametres;