import { React, useEffect, useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { Prompt } from 'react-router';
import { styled } from '@mui/material/styles';
import UserWidget from '../Dashboard/UserWidget';
import SideMenu from '../Dashboard/SideMenu';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import CheckIcon from '@mui/icons-material/Check';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import FolderOpenOutlinedIcon from '@mui/icons-material/FolderOpenOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import EditNoteIcon from '@mui/icons-material/EditNote';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import AddCardIcon from '@mui/icons-material/AddCard';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import CircularProgress from '@mui/material/CircularProgress';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Skeleton, TextField, Button, SwipeableDrawer} from '@mui/material';
import Badge from '@mui/material/Badge';
import Card from '@mui/material/Card';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import IconButton from '@mui/material/IconButton';
import dayjs from 'dayjs';
import swal from 'sweetalert';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateField } from '@mui/x-date-pickers/DateField';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import Select from 'react-select'
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../App/App.css';

import {useCredentials, getByToken} from '../../Services/authServices';
import {getClientDatas, setClientDatas, sendInvitation} from '../../Services/UserServices'; 
import {getEspeces, getRaces, setAnimal, deleteAnimal} from '../../Services/animalServices';
import {getRdvs, setRdv, demarreRdv} from '../../Services/RDVServices';
import {setMandat} from '../../Services/GCServices';
import {uploadPhoto} from '../../Services/FileServices';
import {getTypesPrestations} from '../../Services/prestationsServices';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

function FicheClient() {

    const { id } = useParams();  
    const { user, setUser } = useCredentials();
    const [client, setClient] = useState({});
    const [needSave, setNeedSave] = useState({});
    const [emailErrorMsg, setEmailErrorMsg] = useState();

    const [activeTab, setActiveTab] = useState('1');

    const [listeEspeces, setListeEspeces] = useState([]);
    const [listeRaces, setListeRaces] = useState([]);    
    const [editedAnimal, setEditedAnimal] = useState({});
    const [open, setOpen] = useState(false);
    const handleClose = () => setOpen(false);
    const [puceErrorMsg, setPuceErrorMsg] = useState();
    const [photoError, setPhotoError] = useState();   
    
    const [listeAnimaux, setListeAnimaux] = useState([]);
    
    const [loadingCall, setLoadingCall] = useState(false);
    const [editedRdv, setEditedRdv] = useState({});
    const [openRdv, setOpenRdv] = useState(false);
    const handleOpenRdv = () => setOpenRdv(true);
    const handleCloseRdv = () => setOpenRdv(false);    
    const [animalError, setAnimalError] = useState();  
    const [motifError, setMotifError] = useState();
    const [symptomesError, setSymptomesError] = useState();

    const [listeRdv, setListeRdv] = useState([]);
    const [listeDemandesRdv, setListeDemandesRdv] = useState([]);
    const [openDrawer, setOpenDrawer] = useState(false);

    const [typesPrestations, setTypesPrestations] = useState([]);
    
    useEffect(() => {

        if(!user) { window.open('/login', '_self'); }
        else {

            getEspeces(user).then(res => {
                if(res != undefined && res.data != undefined && res.data.statut == "ok") {                    
                    let optionsEspeces = [];
                    res.data.donnees.map((c, i) => {
                        optionsEspeces.push({value: c.id, label: c.nom});
                    });
                    setListeEspeces(optionsEspeces);
                }
            });

            getTypesPrestations(user.cabinet.id).then(res_t => {
                if(res_t != undefined && res_t.data != undefined && res_t.data.statut == "ok") {
                setTypesPrestations(res_t.data.donnees);
                }
            });

            if(id != 'new') { 
                if(!user.is_veto && id != user.id) { window.open('/client/'+user.id, '_self'); }
                getClientDatas(user, id).then(res => {
                    if(res != undefined && res.data != undefined && res.data.statut == "ok") {
                        setClient(res.data.donnees);
                        let optionsAnimaux = [];
                        res.data.donnees.animaux.map((c, i) => {
                        optionsAnimaux.push({value: c.id, label: c.nom});
                        }); 
                        setListeAnimaux(optionsAnimaux);
                        handleNeedSave(false);
                    }
                });

                getRdvs(user, id).then(res => {
                    if(res != undefined && res.data != undefined && res.data.statut == "ok") {            
                        setListeRdv(res.data.donnees.rdv);                
                        setListeDemandesRdv(res.data.donnees.demandes_rdv);
                    }
                });
            }
            else {
                setClient({
                    'id': null,
                    'nom': 'PATIENT',
                    'prenom': 'Nouveau'
                });
            }   
        } 

    }, [user]);    


    const handleNeedSave = (val) => {
        setNeedSave(val);  
        if(val) {
            window.onbeforeunload = () => true
        } else {
            window.onbeforeunload = undefined
        }
    }

    const changeActiveTab = (event: React.SyntheticEvent, newValue: string) => {
        setActiveTab(newValue);
    };


    const handleChange = (e) => {
        let clientVals = {...client};
        clientVals[e.target.id] = e.target.value;
        setClient(clientVals);
        handleNeedSave(true);        
    }

    const save = (e) => {
        if(client.email && client.email.length > 0) {
            setClientDatas(user, id, client).then(res => {
                if(res != undefined && res.data != undefined && res.data.statut == "ok") {
                    handleNeedSave(false);
                    if(id == 'new') { window.open('/client/'+res.data.id_client, '_self') }                
                }
            });   
        }
        else {
            setEmailErrorMsg("Email invalide");
        }  
    }

    const invitationClient = () => {
        sendInvitation(user, id).then(res => {
            if(res != undefined && res.data != undefined && res.data.statut == "ok") {
                swal({
                    title: "Envoyé !",
                    text: "Votre client à reçu son invitation par mail.",
                    icon: "success",
                    buttons: true,
                });
            }
        });
    }


    const changeEditedAnimal = (id_animal) => {

        let animalVals = {};
        client.animaux.map((a, i) => {
            if(a.id == id_animal) { animalVals = {...a}; }
        });        
        setEditedAnimal(animalVals);
        
        getRaces(user, animalVals.id_espece).then(res => {
            if(res != undefined && res.data != undefined && res.data.statut == "ok") {
                let optionsRaces = [];
                res.data.donnees.map((c, i) => {
                    optionsRaces.push({value: c.id, label: c.nom});
                });
                setListeRaces(optionsRaces);
            }
        });
        setOpen(true);
    }


    const addAnimal = () => {
        setEditedAnimal({'id':'new'});
        setOpen(true);
    }

    const updateAnimal = (e) => {
        let animalVals = {...editedAnimal};
        animalVals[e.target.id.replace('animal-', '')] = e.target.value;
        setEditedAnimal(animalVals);
    }
    const updateAnimalDateNaissance = (e) => {
        let animalVals = {...editedAnimal};
        animalVals['date_naissance'] = e['$y']+'-'+(e['$M']+1)+'-'+e['$D'];
        setEditedAnimal(animalVals);
    }
    const updateAnimalEspece = (e) => {
        let animalVals = {...editedAnimal};
        animalVals['id_espece'] = e.value;
        
        getRaces(user, e.value).then(res => {
            if(res != undefined && res.data != undefined && res.data.statut == "ok") {
                let optionsRaces = [];
                res.data.donnees.map((c, i) => {
                    optionsRaces.push({value: c.id, label: c.nom});
                });
                setListeRaces(optionsRaces);
                animalVals['id_race'] = res.data.donnees[0].id;
            }
        });

        setEditedAnimal(animalVals);
    }
    const updateAnimalRace = (e) => {
        let animalVals = {...editedAnimal};
        animalVals['id_race'] = e.value;
        setEditedAnimal(animalVals);
    }
    const updateAnimalSexe = (e) => {
        let animalVals = {...editedAnimal};
        animalVals['sexe'] = e.target.value;
        setEditedAnimal(animalVals);
    }

    const handlePhotoChange = (e) => {

        if (e.target.files) {

            const photo = e.target.files[0];
            if(photo.size > 5000000) { setPhotoError('L\'image est trop lourde'); }
            else if(photo.type != 'image/jpeg' && photo.type != 'image/png') { setPhotoError('Le fichier n\'est pas une image'); }
            else {
                setPhotoError(null);
                uploadPhoto(photo).then((res) => {
                    if(res != undefined && res.data != undefined && res.data.statut == "ok") {
                        let animalVals = {...editedAnimal};
                        animalVals['photo'] = res.data.filename;
                        setEditedAnimal(animalVals);
                    }
                });                
            }            
        }
    }

    const saveAnimal = () => {
        if(editedAnimal.numero_puce && editedAnimal.numero_puce.length > 0) {
            setAnimal(user, editedAnimal, id).then(res => {
                if(res != undefined && res.data != undefined && res.data.statut == "ok") {
                    if(editedAnimal.id != 'new') {
                        client.animaux.map((a, i) => {
                            if(a.id == editedAnimal.id) { 
                                client.animaux[i] = {...editedAnimal}; 
                                client.animaux[i].espece = res.data.espece;
                                client.animaux[i].race = res.data.race;
                            }
                        });
                    }
                    else {
                        let animalVals = {...editedAnimal};
                        animalVals.espece = res.data.espece;
                        animalVals.race = res.data.race;
                        animalVals.id = res.data.id_animal;
                        client.animaux.push(animalVals);
                    }
                    setOpen(false); 
                    setPuceErrorMsg(null);     
                }
            });
        }
        else {
            setPuceErrorMsg("Ce champ est obligatoire");
        }         
    }

    const supprAnimal = (id_animal) => {
        swal({
            title: "Êtes-vous sûr(e) ?",
            text: "Cette action supprimera l'animal, avec ses consulations.",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          })
          .then((willDelete) => {
            if(willDelete) {
                deleteAnimal(user, id_animal).then(res => {
                    if(res != undefined && res.data != undefined && res.data.statut == "ok") {
                        client.animaux.map((a, i) => {
                            if(a.id == id_animal) { 
                                client.animaux.splice(i, 1); 
                                setEditedAnimal({'id':'new'});
                            }
                        }); 
                    }
                });
            }
        });        
    }


    const changeEditedRdv = (type='demande', id) => {
        if(id != null) {
            (type == 'demande' ? listeDemandesRdv : listeRdv).map((r, i) => {            
                if(r.id == id) {

                    let rdvVals = {...r}; 
                    rdvVals.id_rdv = id; 

                    let ajd = new Date();
                    let date_rdv = new Date(rdvVals['date_rdv']);
                    if(date_rdv.getFullYear() == ajd.getFullYear() && date_rdv.getMonth() == ajd.getMonth() && date_rdv.getDate() == ajd.getDate()) {
                        rdvVals.ready = true;
                    }
                    else {
                        rdvVals.ready = false;
                    }
                                  
                    setEditedRdv(rdvVals); 
                    setOpenDrawer(false);
                    setOpenRdv(true);
                }
            }); 
        }
        else {
            if(client.id_gocardless) {
                let rdvVals = {}; 
                rdvVals.id_rdv = null;   
                rdvVals.ready = false;             
                setEditedRdv(rdvVals); 
                setOpenDrawer(false);
                setOpenRdv(true);
            }
            else {
                swal({
                    title: "Remplissez vos données bancaires",
                    text: "Avant de pouvoir faire une demande de consultation, vous devez d'abord enregistrer un mandat de prélèvement bancaire dans l'onglet \"Paiement\".",
                    icon: "warning",
                    dangerMode: true,
                });
            }
        }
    }

    const updateRdv = (e) => {
        let rdvVals = {...editedRdv};
        rdvVals[e.target.id] = e.target.value;
        setEditedRdv(rdvVals);
    }
    const updateDateRdv = (e) => {
        let rdvVals = {...editedRdv};
        rdvVals['date_rdv'] = e['$y']+'-'+((e['$M']+1) < 10 ? '0' : '')+(e['$M']+1)+'-'+(e['$D'] < 10 ? '0' : '')+e['$D']+' '+(e['$H'] < 10 ? '0' : '')+e['$H']+':'+(e['$m'] < 10 ? '0' : '')+e['$m'];
        setEditedRdv(rdvVals);
    }
    const updateAnimalRdv = (e) => {
        let rdvVals = {...editedRdv};
        rdvVals['id_animal'] = e.value;
        setEditedRdv(rdvVals);
    }
    const updateTypePrestationRdv = (e) => {
      let rdvVals = {...editedRdv};
      rdvVals['type_prestation'] = e.value;
      setEditedRdv(rdvVals);
    }

    const isDateValid = (date = "") => {        
        let parsedDate = new Date(date);    
        return parsedDate.toString() !== "Invalid Date"; 
    }

    const saveRdv = () => {

        setAnimalError(null);
        setMotifError(null);
        setSymptomesError(null);

        let rdvVals = {...editedRdv};
        rdvVals['id_veterinaire'] = (user.is_veto ? user.id : null);

        if(!(rdvVals.id_animal && rdvVals.id_animal.length > 0)) { setAnimalError('Ce champs est obligatoire'); }
        if(!(rdvVals.motif_consultation && rdvVals.motif_consultation.length > 0)) { setMotifError('Ce champs est obligatoire'); }
        if(!(rdvVals.symptomes && rdvVals.symptomes.length > 0)) { setSymptomesError('Ce champs est obligatoire'); }

        let id_rdv = (rdvVals.id_rdv != undefined ? rdvVals.id_rdv : null);
        if(rdvVals.date_rdv != undefined && !isDateValid(rdvVals.date_rdv)) { rdvVals.date_rdv = null; }

        setRdv(user, id_rdv, rdvVals).then(res => {
            if(res != undefined && res.data != undefined && res.data.statut == "ok") {
                
                setOpenRdv(false);
                setEditedRdv({});

                rdvVals.animal = res.data.animal;
                let liste = [...listeRdv];
                let liste_demandes = [...listeDemandesRdv];
                    
                if(id_rdv != null) {

                    if(rdvVals.date_rdv && rdvVals.date_rdv.length > 0 && isDateValid(rdvVals.date_rdv)) {
                        let found = false;
                        liste.map((r, i) => {   
                            if(r.id == id_rdv) {
                                liste[i] = rdvVals;
                                found = true;
                            }
                        });
                        if(!found) {
                            liste_demandes.map((r, i) => {   
                                if(r.id == id_rdv) { liste_demandes.splice(i, 1); }
                            });
                            liste.push(rdvVals);
                        }  
                    }
                    else {
                        let found = false;
                        liste_demandes.map((r, i) => {   
                            if(r.id == id_rdv) {
                                liste_demandes[i] = rdvVals;
                                found = true;
                            }
                        });
                        if(!found) {
                            liste.map((r, i) => {   
                                if(r.id == id_rdv) { liste.splice(i, 1); }
                            });
                            liste_demandes.push(rdvVals);
                        }
                    }                     
                }
                else {
                    if(rdvVals.date_rdv && rdvVals.date_rdv.length > 0 && isDateValid(rdvVals.date_rdv)) {
                        liste.push(rdvVals);
                    }
                    else {
                        liste_demandes.push(rdvVals);
                    }
                }   

                setListeRdv(liste);                      
                setListeDemandesRdv(liste_demandes);

                swal({
                    title: "Ok !",
                    text: "La consultation est enregistrée.",
                    icon: "success",
                    buttons: true,
                });
            }
        });
    }

    
    const appel = (id_rdv) => {
        if(client.id_gocardless) {
            setLoadingCall(true);
            demarreRdv(user, id_rdv).then(res => {
                if(res != undefined && res.data != undefined && res.data.statut == "ok") {
                    window.open(res.data.lien_rdv);
                    setLoadingCall(false);
                }
            });
        }
        else {
            swal({
                title: "Remplissez vos données bancaires",
                text: "Avant de pouvoir faire une demande de consultation, vous devez d'abord enregistrer un mandat de prélèvement bancaire dans l'onglet \"Paiement\".",
                icon: "warning",
                dangerMode: true,
            });
        }        
    }


    const lienGoCardless = () => {
        setMandat(client, 'client').then(res => {
            if(res != undefined && res.data != undefined && res.data.statut == "ok") {                    
                let clientVals = {...client};
                clientVals['id_gocardless'] = res.data.id_gocardless;
                setClient(clientVals);
                setClientDatas(user, id, clientVals).then(res2 => {
                    if(res2 != undefined && res2.data != undefined && res2.data.statut == "ok") {
                        window.open(res.data.lien,'_self');
                    }
                });
            }
        });                   
    }


    const listeTypesPrestations = [];
    typesPrestations.map((t, i) => {
        listeTypesPrestations.push({ value: t.id, label: t.nom });
    });


    return(
        <div className="fullpage">
        <Container className={['full_container','client_page'].join(" ")}>
            
            <UserWidget /> 
            {user.is_veto && <SideMenu user={user} setUser={setUser}/>}
            
            <Row className={user.is_veto ? [(user.menuClosed ? 'withSidemenu-closed' : 'withSidemenu'), 'h-auto'].join(" ") : ['w-100', 'h-auto', ' ps-4'].join(" ")}>

                <Col xs={10} className={user.is_veto ? ['enteteFiche','pt-5'].join(" ") : ['px-5','pt-5'].join(" ")}>
                    {client != undefined ? (
                    <Row className="align-items-center">
                        {user.is_veto && <Col className={['d-flex','justify-content-end', 'pe-1'].join(" ")} md={1}>
                            <IconButton className="arrowButtonLeft" aria-label="retour" onClick={() => window.open('/clients', '_self')}>
                                <ArrowBackIcon/>
                            </IconButton>
                        </Col>}
                        <Col md={4}>
                            <h3 className="mb-1">{client.prenom}</h3>
                            <h2 className="bold">{client.nom && client.nom.toUpperCase()}</h2>
                        </Col>
                        <Col md={7} className="d-flex">
                            {needSave == true ? (<Card className={['mx-2','p-2','waiting'].join(" ")} title="Sauvegarder">
                                <Button variant="text" className={['listButton','p-2'].join(" ")} onClick={save}>
                                    <SaveIcon className='me-3'/>
                                    <span>Sauvegarder</span>
                                </Button>
                            </Card>) : (<Card className={['mx-2','p-2','align-items-center','success'].join(" ")}>
                                <Button variant="text" className={['listButton','success','p-2'].join(" ")}>
                                    <CheckIcon className='me-3'/>
                                    <span>A jour</span>
                                </Button>
                            </Card>)}
                            {id != 'new' && <Card className={['mx-2','p-2'].join(" ")}>
                                <IconButton aria-label="retour" title="Factures" onClick={() => window.open('/factures','_self')}>
                                    <DescriptionOutlinedIcon/>
                                </IconButton>
                            </Card>}
                            {id != 'new' && <Badge badgeContent={listeDemandesRdv.length} color="primary"><Card className={['mx-2','p-2'].join(" ")}>
                                <IconButton aria-label="retour" onClick={() => {setOpenDrawer(true)}} title="Consultations">
                                    <EventOutlinedIcon/>
                                </IconButton>
                            </Card></Badge>}
                            {id != 'new' && <Card className={['mx-2','p-2'].join(" ")}>
                                <IconButton aria-label="retour" onClick={() => window.open('/messagerie?destinataire='+client.id,'_self')} title="Messagerie">
                                    <ChatOutlinedIcon/>
                                </IconButton>
                            </Card>}
                            {user.is_veto && !client.id_gocardless && <Card className={['mx-2','p-2'].join(" ")}>
                                <IconButton aria-label="retour" onClick={invitationClient} title="Inviter à remplir son dossier">
                                    <ConnectWithoutContactIcon className='reverse'/>
                                </IconButton>
                            </Card>}

                            <SwipeableDrawer
                                    anchor='right'
                                    open={openDrawer}
                                    onClose={() => {setOpenDrawer(false)}}
                                >
                                <div className="p-4">
                                    <p className={['bigText','bold'].join(" ")}>Prochaines<br></br>consultations</p>
                                    <hr width="75%" className="black_line"></hr>
                                </div>
                                {listeRdv.length > 0 ? listeRdv
                                    .map((r, i) => {
                                    return (<div key={r.id}> 
                                        <Button variant="text" className="listButton" data-id={r.id} onClick={() => {changeEditedRdv('rdv', r.id)}}>
                                            <p>{r.animal}</p>
                                            <p className='smallText'>{r.motif_consultation}</p>
                                            <p className='smallText'>le {r.date_formatted}</p>
                                        </Button>
                                    </div>)
                                    }) : (<div className='px-4'><p className='smallText'>Aucune consultation pour le moment</p></div>)
                                }

                                <div className="p-4">
                                    <p className={['bigText','bold'].join(" ")}>Demandes de<br></br>consultation</p>
                                    <hr width="75%" className="black_line"></hr>
                                </div>  
                                <div>
                                {listeDemandesRdv.length > 0 ? listeDemandesRdv
                                    .map((r, i) => {
                                    return (<div key={r.id}> 
                                        <Button variant="text" className="listButton" data-id={r.id} onClick={() => {changeEditedRdv('demande', r.id)}}>
                                            <p>{r.animal}</p>
                                            <p className='smallText'>{r.motif_consultation}</p>
                                        </Button>
                                    </div>)
                                    }) : (<div className='px-4'><p className={['smallText','py-2'].join(" ")}>Aucune demande pour le moment</p></div>)
                                }
                                    <Button variant="text" className="listButton" onClick={() => {changeEditedRdv('demande', null)}}>
                                        <AddBoxOutlinedIcon className='me-3' />
                                        <span>Nouvelle demande</span>
                                    </Button>
                                </div>                              
                            </SwipeableDrawer>
                        </Col>
                    </Row>) : (<div className="mt-2">
                        <Skeleton variant="text" sx={{ fontSize: '1.1rem' }} width={200} />
                        <Skeleton variant="text" sx={{ fontSize: '1.5rem' }} width={100} />
                    </div>)
                    }
                </Col>

                <Col md={9} className="px-5 pb-5">
                    <div className={['corpsFiche','borderLeft'].join(" ")}>
                        <TabContext value={activeTab}>
                            <TabList onChange={changeActiveTab} className='px-2'>
                                <Tab label="Informations" value="1" />
                                {id != 'new' && <Tab label={"Animaux ("+ (client.animaux != undefined ? client.animaux.length : 0)+")"} value="2"/>}
                                {id != 'new' && <Tab label="Paiement" className={(client.id_gocardless == false && "textWarning")} icon={(client.id_gocardless == false && <NotificationImportantIcon />)} iconPosition="end" value="3"/>}
                            </TabList>
                            <TabPanel value="1">
                                <Row>
                                    <Col><TextField 
                                        required
                                        id="prenom" 
                                        label="Prénom(s)" 
                                        variant="standard"
                                        value={client.prenom ? client.prenom : ''}
                                        onChange={handleChange}
                                        className="mt-2 mb-1 w-100"
                                    /></Col>                                    
                                    <Col><TextField 
                                        id="nom" 
                                        label="Nom" 
                                        variant="standard" 
                                        value={client.nom ? client.nom : ''}
                                        onChange={handleChange}
                                        className="mt-2 mb-1 w-100"
                                    /></Col>
                                    <Col><TextField 
                                        id="email" 
                                        label="Adresse email" 
                                        variant="standard" 
                                        value={client.email ? client.email : ''}
                                        onChange={handleChange}
                                        className="mt-2 mb-1 w-100"
                                        error={!!emailErrorMsg}
                                        helperText={emailErrorMsg}
                                    /></Col>                                    
                                </Row>
                                <Row>
                                    <Col md={4}><TextField 
                                        id="telephone" 
                                        label="Téléphone" 
                                        variant="standard" 
                                        value={client.telephone ? client.telephone : ''}
                                        onChange={handleChange}
                                        className="mt-2 mb-1 w-100"
                                    /></Col>
                                    <Col md={4}><TextField 
                                        id="telephone2" 
                                        label="Téléphone 2" 
                                        variant="standard" 
                                        value={client.telephone2 ? client.telephone2 : ''}
                                        onChange={handleChange}
                                        className="mt-2 mb-1 w-100"
                                    /></Col>
                                </Row>

                                <p className={['titre','bold','mt-5','mb-2'].join(" ")}>Adresse</p>
                                <Row>
                                    <Col md={4}>                            
                                        <TextField 
                                            id="adresse" 
                                            label="Adresse" 
                                            variant="standard" 
                                            value={client.adresse ? client.adresse : ''}
                                            onChange={handleChange}
                                            className="mt-2 mb-1 w-100"
                                        />
                                    </Col>
                                    <Col md={3}>
                                        <TextField 
                                            id="cp" 
                                            label="Code postal" 
                                            variant="standard" 
                                            value={client.cp ? client.cp : ''}
                                            onChange={handleChange}
                                            className="mt-2 mb-1 w-100"
                                        />
                                    </Col>
                                    <Col md={3}>
                                        <TextField 
                                            id="ville" 
                                            label="Ville" 
                                            variant="standard" 
                                            value={client.ville ? client.ville : ''}
                                            onChange={handleChange}
                                            className="mt-2 mb-1 w-100"
                                        />
                                    </Col>
                                </Row>

                                <p className={['titre','bold','mt-5','mb-2'].join(" ")}>Notes</p>
                                <Col md={6}>
                                    <TextField 
                                        id="notes" 
                                        label="Notes" 
                                        variant="standard" 
                                        value={client.notes ? client.notes : ''}
                                        onChange={handleChange}
                                        className="mt-2 mb-1 w-100"
                                    />
                                </Col>
                            </TabPanel>
                            <TabPanel value="2">                                
                                {id != 'new' ? (<div>
                                    <Col md={6}>
                                    {client.animaux != undefined ? client.animaux
                                        .map((p, i) => {	
                                        var mt = (i > 0 ? "mt-3" : "mt-1");
                                        return (<div className = {mt} key={p.id} >
                                            <Card className={['px-4','py-3','mt-3', 'w-auto'].join(" ")}>
                                                <Row className="align-items-center">
                                                    <Col md={2} className={(p.espece == "Oiseau" ? "bird_img" : (p.espece == "Chat" ? "cat_img" : "dog_img"))}><img className="img-35"></img></Col>
                                                    <Col md={6} className='ps-4'>
                                                        <p className="bigText">{p.nom}</p>
                                                        <p className="smallText">{p.race}</p>
                                                    </Col>
                                                    <Col md={4} className="textRight">
                                                        <IconButton aria-label="modifier" className="mr-2" onClick={() => window.open('/animal/'+p.id, '_self')}>
                                                            <EditNoteIcon/>
                                                        </IconButton>
                                                        <IconButton aria-label="supprimer" className="textRed" onClick={() => supprAnimal(p.id)}>
                                                            <DeleteOutlineIcon />
                                                        </IconButton>
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </div>)
                                        }) : (<div></div>)
                                    } 
                                    <IconButton aria-label="supprimer" className="mt-3" onClick={addAnimal}>
                                        <AddBoxOutlinedIcon />
                                    </IconButton>
                                    </Col></div>)
                                : (<div></div>)}
                            </TabPanel>
                            <TabPanel value="3">
                                <p className={['titre','bold','mb-1'].join(" ")}>Mandat de prélèvement</p>
                                <p className="smallText">Paiement sécurisé avec GoCardless</p>
                                
                                <Col md={4} className='mt-4'>
                                {client.id_gocardless ? (<Card className={['p-2','align-items-center','success'].join(" ")}>
                                    <Button variant="text" className={['listButton','success','p-2'].join(" ")}>
                                        <CreditScoreIcon className='me-3'/>
                                        <span>Mandat en place</span>
                                    </Button>
                                    </Card>) : (<div>                                        
                                        {user.is_veto && <Card className={['p-2','mb-3','waiting'].join(" ")}>
                                            <Button variant="text" className={['listButton','p-2'].join(" ")} onClick={invitationClient}>
                                                <NotificationsActiveOutlinedIcon className='me-3'/>
                                                <span>Envoyer un rappel</span>
                                            </Button>
                                        </Card>}
                                        <Card className={['p-2','waiting'].join(" ")}>
                                            <Button variant="text" className={['listButton','p-2'].join(" ")} onClick={lienGoCardless}>
                                                <AddCardIcon className='me-3'/>
                                                <span>Mettre en place le mandat</span>
                                            </Button>
                                        </Card>
                                    </div>)
                                }
                                </Col>
                            </TabPanel>
                        </TabContext>
                    </div>
                </Col>
               
            </Row>
        </Container>

        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: { timeout: 500 },
            }}
        >
            <Fade in={open}>
                <Box>
                    <p className={['titre','bold','mb-2'].join(" ")}>{editedAnimal.nom ? editedAnimal.nom : 'Nouvel animal'}</p>

                    <IconButton aria-label="modifier" className="modalExit" onClick={handleClose}>
                        <CloseIcon/>
                    </IconButton>

                    <Row>
                        <Col md={6} className='text-center'>
                            <div className={['d-flex','align-items-center','justify-content-center', 'position-relative'].join(" ")}>
                                <div className="animal_photo" alt="animal_photo" style={{ backgroundImage: `url(${process.env.REACT_APP_IMG_URL}${editedAnimal.photo})` }}>
                                </div>
                                <Button component="label" className='photoButton' variant="contained" startIcon={<AddAPhotoIcon />}>
                                    <VisuallyHiddenInput type="file" onChange={handlePhotoChange}/>
                                </Button>
                            </div>
                            {photoError && <small className='textRed'>{photoError}</small>}
                        </Col>
                        <Col md={6}>
                            <TextField 
                                id="animal-nom" 
                                label="Nom" 
                                variant="standard" 
                                value={editedAnimal.nom ? editedAnimal.nom : ''}
                                onChange={updateAnimal}
                                className="mt-2 mb-1 w-100"
                            />
                            <TextField 
                                id="animal-numero_puce" 
                                label="Numéro de puce" 
                                variant="standard" 
                                value={editedAnimal.numero_puce ? editedAnimal.numero_puce : ''}
                                onChange={updateAnimal}
                                className="mt-2 mb-1 w-100"
                                error={!!puceErrorMsg}
                                helperText={puceErrorMsg}
                            />
                            <TextField 
                                id="animal-poids" 
                                label="Poids (Kg)" 
                                variant="standard" 
                                value={editedAnimal.poids ? editedAnimal.poids : ''}
                                onChange={updateAnimal}
                                className="mt-2 mb-1 w-100"
                            />
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateField
                                    id="animal-date_naissance" 
                                    label="Date de naissance"
                                    value={editedAnimal.date_naissance ? dayjs(editedAnimal.date_naissance) : ''}
                                    onChange={updateAnimalDateNaissance}
                                    format="DD-MM-YYYY"
                                    className="mt-3 mb-1 w-100"
                                />
                            </LocalizationProvider>
                            <FormControl className="mt-2 mb-1">
                                <FormLabel id="animal-sexe-label">Sexe</FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby="animal-sexe-label"
                                    id="animal-sexe"
                                    name="animal-sexe" 
                                    value={editedAnimal.sexe != undefined ? editedAnimal.sexe : 'M'}
                                    onChange={updateAnimalSexe}                                    
                                >
                                    <FormControlLabel value="M" control={<Radio />} label="Male" />
                                    <FormControlLabel value="F" control={<Radio />} label="Femelle" />
                                </RadioGroup>
                            </FormControl>
                        </Col>
                    </Row>
                    <Row className="mt-4">
                        <Col md={5}>
                            <FormControl variant="standard" sx={{ width: '90%' }}>
                                <InputLabel id="animal-espece-label">Espèce</InputLabel>
                                <Select
                                    labelId="animal-espece-label"
                                    id="animal-espece"                                    
                                    value={editedAnimal.id_espece ? listeEspeces.filter((c) => c.value == editedAnimal.id_espece) : ''}
                                    onChange={updateAnimalEspece}
                                    label="Espèce"
                                    options={listeEspeces}
                                >
                                </Select>
                            </FormControl>
                        </Col>
                        <Col md={7}>
                            <FormControl variant="standard" sx={{ width: '100%' }}>
                                <InputLabel id="animal-race-label">Race</InputLabel>
                                <Select
                                    labelId="animal-race-label"
                                    id="animal-race"                          
                                    value={editedAnimal.id_race ? listeRaces.filter((c) => c.value == editedAnimal.id_race) : ''}
                                    onChange={updateAnimalRace}
                                    label="Race"
                                    options={listeRaces}
                                >
                                </Select>
                            </FormControl>
                        </Col>
                    </Row>

                    <Button variant="contained" className={['buttonSecondary','mt-5'].join(" ")} type="submit" startIcon={<CheckIcon />} onClick={saveAnimal}>Sauvegarder</Button>
                </Box>
            </Fade>
        </Modal>
        
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={openRdv}
            onClose={handleCloseRdv}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: { timeout: 500 },
            }}
        >
            <Fade in={openRdv}>
                <Box>
                    <p className={['titre','bold','mb-2'].join(" ")}>{editedRdv.id_rdv ? 'Consultation' : 'Demande de consultation'}</p>

                    <IconButton aria-label="modifier" className="modalExit" onClick={handleCloseRdv}>
                        <CloseIcon/>
                    </IconButton>

                    <Row className="align-items-start">
                        <Col md={6} className='pe-4'>
                            {user.is_veto ? (<LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateTimePicker
                                id="date_rdv" 
                                label="Date"
                                value={editedRdv.date_rdv ? dayjs(editedRdv.date_rdv) : ''}
                                onChange={updateDateRdv}
                                ampm={false}
                                format="DD/MM/YYYY HH:mm"
                                className="mt-3 mb-1 w-100"
                            />
                            </LocalizationProvider>) : (<div></div>)}
                            <FormControl variant="standard" sx={{ width: '100%' }} className='mt-1' error={!!animalError}>
                                <InputLabel id="rdv_animal">Animal</InputLabel>
                                <Select
                                    labelId="rdv_animal"
                                    id="id_animal"
                                    value={editedRdv.id_animal ? listeAnimaux.filter((c) => c.value == editedRdv.id_animal) : ''}
                                    onChange={updateAnimalRdv}
                                    label="Animal"         
                                    placeholder="Animal"                           
                                    options={listeAnimaux}
                                    >
                                </Select>
                            </FormControl>
                            {user.is_veto &&
                            <FormControl variant="standard" sx={{ width: '100%' }} className='mt-2'>
                                <Select
                                id="type_prestation"
                                value={editedRdv.type_prestation ? listeTypesPrestations.filter((c) => c.value == editedRdv.type_prestation) : ''}
                                onChange={updateTypePrestationRdv}
                                placeholder="Type de prestation"
                                options={listeTypesPrestations}
                                >                      
                                </Select>
                            </FormControl>
                            }
                        </Col>
                        <Col md={6} className={['borderLeft','ps-4'].join(" ")}>
                            <TextField 
                                id="motif_consultation" 
                                label="Motif de consultation" 
                                variant="standard" 
                                value={editedRdv.motif_consultation ? editedRdv.motif_consultation : ''}
                                onChange={updateRdv}
                                className="mb-1 w-100"
                                error={!!motifError}
                                helperText={motifError}
                            />
                            <TextField 
                                id="symptomes" 
                                label="Symptômes" 
                                variant="standard" 
                                value={editedRdv.symptomes ? editedRdv.symptomes : ''}
                                onChange={updateRdv}
                                className="mt-2 mb-1 w-100"
                                error={!!symptomesError}
                                helperText={symptomesError}
                            />
                            <TextField 
                                id="duree_symptomes" 
                                label="Durée des symptômes" 
                                variant="standard" 
                                value={editedRdv.duree_symptomes ? editedRdv.duree_symptomes : ''}
                                onChange={updateRdv}
                                className="mt-2 mb-1 w-100"
                            />
                            <TextField 
                                id="antecedents" 
                                label="Antécédents" 
                                variant="standard" 
                                value={editedRdv.antecedents ? editedRdv.antecedents : ''}
                                onChange={updateRdv}
                                className="mt-2 mb-1 w-100"
                            />
                        </Col>
                    </Row>                    

                    <Row>
                        <Col md={6}>
                            <Button variant="contained" className={['buttonSecondary','mt-5'].join(" ")} type="submit" startIcon={<CheckIcon />} onClick={saveRdv}>Sauvegarder</Button>
                        </Col>
                        <Col md={6} className={['d-flex','justify-content-end'].join(" ")}>
                            <Button variant="contained" className={(editedRdv.ready ? ['buttonSecondary','mt-5'] : ['mt-5']).join(" ")} startIcon={(loadingCall ? <CircularProgress/> : <LocalPhoneIcon />)} onClick={() => appel(editedRdv.id_rdv)} disabled={!editedRdv.ready}>{user.is_veto ? "Appeler" : "Rejoindre"}</Button>
                        </Col>
                    </Row>  
                </Box>
            </Fade>
        </Modal>

        <Prompt
            when={needSave}
            message='You have unsaved changes, are you sure you want to leave?'
        />
        </div>
    )
}

export default FicheClient;