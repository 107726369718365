import { React, useEffect, useState } from 'react';
import { Redirect } from "react-router-dom";
import UserWidget from './UserWidget';
import SideMenu from './SideMenu';
import EditNoteIcon from '@mui/icons-material/EditNote';
import NotesIcon from '@mui/icons-material/Notes';
import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import PeopleOutlineOutlinedIcon from '@mui/icons-material/PeopleOutlineOutlined';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import DriveFileMoveOutlinedIcon from '@mui/icons-material/DriveFileMoveOutlined';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import {Skeleton, TextField, Button, Paper} from '@mui/material';
import dayjs from 'dayjs';
import swal from 'sweetalert';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateField } from '@mui/x-date-pickers/DateField';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import Select from 'react-select'
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../App/App.css';

import {useCredentials} from '../../Services/authServices';
import {getCabDatas} from '../../Services/cabinetServices';
import {getClientDatas} from '../../Services/UserServices';  
import {getRdvsReferes, setRdv, demarreRdv} from '../../Services/RDVServices';
import {getTypesPrestations} from '../../Services/prestationsServices';


function Dashboard() {

  const { user, setUser } = useCredentials();
  const [cabinet, setCabinet] = useState({});
  const [loadingCalls, setLoadingCalls] = useState([]);

  const [editedRdv, setEditedRdv] = useState({});
  const [openRdv, setOpenRdv] = useState(false);
  const handleOpenRdv = () => setOpenRdv(true);
  const handleCloseRdv = () => setOpenRdv(false);    
  const [animalError, setAnimalError] = useState();  
  const [motifError, setMotifError] = useState();
  const [symptomesError, setSymptomesError] = useState();

  const [listeClients, setListeClients] = useState([]);
  const [listeAnimaux, setListeAnimaux] = useState([]);

  const [typesPrestations, setTypesPrestations] = useState([]);

  const [dureeProjection, setDureeProjection] = useState('1w');

  var caProjete = 0;
  var listePrestationsPrevues = [];  
  
  useEffect(() => {

    let ajd = new Date;

    if(!user) { window.open('/login', '_self'); }
    else {
      getCabDatas(user, false, true).then(res => {
        if(res != undefined && res.data != undefined && res.data.statut == "ok") {
          
          let _loadingCalls = []
          res.data.donnees.rdv.map((r, i) => {	
            _loadingCalls[r.id] = false; 

            let date_rdv = new Date(r.date_rdv);
            if(date_rdv.getFullYear() == ajd.getFullYear() && date_rdv.getMonth() == ajd.getMonth() && date_rdv.getDate() == ajd.getDate()) {
              r.ready = true;
            }
            else {
              r.ready = false;
            }
          });

          let optionsClients = [];
          res.data.donnees.clients.map((c, i) => {
            optionsClients.push({value: c.id, label: c.prenom+' '+c.nom});
          }); 

          getTypesPrestations(res.data.donnees.id).then(res_t => {
            if(res_t != undefined && res_t.data != undefined && res_t.data.statut == "ok") {
              setTypesPrestations(res_t.data.donnees);
            }
          });

          setCabinet(res.data.donnees);
          setListeClients(optionsClients);
          setLoadingCalls(_loadingCalls);
        }
      });
    }
  }, []);


  const updatelisteAnimaux = (e) => {
    let rdvVals = {...editedRdv};
    setListeAnimaux([]);

    getClientDatas(user, e.value).then(res => {
        if(res != undefined && res.data != undefined && res.data.statut == "ok") {

          let optionsAnimaux = [];
          res.data.donnees.animaux.map((c, i) => {
            optionsAnimaux.push({value: c.id, label: c.nom});
          }); 
          setListeAnimaux(optionsAnimaux);
          if(res.data.donnees.animaux.length > 0) { rdvVals['id_animal'] = res.data.donnees.animaux[0].id; }
        }
    });
    
    rdvVals['id_client'] = e.value;
    setEditedRdv(rdvVals);
  }

  const isDateValid = (date = "") => {        
    let parsedDate = new Date(date);    
    return parsedDate.toString() !== "Invalid Date"; 
  }

  const nouveauRdv = () => {
    setEditedRdv({});
    handleOpenRdv();
  }

  const changeEditedRdv = (id, type = 'rdv') => {
    let liste = (type == 'demande' ? [...cabinet.demandes_rdv] : [...cabinet.rdv]);
    liste.map((r, i) => {            
      if(r.id == id) {
        let rdvVals = {...r}; 
        rdvVals['id_rdv'] = id;              
        getClientDatas(user, r.id_client).then(res => {
          if(res != undefined && res.data != undefined && res.data.statut == "ok") {
            let optionsAnimaux = [];
            res.data.donnees.animaux.map((c, i) => {
                optionsAnimaux.push({value: c.id, label: c.nom});
            }); 
            setListeAnimaux(optionsAnimaux);
            if(res.data.donnees.animaux.length > 0) { 
                let rdvVals = {...editedRdv};
                if(res.data.donnees.animaux.length > 0) { rdvVals['id_animal'] = res.data.donnees.animaux[0].id; }                            
            }
          }
        });     
        rdvVals.ready = false;             
        setEditedRdv(rdvVals);
        setOpenRdv(true);
      }
    }); 
  }
  
  const updateRdv = (e) => {
    let rdvVals = {...editedRdv};
    rdvVals[e.target.id] = e.target.value;
    setEditedRdv(rdvVals);
  }
  const updateDateRdv = (e) => {

    let day = (e['$D'] < 10 ? '0' : '')+e['$D'];
    let month = ((e['$M']+1) < 10 ? '0' : '')+(e['$M']+1);
    let year = e['$y'];
    let hour = (e['$H'] < 10 ? '0' : '')+e['$H'];
    let minutes = (e['$m'] < 10 ? '0' : '')+e['$m'];

    let rdvVals = {...editedRdv};
    rdvVals['date_rdv'] = year+'-'+month+'-'+day+' '+hour+':'+minutes;
    rdvVals['date_formatted'] = day+'/'+month+'/'+year+' à '+hour+'h'+minutes;
    setEditedRdv(rdvVals);
  }
  const updateAnimalRdv = (e) => {
    let rdvVals = {...editedRdv};
    rdvVals['id_animal'] = e.value;
    setEditedRdv(rdvVals);
  }
  const updateTypePrestationRdv = (e) => {
    let rdvVals = {...editedRdv};
    rdvVals['type_prestation'] = e.value;
    setEditedRdv(rdvVals);
  }
  
  const saveRdv = () => {
    
    setAnimalError(null);
    setMotifError(null);
    setSymptomesError(null);
    
    let rdvVals = {...editedRdv};
    rdvVals['id_veterinaire'] = (user.is_veto ? user.id : null);
    
    if(!(rdvVals.id_animal && rdvVals.id_animal.length > 0)) { setAnimalError('Ce champs est obligatoire'); }
    if(!(rdvVals.motif_consultation && rdvVals.motif_consultation.length > 0)) { setMotifError('Ce champs est obligatoire'); }
    if(!(rdvVals.symptomes && rdvVals.symptomes.length > 0)) { setSymptomesError('Ce champs est obligatoire'); }
    
    let id_rdv = (rdvVals.id_rdv != undefined ? rdvVals.id_rdv : null);
    if(rdvVals.date_rdv != undefined && !isDateValid(rdvVals.date_rdv)) { rdvVals.date_rdv = null; }
    
    setRdv(user, id_rdv, rdvVals).then(res => {
      if(res != undefined && res.data != undefined && res.data.statut == "ok") {
        
        setOpenRdv(false); 
        setEditedRdv({});     
        
        let liste_complete = [...cabinet.rdv];
        let liste_demandes = [...cabinet.demandes_rdv];
        
        if(id_rdv != null) {
          
          if(rdvVals.date_rdv && rdvVals.date_rdv.length > 0 && isDateValid(rdvVals.date_rdv)) {
            
            let found = false;
            liste_complete.map((r, i) => {   
              if(r.id == id_rdv) {
                liste_complete[i] = rdvVals;
                found = true;
              }
            });
            
            if(!found) {
              liste_demandes.map((r, i) => {   
                if(r.id == id_rdv) {
                  liste_demandes.splice(i, 1);
                }
              });              
              rdvVals.date = rdvVals.date_rdv;
              liste_complete.push(rdvVals);
            }  
          }
          else {
            let found = false;
            liste_demandes.map((r, i) => {   
              if(r.id == id_rdv) {
                liste_demandes[i] = rdvVals;
                found = true;
              }
            });

            if(!found) {
              liste_complete.map((r, i) => {   
                if(r.id == id_rdv) {
                  liste_complete.splice(i, 1);
                }
              });              
              rdvVals.date = rdvVals.date_rdv;
              liste_demandes.push(rdvVals);
            }
          }                     
        }
        else {
          if(rdvVals.date_rdv && rdvVals.date_rdv.length > 0 && isDateValid(rdvVals.date_rdv)) {
            liste_complete.push(rdvVals);
          }
          else {
            liste_demandes.push(rdvVals);
          }
        }
        
        let cabVals = {...cabinet};
        cabVals.rdv = liste_complete;
        cabVals.demandes_rdv = liste_demandes;
        setCabinet(cabVals);   
      }
    });
  }


  const appel = (id_rdv) => {
    let _loadingCalls = [...loadingCalls];
    _loadingCalls[id_rdv] = true;
    setLoadingCalls(_loadingCalls);

    demarreRdv(user, id_rdv).then(res => {
        if(res != undefined && res.data != undefined && res.data.statut == "ok") {
          window.open(res.data.lien_rdv);

          let _loadingCalls = [...loadingCalls];
          _loadingCalls[id_rdv] = false;
          setLoadingCalls(_loadingCalls);
        }        
    });
  }

  const changeDureeProjection = (e) => {
    setDureeProjection(e.value);
  }

  const updateCAProjete = () => {

    if(!cabinet) return;
    
    let d = (dureeProjection ? dureeProjection : '1w');  
    var limite = new Date();
    if(d == '1w') { limite.setDate(limite.getDate() + 7); }
    else if(d == '1m') { limite.setMonth(limite.getMonth() + 1); }
    else if(d == '3m') { limite.setMonth(limite.getMonth() + 3); }

    let liste_rdvs = [];
    if(cabinet.rdv) { let liste_rdvs = [...cabinet.rdv]; }
    if(cabinet.licence && cabinet.licence.avec_cas_ref == true && cabinet.cas_referes) {
      liste_rdvs = [...cabinet.rdv, ...cabinet.cas_referes];
    }
    
    if(liste_rdvs) {
      liste_rdvs.map((r, i) => {

        let date_rdv = new Date(r.date_rdv);
        if(date_rdv <= limite && (r.id_specialiste == null || r.cabinet_specialiste == cabinet.id)) {

          let found = false;
          let liste_prestations = [...cabinet.prestations, ...cabinet.prestations_ref];
          liste_prestations.map((p, j) => {	
            if(p.type_prestation == r.type_prestation) {
              found = true;
              if(p.prix_fixe || p.prix_horaire) {
                caProjete += (p.prix_fixe + (p.prix_horaire * 15));
              }
              let found2 = false;
              listePrestationsPrevues.map((pp, k) => {
                if(pp.type_prestation == p.type_prestation) { pp.nb++; found2 = true; }
              });
              if(!found2) {
                listePrestationsPrevues.push({
                  type_prestation : p.type_prestation,
                  nom_prestation: p.prestation,
                  nb: 1
                })
              }
            }
          });

          if(!found) {
            typesPrestations.map((t, i) => {
              if(t.id == r.type_prestation) {
                listePrestationsPrevues.push({
                  type_prestation : t.id,
                  nom_prestation: t.nom,
                  nb: 1
                })
              }
            });            
          }
        }
      });
    }
  }

    
  const listeTypesPrestations = [];
  typesPrestations.map((t, i) => {
    listeTypesPrestations.push({ value: t.id, label: t.nom });
  });

  const listeDureesProjection = [
    {value: '1w', label: '1 semaine'}, {value: '1m', label: '1 mois'}, {value: '3m', label: '3 mois'}
  ]

  updateCAProjete();

  if(!user) { return <Redirect to={"/login"} /> }
  if(user.is_veto) {
    return(
      <div className="fullpage">
        <Container className={['full_container','dashboard_page'].join(" ")}>
          
          <UserWidget /> 
          <SideMenu user={user} setUser={setUser}/> 
          
          <Row className={user.is_veto ? (user.menuClosed ? 'withSidemenu-closed' : 'withSidemenu') : ""}>
            <Col xs={10} md={12} className="p-5">
              <h2>Mon cabinet</h2>
              <hr width="30%" className="black_line"></hr>

              <Row className='mt-5'>
                <Col md={4}>
                  <Card>
                    <CardHeader avatar={<EventOutlinedIcon />} title={"Consultations à venir ("+(cabinet.rdv ? cabinet.rdv.length : 0)+")"} 
                      action={
                        <IconButton aria-label="supprimer" className={['mb-1','ms-3'].join(" ")} onClick={nouveauRdv}>
                          <AddBoxOutlinedIcon />
                        </IconButton>
                      }
                    />                    
                    <CardContent>
                    {cabinet.rdv ? (cabinet.rdv.length > 0 ?
                      cabinet.rdv.map((r, i) => {	  
                        if(i > 2) { return; }
                        return (<Row className='align-items-center'>
                          <Col xs={9}>
                            <Button variant="text" className={['listButton','px-3'].join(" ")} data-id={r.id} onClick={() => {changeEditedRdv(r.id, 'rdv')}}>
                              <strong>le {r.date_formatted}</strong>
                              <p className="smallText">{r.client}</p>                          
                              <p className="smallText">{r.animal}</p>
                            </Button>
                          </Col>
                          <Col title={(r.ready ? 'Appeler' : 'La consultation n\'est pas pour aujourd\'hui')}>
                            <IconButton className={(r.ready ? 'textSecondary' : '')} aria-label="settings" onClick={() => appel(r.id)} disabled={!r.ready}>
                              {(loadingCalls[r.id] == true ? <CircularProgress/> : <LocalPhoneIcon />)}
                            </IconButton>
                          </Col>
                        </Row>)
                      }) : <p className="smallText">Aucune consultation planifiée</p>) : (<div className="mt-2">
                        <Skeleton variant="text" sx={{ fontSize: '0.8rem' }} width={200} />
                        <Skeleton variant="text" sx={{ fontSize: '0.8rem' }} width={100} />
                        <br></br>
                        <Skeleton variant="text" sx={{ fontSize: '0.8rem' }} width={200} />
                        <Skeleton variant="text" sx={{ fontSize: '0.8rem' }} width={100} />
                        <br></br>
                        <Skeleton variant="text" sx={{ fontSize: '0.8rem' }} width={80} />
                      </div>)
                    }      
                    <br></br>
                    <strong className='ps-2'>
                      Tout voir
                      <IconButton className="arrowButton" aria-label="settings">
                        <ArrowForwardIcon onClick={() => window.open('/agenda','_self')} />
                      </IconButton>
                    </strong>            
                    </CardContent>
                  </Card>

                  {cabinet.licence && cabinet.licence.avec_cas_ref == true && <Card className='mt-3'>
                    <CardHeader avatar={<DriveFileMoveOutlinedIcon />} title={"Cas référés ouverts ("+(cabinet.cas_referes ? cabinet.cas_referes.length : 0)+")"} 
                      action={
                        <IconButton aria-label="supprimer" className={['mb-1','ms-3'].join(" ")} onClick={() => window.open('/nouveau_cas_refere','_self')}>
                          <AddBoxOutlinedIcon />
                        </IconButton>
                      }
                    />                    
                    <CardContent>
                    {cabinet.cas_referes ? (cabinet.cas_referes.length > 0 ?
                      cabinet.cas_referes.map((r, i) => {	  
                        if(i > 2) { return; }
                        var destinataire = (user.id == r.id_veterinaire ? r.id_specialiste : r.id_veterinaire);
                        return (<Row className='align-items-center'>
                          <Col xs={9}>
                            <Button variant="text" className={['listButton','px-3'].join(" ")} data-id={r.id} onClick={() => window.open('/animal/'+r.id_animal+'?onglet=2','_self')}>
                              <strong>{r.date_formatted != null ? 'le '+r.date_formatted : 'Sans date'}</strong>
                              <p className="smallText">Avec {user.id == r.id_veterinaire ? r.specialiste : r.veterinaire}</p>     
                              <p className="smallText">Pour {r.client} / {r.animal}</p>
                            </Button>
                          </Col>
                          <Col>
                            <IconButton aria-label="settings" onClick={() => window.open('/messagerie?destinataire='+destinataire+'&consultation='+r.id, '_self')}>
                              <ChatOutlinedIcon />
                            </IconButton>
                          </Col>
                        </Row>)
                      }) : <p className="smallText">Aucune consultation planifiée</p>) : (<div className="mt-2">
                        <Skeleton variant="text" sx={{ fontSize: '0.8rem' }} width={200} />
                        <Skeleton variant="text" sx={{ fontSize: '0.8rem' }} width={100} />
                        <br></br>
                        <Skeleton variant="text" sx={{ fontSize: '0.8rem' }} width={200} />
                        <Skeleton variant="text" sx={{ fontSize: '0.8rem' }} width={100} />
                        <br></br>
                        <Skeleton variant="text" sx={{ fontSize: '0.8rem' }} width={80} />
                      </div>)
                    }      
                    <br></br>
                    <strong className='ps-2'>
                      Tout voir
                      <IconButton className="arrowButton" aria-label="settings">
                        <ArrowForwardIcon onClick={() => window.open('/cas_referes','_self')} />
                      </IconButton>
                    </strong>            
                    </CardContent>
                  </Card>}
                </Col>
                <Col md={4}>
                  <Card>
                    <CardHeader avatar={<EventOutlinedIcon />} title={"Demandes de consultation ("+(cabinet.demandes_rdv ? cabinet.demandes_rdv.length : 0)+")"}/>                    
                    <CardContent>
                    {cabinet.demandes_rdv ?
                      (cabinet.demandes_rdv.length > 0 ? cabinet.demandes_rdv
                        .map((r, i) => {
                          if(i > 2) { return; }
                          return (<div key={r.id}> 
                            <Button variant="text" className={['listButton','px-3'].join(" ")} data-id={r.id} onClick={() => {changeEditedRdv(r.id, 'demande')}}>
                            <strong>{r.client}</strong>
                            <p className='smallText'>{r.motif_consultation}</p>
                            </Button>
                            </div>)
                          }) : (<div className='px-4'><p className='smallText'>Aucune demande pour le moment</p></div>)
                      )
                        : (<div className="mt-2">
                        <Skeleton variant="text" sx={{ fontSize: '0.8rem' }} width={200} />
                        <Skeleton variant="text" sx={{ fontSize: '0.8rem' }} width={100} />
                        <br></br>
                        <Skeleton variant="text" sx={{ fontSize: '0.8rem' }} width={200} />
                        <Skeleton variant="text" sx={{ fontSize: '0.8rem' }} width={100} />
                        <br></br>
                        <Skeleton variant="text" sx={{ fontSize: '0.8rem' }} width={80} />
                      </div>)
                    }    
                    <br></br>
                    <strong className='ps-2'>
                      Tout voir
                      <IconButton className="arrowButton" aria-label="settings">
                        <ArrowForwardIcon onClick={() => window.open('/agenda','_self')} />
                      </IconButton>
                    </strong>            
                    </CardContent>
                  </Card>
                </Col>
                <Col md={4}>
                  <Card>
                    <CardHeader avatar={<AccountBalanceWalletOutlinedIcon />} title="Volume d'affaires estimé" />                    
                    <CardContent>
                    
                    <div className={['d-flex','align-items-center','mb-4'].join(" ")}>
                      <span className='me-3'>Horizon de temps : </span>
                      <Select
                        id="duree_projection"                        
                        value={dureeProjection ? listeDureesProjection.filter((c) => c.value == dureeProjection) : ''}
                        onChange={changeDureeProjection}
                        placeholder="Durée de projection"
                        options={listeDureesProjection}
                        >
                      </Select>
                    </div>

                    {listePrestationsPrevues ? (listePrestationsPrevues.length > 0 &&
                      listePrestationsPrevues.map((pp, i) => {
                        return (<p className='smallText'> - {pp.nom_prestation} x {pp.nb}</p>)
                      })) : (<div className="mt-2">
                        <Skeleton variant="text" sx={{ fontSize: '0.8rem' }} width={200} />
                        <Skeleton variant="text" sx={{ fontSize: '0.8rem' }} width={200} />
                      </div>)
                    }      
                    <br></br>
                    <strong className='bigNumber'>{caProjete} € <sup><small>*</small></sup></strong>
                    <hr className=''></hr> 
                    <p className='smallText'>* <small>Chiffre d'affaire calculé sur la base de vos tarifs de prestations, en estimant une durée de consultation moyenne de 15 minutes.</small></p>      
                    </CardContent>
                  </Card>
                </Col>
              </Row>
                
            </Col>
          </Row>
              
        </Container>

        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={openRdv}
          onClose={handleCloseRdv}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
              backdrop: { timeout: 500 },
          }}
        >
          <Fade in={openRdv}>
            <Box>
              <p className={['titre','bold','mb-2'].join(" ")}>Consultations</p>

              <IconButton aria-label="modifier" className="modalExit" onClick={handleCloseRdv}>
                <CloseIcon/>
              </IconButton>

              <Row className="align-items-start">
                <Col md={6} className='pe-4'>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    id="date_rdv" 
                    label="Date"
                    value={editedRdv.date_rdv ? dayjs(editedRdv.date_rdv) : ''}
                    onChange={updateDateRdv}
                    ampm={false}
                    format="DD/MM/YYYY HH:mm"
                    className="mt-3 mb-1 w-100"
                  />
                  </LocalizationProvider>
                  {user.is_veto ? (
                    <FormControl variant="standard" sx={{ width: '100%' }} className='mt-2'>
                      <InputLabel id="rdv_animal">Client</InputLabel>
                      <Select
                        labelId="rdv_animal"
                        id="id_client"
                        value={editedRdv.id_client ? listeClients.filter((c) => c.value == editedRdv.id_client) : ''}
                        onChange={updatelisteAnimaux}
                        label="Client"
                        options={listeClients}
                        placeholder="Client"
                        >                        
                      </Select>
                    </FormControl>
                  ) : (<div></div>)}
                  <FormControl variant="standard" sx={{ width: '100%' }} className='mt-2' error={!!animalError}>
                    <InputLabel id="rdv_animal">Animal</InputLabel>
                    <Select
                      labelId="rdv_animal"
                      id="id_animal"
                      value={editedRdv.id_animal ? listeAnimaux.filter((c) => c.value == editedRdv.id_animal) : ''}
                      onChange={updateAnimalRdv}
                      label="Animal"
                      options={listeAnimaux}
                      placeholder="Animal"
                      >
                    </Select>
                  </FormControl>
                  <FormControl variant="standard" sx={{ width: '100%' }} className='mt-2'>
                    <Select
                      id="type_prestation"
                      value={editedRdv.type_prestation ? listeTypesPrestations.filter((c) => c.value == editedRdv.type_prestation) : ''}
                      onChange={updateTypePrestationRdv}
                      placeholder="Type de prestation"
                      options={listeTypesPrestations}
                      >                      
                    </Select>
                  </FormControl>
                </Col>
                <Col md={6} className={['borderLeft','ps-4'].join(" ")}>
                  <TextField 
                    id="motif_consultation" 
                    label="Motif de consultation" 
                    variant="standard" 
                    value={editedRdv.motif_consultation ? editedRdv.motif_consultation : ''}
                    onChange={updateRdv}
                    className="mb-1 w-100"
                    error={!!motifError}
                    helperText={motifError}
                  />
                  <TextField 
                    id="symptomes" 
                    label="Symptômes" 
                    variant="standard" 
                    value={editedRdv.symptomes ? editedRdv.symptomes : ''}
                    onChange={updateRdv}
                    className="mt-2 mb-1 w-100"
                    error={!!symptomesError}
                    helperText={symptomesError}
                  />
                  <TextField 
                    id="duree_symptomes" 
                    label="Durée des symptômes" 
                    variant="standard" 
                    value={editedRdv.duree_symptomes ? editedRdv.duree_symptomes : ''}
                    onChange={updateRdv}
                    className="mt-2 mb-1 w-100"
                  />
                  <TextField 
                    id="antecedents" 
                    label="Antécédents" 
                    variant="standard" 
                    value={editedRdv.antecedents ? editedRdv.antecedents : ''}
                    onChange={updateRdv}
                    className="mt-2 mb-1 w-100"
                    />
                </Col>
              </Row>                    
              
              <Row>
                <Col md={6}>
                    <Button variant="contained" className={['buttonSecondary','mt-5'].join(" ")} type="submit" startIcon={<CheckIcon />} onClick={saveRdv}>Sauvegarder</Button>
                </Col>
              </Row>                        
                </Box>
            </Fade>
        </Modal>
      </div>
    );
  } else {
    return <Redirect to={"/client/"+user.id} />
  }
}

export default Dashboard;