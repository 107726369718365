import { React, useEffect, useState} from 'react';
import axios from "axios";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from '@mui/material/Card';
import {Skeleton, TextField, Button, Paper, CardContent} from '@mui/material';
import Input from '@mui/material/Input';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Switch from '@mui/material/Switch';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../App/App.css';

import {useCabCredentials, saveCabinet} from '../../Services/cabinetServices'; 
import {getTypesPrestations, useTypesPrestationPerso} from '../../Services/prestationsServices'; 
import {setMandat} from '../../Services/GCServices';

var max_id_types_prestation = 1;

function InscriptionCabinet2() {

    const { cabinet, setCabinet } = useCabCredentials();
    const [typesPrestations, setTypesPrestations] = useState([]);
    const { typesPrestationsPerso, setTypesPrestationsPerso } = useTypesPrestationPerso();
    const [usedTypesPrestations, setUsedTypesPrestations] = useState([]);
    const [montantLicence, setMontantLicence] = useState(300);

    const [open, setOpen] = useState(false);
    const handleClose = () => setOpen(false);
    const [nomPrestation, setNomPrestation] = useState();
    const [nomPrestationError, setNomPrestationError] = useState();
    
    const [erreursPrixPrestations, setErreursPrixPrestations] = useState([]);
    const [erreurPrixTexte, setErreurPrixTexte] = useState();

    const [activeTab, setActiveTab] = useState('1');
    
    var max_id_prestation = 1;

    useEffect(() => {
        if(cabinet == null) { window.open('/inscription_cabinet', '_self'); }
        getTypesPrestations().then(res => {
            if(res != undefined && res.data != undefined && res.data.statut == "ok") {
                setTypesPrestations([...res.data.donnees, ...typesPrestationsPerso]);
                max_id_types_prestation = res.data.next_id;
            }
        });
                    
        let erreursPrix = [...erreursPrixPrestations];
        let _usedTypesP = [];
        if(cabinet.prestations) {
            cabinet.prestations.map((p, i) => {
                if(_usedTypesP.indexOf(p.type_prestation) == -1) { _usedTypesP.push(p.type_prestation); }   
                erreursPrix[p.id] = null;       
            }); 
        }
        if(cabinet.prestations_ref) {
            cabinet.prestations_ref.map((p, i) => {
                if(_usedTypesP.indexOf(p.type_prestation) == -1) { _usedTypesP.push(p.type_prestation); }   
                erreursPrix[p.id] = null;       
            }); 
        }
        setUsedTypesPrestations(_usedTypesP);
        setErreursPrixPrestations(erreursPrix);   
        
        let cabVals = {...cabinet};
        if(!cabVals.periodicite || cabVals.periodicite.length == 0) {
            cabVals.periodicite = "M";
            setCabinet(cabVals);
        }

        let montant_licence = 0;
        if(cabVals.avec_consultations) montant_licence += 300;
        if(cabVals.avec_cas_ref) montant_licence += 2400;
        setMontantLicence(cabVals.periodicite == 'M' ? montant_licence/12 : montant_licence);        
    }, []);

    const changeActiveTab = (event, newValue) => {
        setActiveTab(newValue);
    };

    const updateCabPeriodicite = (e) => {
        let cabVals = {...cabinet};
        cabVals.periodicite = e.target.value;
        setCabinet(cabVals);     

        let montant_licence = 0;
        if(cabinet.avec_consultations) montant_licence += 300;
        if(cabinet.avec_cas_ref) montant_licence += 2400;
        setMontantLicence(cabVals.periodicite == 'M' ? montant_licence/12 : montant_licence);
    }
    const updateCabEngagement = (e) => {
        let cabVals = {...cabinet};
        cabVals['engagement'] = e.target.value;
        setCabinet(cabVals);     
    }
    const updateCabLicence = (niveau) => {
        let cabVals = {...cabinet};
        if(niveau == 2) { cabVals.avec_consultations = !cabVals.avec_consultations; }
        else if(niveau == 3) { cabVals.avec_cas_ref = !cabVals.avec_cas_ref; }
        setCabinet(cabVals);

        if(activeTab == '2' && !cabVals.avec_cas_ref && cabVals.avec_consultations) setActiveTab('1');
        else if(activeTab == '1' && !cabVals.avec_consultations && cabVals.avec_cas_ref) setActiveTab('2');
        
        let montant_licence = 0;
        if(cabVals.avec_consultations) montant_licence += 300;
        if(cabVals.avec_cas_ref) montant_licence += 2400;
        setMontantLicence(cabVals.periodicite == 'M' ? montant_licence/12 : montant_licence);
    }
    const updateCabPromo = (e) => {
        let cabVals = {...cabinet};
        cabVals.code_promo = e.target.value;
        setCabinet(cabVals);     
    }

    const addPrestation = (type = "std") => {
        let cabVals = {...cabinet};
        if(!cabVals.prestations) { cabVals.prestations = []; }
        if(!cabVals.prestations_ref) { cabVals.prestations_ref = []; }

        let typePDispo = 0;
        typesPrestations.map((t, i) => {
            if(usedTypesPrestations.indexOf(t.id) == -1) { typePDispo = i; }
        });

        (type == "ref" ? cabVals.prestations_ref : cabVals.prestations).push({
            'id': {...max_id_prestation},
            'type_prestation': typesPrestations[typePDispo].id,
            'nom_prestation': typesPrestations[typePDispo].nom,
            'a_distance': true,
            'delai': '1J',
            'prix_fixe': 0.0,
            'prix_horaire': 0.0
        });
        setCabinet(cabVals); 

        let _usedTypesPrestations = [...usedTypesPrestations];
        if(_usedTypesPrestations.indexOf(typesPrestations[typePDispo].id) == -1) { 
            _usedTypesPrestations.push(typesPrestations[typePDispo].id); 
            setUsedTypesPrestations(_usedTypesPrestations);
        }

        max_id_prestation++;
    }


    const ajoutePrestation = () => {

        if(!nomPrestation || nomPrestation.length == 0) { setNomPrestationError('Ce champ est obligatoire'); }
        else { 
            setNomPrestationError(null); 

            let _types = [...typesPrestations];
            _types.push({id: max_id_types_prestation, nom: nomPrestation});
            setTypesPrestations(_types);

            let _types_perso = [...typesPrestationsPerso];
            _types_perso.push({id: max_id_types_prestation, nom: nomPrestation});
            setTypesPrestationsPerso(_types_perso);
            

            max_id_types_prestation++;

            setOpen(false); 
            setNomPrestation(null);
        }        
    }

    const supprPrestationPerso = (e, t_id) => {

        e.preventDefault(true);

        let _types = [...typesPrestations];
        let _types_perso = [...typesPrestationsPerso];

        _types_perso.map((t, i) => {
            if(t.id == t_id) { _types_perso.splice(i, 1); }
        });
        setTypesPrestationsPerso(_types_perso);  
        
        _types.map((t, i) => {
            if(t.id == t_id) { _types.splice(i, 1); }
        });
        setTypesPrestations(_types);
    }


    const updateTypePrestation = (p_id, val, type='std') => {        
        let cabVals = {...cabinet};
        let _usedTypesP = [];

        if(val == -1) { setOpen(true); }
        else {
            (type == "ref" ? cabVals.prestations_ref : cabVals.prestations).map((p, i) => {
                if(p.id == p_id) { 
                    p.type_prestation = val; 
                    typesPrestations.map((t, j) => {
                        if(t.id == val) { p.nom_prestation = t.nom; }
                    });
                }
                if(_usedTypesP.indexOf(p.type_prestation) == -1) { _usedTypesP.push(p.type_prestation); }
            });
            setCabinet(cabVals); 
            setUsedTypesPrestations(_usedTypesP);
        }        
    }    
    const updateADistance = (p_id, val, type='std') => {        
        let cabVals = {...cabinet};
        (type == "ref" ? cabVals.prestations_ref : cabVals.prestations).map((p, i) => {
            if(p.id == p_id) { 
                p.a_distance = val;
                if(val == false) { p.prix_horaire = 0; }
            }
        });
        setCabinet(cabVals); 
    }  
    const updateDelai = (p_id, val, type='std') => {        
        let cabVals = {...cabinet};
        (type == "ref" ? cabVals.prestations_ref : cabVals.prestations).map((p, i) => {
            if(p.id == p_id) { p.delai = val; }
        });
        setCabinet(cabVals); 
    }
    const updatePrixFPrestation = (p_id, prix, type='std') => {
        if(typeof prix == "string") { prix = prix.replace(/[^0-9]/g, ''); }

        let cabVals = {...cabinet};
        (type == "ref" ? cabVals.prestations_ref : cabVals.prestations).map((p, i) => {
            if(p.id == p_id) { p.prix_fixe = prix; }
        });
        setCabinet(cabVals); 
    }
    const updatePrixHPrestation = (p_id, prix, type='std') => {
        if(typeof prix == "string") { prix = prix.replace(/[^0-9]/g, ''); }
        
        let cabVals = {...cabinet};
        (type == "ref" ? cabVals.prestations_ref : cabVals.prestations).map((p, i) => {
            if(p.id == p_id) { p.prix_horaire = prix; }
        });
        setCabinet(cabVals); 
    }
    

    const supprPrestation = (p_id, type='std') => {
        let cabVals = {...cabinet};
        let _usedTypesP = [];
        (type == "ref" ? cabVals.prestations_ref : cabVals.prestations).map((p, i) => {
            if(p.id == p_id) { (type == "ref" ? cabVals.prestations_ref : cabVals.prestations).splice(i, 1); }    
            else if(_usedTypesP.indexOf(p.type_prestation) == -1) { _usedTypesP.push(p.type_prestation); }     
        });
        setCabinet(cabVals);   
        setUsedTypesPrestations(_usedTypesP);     
    }


    const lienGoCardless = () => {

        let nb_errors = 0;

        let erreursPrix = [...erreursPrixPrestations];
        cabinet.prestations.map((p, i) => {
            if(p.prix_horaire == "") p.prix_horaire = 0;
            if(p.prix_fixe == "") p.prix_fixe = 0;
            if(p.prix_horaire == 0 && p.prix_fixe == 0) { erreursPrix[p.id] = true; nb_errors++; }
            else erreursPrix[p.id] = null;
        });
        cabinet.prestations_ref.map((p, i) => {            
            if(p.prix_horaire == "") p.prix_horaire = 0;
            if(p.prix_fixe == "") p.prix_fixe = 0;
            if(p.prix_horaire == 0 && p.prix_fixe == 0) { erreursPrix[p.id] = true; nb_errors++; }
            else erreursPrix[p.id] = null;
        });
        setErreursPrixPrestations(erreursPrix);

        if(nb_errors == 0) {
            setErreurPrixTexte(null);
            setMandat(cabinet, 'cabinet').then(res => {
                if(res != undefined && res.data != undefined && res.data.statut == "ok") {

                    let cabVals = {...cabinet};
                    cabVals['id_gocardless'] = res.data.id_gocardless;
                    setCabinet(cabVals); 
                    window.open(res.data.lien,'_self');
                }
            });
            // window.open('/validation_cabinet','_self');
        }
        else {
            setErreurPrixTexte('Les prixs doivent être supérieurs à 0');
        }
    }


    const isTypePerso = (t_id) => {
        let res = false;
        typesPrestationsPerso.map((t, i) => { if(t.id == t_id) { res = true; } });   
        return res;
    }
    
    const listeTypesPrestations = (typesPrestations != undefined && typesPrestations.length > 0 ? 
        typesPrestations.map((t, i) => <MenuItem disabled={(usedTypesPrestations.indexOf(t.id) > -1)} key={t.id} value={t.id} className={['d-flex','justify-content-between'].join(" ")}>
            <span>{t.nom}</span>
            {isTypePerso(t.id) && <IconButton className={['ms-2','smallIconButton'].join(" ")} aria-label="retour" onClick={(e) => supprPrestationPerso(e, t.id)}><DeleteOutlineIcon/></IconButton>}
        </MenuItem>) : 
    []);
    listeTypesPrestations.push(<MenuItem key={-1} value={-1} className={['textSecondary','italic'].join(" ")}><span>+ Prestation personnalisée</span></MenuItem>)

    return(
        <div className="w-100">
        <Container className={['full_container','h-min-100','inscription_cabinet_page','align_items_start'].join(" ")}>
            
            <div className='user_widgets'>  
                <div className="logo">          
                    <img src='/img/logo.png' className="w-100" alt="logo"/>
                </div>
            </div>
            
            <Row className={['w-100',' ps-4'].join(" ")}>

                <Col xs={10} className={['px-5','pt-5'].join(" ")}>
                    <Row>
                        <Col md={8}>
                            <h2 className="bold">Bienvenue !</h2>
                            <IconButton className="ms-0" aria-label="retour" onClick={() => window.open('/inscription_cabinet', '_self')}>
                                <ArrowBackIcon/>
                            </IconButton>
                            <span className='mb-1'>Etape 2/2 - </span><span className='textSecondary'>Tarifs </span>
                        </Col>
                    </Row>
                </Col>

                <Col xs={12} md={5} className="pb-5">
                    <div className={['corpsFiche','mt-3','pt-3'].join(" ")}>
                        <p className={['titre','bold','mb-0'].join(" ")}>Licence(s)</p>
                        <p className={['smallText','mb-3'].join(" ")}>Les fonctionnalités dont vous bénéficierez</p>

                        <Row className='mb-2'>
                            <Col md={7}>
                                <strong>Création de dossiers patients & de cas référés</strong>
                                <p className='smallText'>Engagement 12 mois</p>
                            </Col>
                            <Col md={2}></Col>
                            <Col md={3}>
                                <p className={['fw-bold','textRight'].join(" ")}>Gratuit</p>
                            </Col>
                        </Row>
                        <Row className='mb-2'>
                            <Col md={7}>
                                <strong>Gestion des consultations classiques</strong>
                                <p className='smallText'>Engagement 12 mois</p>
                            </Col>
                            <Col md={2}>
                                <Switch checked={cabinet && cabinet.avec_consultations} onChange={() => updateCabLicence(2)} className='ms-2' />
                            </Col>
                            <Col md={3}>
                                <p className={['fw-bold','textRight'].join(" ")}>300 € HT / an</p>
                            </Col>
                        </Row>
                        <Row className='mb-2'>
                            <Col md={7}>
                                <strong>Gestion des cas référés</strong>
                                <p className='smallText'>Engagement 12 mois</p>
                            </Col>
                            <Col md={2}>
                                <Switch checked={cabinet && cabinet.avec_cas_ref} onChange={() => updateCabLicence(3)} className='ms-2' />
                            </Col>
                            <Col md={3}>
                                <p className={['fw-bold','textRight'].join(" ")}>2400 € HT / an</p>
                            </Col>
                        </Row>

                        <p className={['titre','bold','mt-4'].join(" ")}>Facturation</p>
                        
                        <Row className='my-3'>
                            <Col md={6}>
                                <FormControl className="mb-1">
                                    <FormLabel id="periodicite-label" className='smallText'>Périodicité des prélèvements</FormLabel>
                                    <RadioGroup
                                        row
                                        aria-labelledby="periodicite-label"
                                        id="periodicite"
                                        name="periodicite" 
                                        value={cabinet && cabinet.periodicite != undefined ? cabinet.periodicite : 'M'}
                                        onChange={updateCabPeriodicite}                                    
                                    >
                                        <FormControlLabel value="M" control={<Radio />} label="Mensuels" />
                                        <FormControlLabel value="A" control={<Radio />} label="Annuels" />
                                    </RadioGroup>
                                </FormControl>  

                                <TextField
                                    id="code_promo" 
                                    label="Code promotionnel" 
                                    variant="standard"
                                    value={cabinet && cabinet.code_promo ? cabinet.code_promo : ''}
                                    onChange={updateCabPromo}
                                    className="my-1 w-100"
                                />
                            </Col>
                            <Col md={6} className={['d-flex','justify-content-end'].join(" ")} style={{'flex-direction':'column'}}>
                                <small className='textRight'>Total HT / {cabinet && cabinet.periodicite == 'M' ? 'mois' : 'an'}</small>
                                <p className={['bigNumber','textRight'].join(" ")}>{montantLicence} €</p>                                
                            </Col>
                        </Row>

                        <Button variant="contained" className={['buttonSecondary','mt-5'].join(" ")} type="submit" startIcon={<CheckIcon />} onClick={lienGoCardless}>Finaliser l'inscription</Button>
                    </div>
                </Col>
                
                {cabinet && (cabinet.avec_consultations || cabinet.avec_cas_ref) &&
                <Col xs={12} md={7}>
                    <div className={['corpsFiche','borderLeft','pt-0'].join(" ")}>

                        <TabContext value={activeTab}>
                            <TabList onChange={changeActiveTab} className='px-2'>
                                {cabinet && cabinet.avec_consultations && <Tab label="Consultations générales" value="1" />}
                                {cabinet && cabinet.avec_cas_ref && <Tab label="Cas référés" value="2"/>}
                            </TabList>
                            <TabPanel value="1" className='pt-3'>
                                {cabinet && cabinet.prestations && cabinet.prestations.length > 0 && <div>
                                    <Card className='p-3'>                        
                                        {cabinet.prestations.length > 0 ? cabinet.prestations
                                            .map((p, i) => {	
                                            var mt = (i > 0 ? "mt-3" : "mt-1");
                                            return (<div className = {mt} key={p.id} >
                                                <Row className="align-items-center">
                                                <Col md={4} className='pe-2'>
                                                        <FormControl variant="standard" sx={{ width: '100%' }} className='mt-2'>
                                                            <Select
                                                                id="pst-type_prestation"
                                                                value={p.type_prestation}
                                                                onChange={e => {updateTypePrestation(p.id, e.target.value);}}
                                                                >
                                                                {listeTypesPrestations}
                                                            </Select>
                                                            <FormHelperText>Type de prestation</FormHelperText>
                                                        </FormControl>
                                                    </Col>
                                                    <Col md={3} className='px-2'>
                                                        <FormControl variant="standard" sx={{ width: '100%' }} className='mt-2'>
                                                            <Select
                                                                id="pst-a_distance"
                                                                value={p.a_distance}
                                                                onChange={e => {updateADistance(p.id, e.target.value);}}
                                                                >
                                                                <MenuItem value={true}>Téléconsultation</MenuItem>
                                                                <MenuItem value={false}>Rdv physique</MenuItem>
                                                            </Select>
                                                            <FormHelperText>Format</FormHelperText>
                                                        </FormControl>
                                                    </Col>
                                                    <Col md={2} className='px-2'>
                                                        <FormControl variant="standard">
                                                            <Input
                                                                id="prix_fixe"
                                                                endAdornment={<InputAdornment position="end">€</InputAdornment>}
                                                                aria-describedby="prix_fixe-text"
                                                                value={p.prix_fixe}
                                                                onChange={e => {updatePrixFPrestation(p.id, e.target.value);}}
                                                                error={!!erreursPrixPrestations[p.id]}
                                                                helperText={erreursPrixPrestations[p.id]}
                                                            />
                                                            <FormHelperText id="prix_fixe-text">Prix Fixe</FormHelperText>
                                                        </FormControl>
                                                    </Col>
                                                    {p.a_distance == true && <Col md={2} className='px-2'>
                                                        <FormControl variant="standard">
                                                            <Input
                                                                id="prix_horaire"
                                                                endAdornment={<InputAdornment position="end">€/min</InputAdornment>}
                                                                aria-describedby="prix_horaire-text"
                                                                value={p.prix_horaire}
                                                                onChange={e => {updatePrixHPrestation(p.id, e.target.value);}}
                                                                error={!!erreursPrixPrestations[p.id]}
                                                                helperText={erreursPrixPrestations[p.id]}
                                                            />
                                                            <FormHelperText id="prix_horaire-text">Prix horaire</FormHelperText>
                                                        </FormControl>
                                                    </Col>}
                                                    <Col md={1} className='ps-2'>
                                                        <IconButton aria-label="supprimer" className="textRed" onClick={() => supprPrestation(p.id)}>
                                                            <DeleteOutlineIcon data-id={p.id} />
                                                        </IconButton>
                                                    </Col>
                                                </Row>
                                            </div>)
                                            }) : (<div></div>)
                                        } 
                                    </Card>
                                    <p className={['textRed', 'textRight', 'mt-1'].join(' ')}>{erreurPrixTexte}</p>                                    
                                </div>}
                                <div className={['d-flex','align-items-center','mt-2'].join(" ")}>
                                    <Card className={['w-auto','p-2','pe-3'].join(" ")}>
                                        <IconButton aria-label="supprimer" className="me-2" onClick={addPrestation}>
                                            <AddBoxOutlinedIcon />
                                        </IconButton> 
                                        Ajouter une prestation
                                    </Card>
                                </div>
                            </TabPanel>
                            <TabPanel value="2" className='pt-3'>
                                {cabinet && cabinet.prestations_ref && cabinet.prestations_ref.length > 0 && <div>
                                    <Card className='p-3'>                        
                                        {cabinet.prestations_ref.length > 0 ? cabinet.prestations_ref
                                            .map((p, i) => {	
                                            var mt = (i > 0 ? "mt-3" : "mt-1");
                                            return (<div className = {mt} key={p.id} >
                                                <Row className="align-items-center">
                                                    <Col md={3} className='pe-2'>
                                                        <FormControl variant="standard" sx={{ width: '100%' }} className='mt-2'>
                                                            <Select
                                                                id="pst-type_prestation"
                                                                value={p.type_prestation}
                                                                onChange={e => {updateTypePrestation(p.id, e.target.value, 'ref');}}
                                                                >
                                                                {listeTypesPrestations}
                                                            </Select>
                                                            <FormHelperText>Type de prestation</FormHelperText>
                                                        </FormControl>
                                                    </Col>
                                                    <Col md={2} className='px-2'>
                                                        <FormControl variant="standard" sx={{ width: '100%' }} className='mt-2'>
                                                            <Select
                                                                id="pst-a_distance"
                                                                value={p.a_distance}
                                                                onChange={e => {updateADistance(p.id, e.target.value, 'ref');}}
                                                                >
                                                                <MenuItem value={true}>Téléconsultation</MenuItem>
                                                                <MenuItem value={false}>Rdv physique</MenuItem>
                                                            </Select>
                                                            <FormHelperText>Format</FormHelperText>
                                                        </FormControl>
                                                    </Col>
                                                    <Col md={2} className='px-2'>
                                                        <FormControl variant="standard" sx={{ width: '100%' }} className='mt-2'>
                                                            <Select
                                                                id="pst-delai"
                                                                value={p.delai}
                                                                onChange={e => {updateDelai(p.id, e.target.value, 'ref');}}
                                                                >
                                                                <MenuItem value='1H'>1H</MenuItem>
                                                                <MenuItem value='1J'>1J</MenuItem>
                                                                <MenuItem value='2J'>2J</MenuItem>
                                                                <MenuItem value='4J'>4J</MenuItem>
                                                            </Select>
                                                            <FormHelperText>Délai de réponse</FormHelperText>
                                                        </FormControl>
                                                    </Col>
                                                    <Col md={2} className='px-2'>
                                                        <FormControl variant="standard">
                                                            <Input
                                                                id="prix_fixe"
                                                                endAdornment={<InputAdornment position="end">€</InputAdornment>}
                                                                aria-describedby="prix_fixe-text"
                                                                value={p.prix_fixe}
                                                                onChange={e => {updatePrixFPrestation(p.id, e.target.value, 'ref');}}
                                                                error={!!erreursPrixPrestations[p.id]}
                                                                helperText={erreursPrixPrestations[p.id]}
                                                            />
                                                            <FormHelperText id="prix_fixe-text">Prix Fixe</FormHelperText>
                                                        </FormControl>
                                                    </Col>
                                                    {p.a_distance == true && <Col md={2} className='px-2'>
                                                        <FormControl variant="standard">
                                                            <Input
                                                                id="prix_horaire"
                                                                endAdornment={<InputAdornment position="end">€/min</InputAdornment>}
                                                                aria-describedby="prix_horaire-text"
                                                                value={p.prix_horaire}
                                                                onChange={e => {updatePrixHPrestation(p.id, e.target.value, 'ref');}}
                                                                error={!!erreursPrixPrestations[p.id]}
                                                                helperText={erreursPrixPrestations[p.id]}
                                                            />
                                                            <FormHelperText id="prix_horaire-text">Prix horaire</FormHelperText>
                                                        </FormControl>
                                                    </Col>}
                                                    <Col md={1} className='ps-2'>
                                                        <IconButton aria-label="supprimer" className="textRed" onClick={() => supprPrestation(p.id, 'ref')}>
                                                            <DeleteOutlineIcon data-id={p.id} />
                                                        </IconButton>
                                                    </Col>
                                                </Row>
                                            </div>)
                                            }) : (<div></div>)
                                        } 
                                    </Card>
                                    <p className={['textRed', 'textRight', 'mt-1'].join(' ')}>{erreurPrixTexte}</p>                                    
                                </div>}
                                <div className={['d-flex','align-items-center','mt-2'].join(" ")}>
                                    <Card className={['w-auto','p-2','pe-3'].join(" ")}>
                                        <IconButton aria-label="supprimer" className="me-2" onClick={() => addPrestation('ref')}>
                                            <AddBoxOutlinedIcon />
                                        </IconButton> 
                                        Ajouter une prestation
                                    </Card>
                                </div>
                            </TabPanel>
                        </TabContext>                        
                    </div>
                </Col>}
               
            </Row>
        </Container>

        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: { timeout: 500 },
            }}
        >
            <Fade in={open}>
                <Box>
                    <p className={['titre','bold','mb-2'].join(" ")}>Prestation personnalisée</p>

                    <IconButton aria-label="modifier" className="modalExit" onClick={handleClose}>
                        <CloseIcon/>
                    </IconButton>

                    <TextField 
                        id="nom" 
                        label="Nom" 
                        variant="standard"
                        className="mt-2 mb-1 w-100"
                        value={nomPrestation}
                        onChange={(e) => setNomPrestation(e.target.value)}
                        error={!!nomPrestationError}                                
                        helperText={nomPrestationError}
                    />

                    <Button variant="contained" className={['buttonSecondary','mt-5'].join(" ")} type="submit" startIcon={<CheckIcon />} onClick={ajoutePrestation}>Sauvegarder</Button>
                </Box>
            </Fade>
        </Modal>

        </div>
    )
}

export default InscriptionCabinet2;