import {React, useState} from 'react';
import axios from "axios";


export async function loginUser(credentials) {

  return axios.post(process.env.REACT_APP_API_URL+'login', {
      email: credentials.username,
      password: credentials.password,
  }, {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
  .catch(function (error) {
    console.log(error.toJSON());
  });
}


export async function getByToken(token) {

  return axios.post(process.env.REACT_APP_API_URL+'client/get_by_token', {
      token: token
  }, {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
  .catch(function (error) {
    console.log(error.toJSON());
  });
}


export function useCredentials() {

  const getUser = () => {
    const tokenString = localStorage.getItem('user');
    const userToken = JSON.parse(tokenString);
    return userToken
  };

  const [user, setUser] = useState(getUser());

  const saveUser = userDatas => {
    localStorage.setItem('user', JSON.stringify(userDatas));
    setUser(userDatas);
  };

  return {
    setUser: saveUser,
    user
  }
}
