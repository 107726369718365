import axios from "axios";

export async function getUserDestinaires(user) {
    
    return axios.post(process.env.REACT_APP_API_URL+'messages/get_destinataires', {
        token: user.sessionToken
    }, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    })
    .catch(function (error) {
        console.log(error.toJSON());
    });
}

export async function getMessages(user, id_emetteur, id_destinataire, id_rdv, from = null) {
    
    return axios.post(process.env.REACT_APP_API_URL+'messages/get', {
        token: user.sessionToken,
        id_emetteur: id_emetteur,
        id_destinataire: id_destinataire,
        id_rdv: id_rdv,
        from: from
    }, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    })
    .catch(function (error) {
        console.log(error.toJSON());
    });
}


export async function setMessage(user, id_emetteur, id_destinataire, id_rdv, contenu) {
    
    return axios.post(process.env.REACT_APP_API_URL+'messages/set', {
        token: user.sessionToken,
        id_emetteur: id_emetteur,
        id_destinataire: id_destinataire,
        id_rdv : id_rdv, 
        contenu: contenu
    }, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    })
    .catch(function (error) {
        console.log(error.toJSON());
    });
}