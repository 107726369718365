import axios from "axios";

export async function getEspeces(user) {
    
    return axios.post(process.env.REACT_APP_API_URL+'animals/get_especes', {
        token: user.sessionToken
    }, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    })
    .catch(function (error) {
        console.log(error.toJSON());
    });
}


export async function getRaces(user, id_espece) {
    
    return axios.post(process.env.REACT_APP_API_URL+'animals/get_races', {
        token: user.sessionToken,
        id_espece: id_espece
    }, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    })
    .catch(function (error) {
        console.log(error.toJSON());
    });
}


export async function getAnimal(user, id_animal) {
    
    return axios.post(process.env.REACT_APP_API_URL+'animals/get_one', {
        token: user.sessionToken,
        id_animal: id_animal
    }, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    })
    .catch(function (error) {
        console.log(error.toJSON());
    });
}


export async function setAnimal(user, datas, id_proprietaire) {
    
    return axios.post(process.env.REACT_APP_API_URL+'animal/set', {
        token: user.sessionToken,
        id_animal: datas.id,
        id_proprietaire: id_proprietaire,
        datas: JSON.stringify(datas)
    }, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    })
    .catch(function (error) {
        console.log(error.toJSON());
    });
}


export async function deleteAnimal(user, id_animal) {
    
    return axios.post(process.env.REACT_APP_API_URL+'animal/delete', {
        token: user.sessionToken,
        id_animal: id_animal
    }, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    })
    .catch(function (error) {
        console.log(error.toJSON());
    });
}

export async function getAnimalRdvs(user, id_animal) {

    return axios.post(process.env.REACT_APP_API_URL+'animal/get_rdvs', {
        token: user.sessionToken,
        id_animal: id_animal
    }, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    })
    .catch(function (error) {
        console.log(error.toJSON());
    });
}

export async function setCommentaires(user, datas, id_animal) {
    
    return axios.post(process.env.REACT_APP_API_URL+'commentaires/set', {
        token: user.sessionToken,
        id_animal: id_animal,
        datas: JSON.stringify(datas)
    }, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    })
    .catch(function (error) {
        console.log(error.toJSON());
    });
}


export async function deleteCommentaire(user, id_commentaire) {
    
    return axios.post(process.env.REACT_APP_API_URL+'commentaires/delete', {
        token: user.sessionToken,
        id_commentaire: id_commentaire
    }, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    })
    .catch(function (error) {
        console.log(error.toJSON());
    });
}